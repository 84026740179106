import React from 'react';
import { useLocation } from 'react-router-dom';
import Header from '../components/header/Header';
import Sidebar from '../components/sidebar/Sidebar';
import useWindowDimensions from '../hook/useWindowDimensions';
import '../static/scss/page.scss';

const PageLayout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { width } = useWindowDimensions();
    const location = useLocation();

    const isStudio = location.pathname.includes('/task-manager/anonymization/edit');

    if (isStudio) return <>{children}</>;

    return (
        <>
            <Sidebar />
            <Header />
            <div className={`page-container py-3 ${width >= 991 ? 'px-4' : 'task-container'} ${isStudio ? 'full-width' : ' '}`}>
                <div className="container-fluid">{children}</div>
            </div>
        </>
    );
};

export default PageLayout;
