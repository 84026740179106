import { Controls } from './controls/Controls';
import { Timeline } from './timeline/Timeline';

import '../../styles/media-controls.scss';
import { useRecoilValue } from 'recoil';
import { isMediaVideo, mediaAtom } from '../../hooks/useMedia';

export const MediaControls = () => {
    const mediaState = useRecoilValue(mediaAtom);
    if (!isMediaVideo(mediaState)) return null;
    return (
        <div className="media-controls">
            <Timeline media={mediaState} />
            <Controls />
        </div>
    );
};
