import { atom } from 'recoil';

type TrackEditor = {
    min: number;
    max: number;
    onChangeMin: (min: number) => void;
    onChangeMax: (max: number) => void;
    onReleaseMax?: (value: number) => void;
    onReleaseMin?: (value: number) => void;
};

export const trackEditorAtom = atom<TrackEditor | undefined>({
    key: 'trackEditorAtom',
    default: undefined,
});
