import { Fragment, useRef, useEffect, useState } from 'react';
import Konva from 'konva';
import { Rect, Transformer, Line, Group, KonvaNodeComponent } from 'react-konva';
import { Box, DetectionClassName, DetectionHead, DetectionOther, Point } from '../../../../../recoil/framesDetectionsCoordinates.atom';
import { DetectionState, rectStyle } from '../../types';
import { KonvaEventObject } from 'konva/lib/Node';

type Props = Konva.LineConfig &
    Omit<DetectionOther, 'trackId' | 'box' | 'src'> &
    DetectionState & {
        cursorPosition?: Point;
        transformerVisible?: boolean;
        onEndCreation?: (data: { data: Point[]; cn: DetectionClassName }) => void;
        handleClick?: () => void;
        onDetectionHover?: () => void;
        onDetectionHoverLeave?: () => void;
        onMouseDown?: (data: { data: Point[] }) => void;
        onDragEnd?: (groupCoordinates: Point[]) => void;
        onTransformEnd?: (box: Point[]) => void;
    };

export const OtherDetection: React.FC<Props> = (props) => {
    const { ldm, mode, cursorPosition, onEndCreation } = props;
    const [isHover, setIsHover] = useState(false);
    const firsPointRef = useRef<Konva.Rect>(null!);
    const groupRef = useRef<Konva.Group>(null!);
    const lineRef = useRef<Konva.Line>(null!);
    const points: number[] = [];
    const isDraggingCorner = useRef(false);
    const isTransforming = useRef(false);
    let ldmCopy = [...ldm];

    // creation mode
    if (cursorPosition) ldmCopy.push({ x: cursorPosition.x, y: cursorPosition.y });

    ldmCopy.forEach((point) => {
        points.push(point.x, point.y);
    });

    useEffect(() => {
        if (!firsPointRef.current || mode !== 'creation' || ldm.length < 3) return;
        const ref = firsPointRef.current;
        ref.on('mouseenter', () => {
            ref.setAttrs({
                width: 20,
                height: 20,
                x: ldmCopy[0].x - 10,
                y: ldmCopy[0].y - 10,
            });
        });
        ref.on('mouseleave', () => {
            ref.setAttrs({
                width: 10,
                height: 10,
                x: ldmCopy[0].x - 5,
                y: ldmCopy[0].y - 5,
            });
        });
        ref.on('click', (e) => {
            e.cancelBubble = true;
            if (onEndCreation) {
                if (ldmCopy.length > 3) {
                    onEndCreation({ data: ldmCopy, cn: DetectionClassName.Other });
                }
            }
        });
        return () => {
            ref.off('mouseenter');
            ref.off('mouseleave');
            ref.off('click');
        };
    }, [firsPointRef, ldmCopy]);

    const handleDragEnd = (evt: KonvaEventObject<Event>) => {
        if (isDraggingCorner.current) return;
        const node = groupRef.current;
        const scaleX = node.scaleX();
        const scaleY = node.scaleY();
        evt.target.setAttrs({
            width: node.width() * scaleX,
            height: node.height() * scaleY,
        });
        if (props.onDragEnd) {
            props.onDragEnd(ldmCopy.map((point) => ({ x: point.x + node.x(), y: point.y + node.y() })));
        }
        groupRef?.current?.setAttrs({ scaleX: 1, scaleY: 1, x: 0, y: 0 });
    };

    const handleDragCorner = (index: number) => (evt: KonvaEventObject<Event>) => {
        isDraggingCorner.current = true;
        let tmp = [...points];
        tmp[index * 2] = evt.currentTarget.x();
        tmp[index * 2 + 1] = evt.currentTarget.y();
        lineRef.current.setAttrs({ points: tmp });
    };

    const handleDragCornerEnd = (evt: KonvaEventObject<Event>) => {
        if (props.onDragEnd) {
            let newPoints: Point[] = [];
            const pointsTmp = lineRef.current.points();
            for (let i = 0; i < pointsTmp.length; i += 2) {
                const x = pointsTmp[i];
                const y = pointsTmp[i + 1];
                newPoints.push({ x: x, y: y });
            }
            props.onDragEnd(newPoints);
        }
        evt.cancelBubble = true;
        isDraggingCorner.current = false;
    };

    const handleMouseEnter = () => {
        if (props.disabled) return;
        if (props.onDetectionHover) props.onDetectionHover();
        setIsHover(true);
    };
    const handleMouseLeave = () => {
        if (props.disabled) return;
        if (props.onDetectionHoverLeave) props.onDetectionHoverLeave();
        setIsHover(false);
    };

    const handleMouseEnterCorner = (evt: KonvaEventObject<MouseEvent>) => {
        const stage = evt.currentTarget.getStage();
        if (!stage) return;
        stage.container().style.cursor = 'grab';
    };

    const handleMouseLeaveCorner = (evt: KonvaEventObject<MouseEvent>) => {
        const stage = evt.currentTarget.getStage();
        if (!stage) return;
        stage.container().style.cursor = 'default';
    };

    const handleClick = () => {
        if (props.disabled) return;
        if (props.handleClick) props.handleClick();
    };

    const pointsD = mode === 'creation' ? ldmCopy.slice(0, -1) : ldmCopy;
    return (
        <Group
            ref={groupRef}
            onClick={handleClick}
            draggable={!props.disabled}
            onDragEnd={handleDragEnd}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <Line ref={lineRef} points={points} opacity={1} {...rectStyle[mode]} closed={!cursorPosition} />
            {(props.transformerVisible || isHover || isTransforming.current) &&
                pointsD.map((point, index) => {
                    return (
                        <Rect
                            ref={(e) => {
                                if (index === 0 && e) {
                                    firsPointRef.current = e;
                                }
                            }}
                            key={index}
                            x={point.x - 5}
                            y={point.y - 5}
                            width={10}
                            height={10}
                            draggable
                            onMouseEnter={handleMouseEnterCorner}
                            onMouseLeave={handleMouseLeaveCorner}
                            onDragMove={handleDragCorner(index)}
                            poi
                            onDragEnd={handleDragCornerEnd}
                            {...rectStyle[mode]}
                        />
                    );
                })}
        </Group>
    );
};
