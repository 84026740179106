import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as IconCheck } from '../../../../../static/icons/tasks/icon_check.svg';
import DragNDrop from '../../../../../components/dragNDrop/DragNDrop';
import { useDragNDrop } from '../../../../../components/dragNDrop/useDragNDrop';
import '../../../../../static/scss/anonymization.scss';
import { useFormikContext } from 'formik';
import { FormAskAnonymization } from '../../../../../types';
import { useSetRecoilState } from 'recoil';
import { optionsSettingsState } from './BlurOptions';
import CustomButton from '../../../../../components/button/button';
import RoundedLoader from '../../../../../components/loader/RoundedLoaderAnimated';

export const pictureAuthorizedFormats = ['image/jpg', 'image/jpeg', 'image/png', 'image/webp'];
export const videoAuthorizedFormats = ['video/x-matroska', 'video/mp4', 'video/quicktime', 'video/x-msvideo'];
export const dirtyAuthorizedVideoFormats = ['mkv', 'mov']; // mimetype is unpredictable
export const veryDirtyAuthorizedVideoFormats = ['avi']; // can't be preloaded

const PreviewMedia: React.FC = React.memo(() => {
    const { inputRef, isZip, isDirtyVideoAcceptedFormat } = useDragNDrop();
    const { t } = useTranslation();
    const [isPreviewLoading, setPreviewIsLoading] = useState(true);
    const [isPreviewLoadedWithError, setPreviewIsLoadedWithError] = useState(false);
    const timeoutRef = useRef<any>(null!);
    const onClickInput = () => {
        setPreviewIsLoadedWithError(false);
        inputRef?.current?.click();
    };
    const { file } = useDragNDrop();

    if (!file) return null;

    if (isDirtyVideoAcceptedFormat(file, veryDirtyAuthorizedVideoFormats) || isZip(file)) {
        return (
            <div className="preview-container__preview-dirty-file">
                <div className="preview-container__preview-dirty-file__icon-container">
                    <IconCheck height="20" width="20" />
                </div>
                <span>
                    <strong>{file.name}</strong>
                </span>
                <CustomButton
                    onClick={onClickInput}
                    buttonText={t('task-management.tasks.anonymization.change-button')}
                    customClass="preview-container__file-btn preview-container__file-btn--hover"
                />
            </div>
        );
    }

    if (pictureAuthorizedFormats.includes(file.type)) {
        return (
            <div className="preview-container__preview-file-container">
                <img src={URL.createObjectURL(file)} className="preview-container__preview-file" alt="preview file" />
                <CustomButton
                    onClick={onClickInput}
                    buttonText={t('task-management.tasks.anonymization.change-button')}
                    customClass="preview-container__file-btn preview-container__file-btn--hover"
                />
            </div>
        );
    }

    if (videoAuthorizedFormats.includes(file.type) || isDirtyVideoAcceptedFormat(file, dirtyAuthorizedVideoFormats)) {
        return (
            <>
                {isPreviewLoadedWithError ? (
                    <div className="preview-container__preview-dirty-file">
                        <div className="preview-container__preview-dirty-file__icon-container">
                            <IconCheck height="20" width="20" />
                        </div>
                        <span>
                            <strong>{file.name}</strong>
                        </span>
                        <CustomButton
                            onClick={onClickInput}
                            buttonText={t('task-management.tasks.anonymization.change-button')}
                            customClass="preview-container__file-btn preview-container__file-btn--hover"
                        />
                    </div>
                ) : (
                    <div className="preview-container__preview-file-container">
                        <div className="preview-container__preview-file-loading">
                            <RoundedLoader isLoading={isPreviewLoading} />
                        </div>
                        <video
                            onLoadStart={() => {
                                setPreviewIsLoadedWithError(false);
                                timeoutRef.current = setTimeout(() => {
                                    setPreviewIsLoadedWithError(true);
                                }, 2000);
                            }}
                            onLoadedData={(e) => {
                                clearTimeout(timeoutRef.current);
                                setPreviewIsLoading(false);
                                setPreviewIsLoadedWithError(false);
                            }}
                            src={URL.createObjectURL(file)}
                            preload="auto"
                            className="preview-container__preview-file"
                        />
                        <CustomButton
                            onClick={onClickInput}
                            buttonText={t('task-management.tasks.anonymization.change-button')}
                            customClass="preview-container__file-btn preview-container__file-btn--hover"
                        />
                    </div>
                )}
            </>
        );
    }
    return null;
});

export const PreviewTask = () => {
    const { inputRef, file, dragging } = useDragNDrop();
    const { t } = useTranslation();
    const formikContext = useFormikContext<FormAskAnonymization>();
    const setState = useSetRecoilState(optionsSettingsState);

    const onChangeFile = (file: File) => {
        formikContext.setValues((prev) => ({
            ...prev,
            input_media: file,
        }));
        setState((prev) => ({
            ...prev,
            'blur-settings': {
                state: 'active',
            },
        }));
    };

    const onClickInput = () => {
        inputRef?.current?.click();
    };

    return (
        <div className="col-md-4">
            <DragNDrop id="dnd-anonymization" onDrop={onChangeFile}>
                <div className={`preview-container ${dragging ? 'dragging' : ''}`}>
                    <div className="preview-container__box">
                        {file ? (
                            <PreviewMedia />
                        ) : (
                            <>
                                <div className="preview-container__laius-box">
                                    <span className={`preview-container__laius`}>{t('task-management.tasks.anonymization.drag-and-drop')}</span>
                                    <span className={`preview-container__laius`}>{t('task-management.tasks.anonymization.drag-and-drop-or')}</span>
                                </div>
                                <CustomButton
                                    onClick={onClickInput}
                                    buttonText={t('task-management.tasks.anonymization.choose-button')}
                                    customClass="preview-container__file-btn"
                                />
                                <div className="preview-container__list">
                                    <span className="preview-container__list-item">{t('task-management.tasks.anonymization.select-file-laius')}</span>
                                    <span className="preview-container__list-item">{t('task-management.tasks.anonymization.max-size')}</span>
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </DragNDrop>
        </div>
    );
};
