import { useLoadingState } from '../../../hooks/useLoadingState';
import { isMediaVideo, useMedia } from '../../../hooks/useMedia';
import { ActionButtons } from './ActionButtons';
import { Audio } from './Audio';
import { MediaData } from './MediaData';

export const Controls = () => {
    const [media] = useMedia();

    if (!media.isLoading && !isMediaVideo(media)) return null;

    return (
        <div className="media-controls__controls">
            <Audio />
            <ActionButtons />
            <MediaData />
        </div>
    );
};
