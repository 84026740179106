import { useEffect, useRef, useState } from 'react';
import { useRecoilValue } from 'recoil';
import { RoundedLoader } from '../../../../../../../components/loader/RoundedLoader';
import { useRefsContext } from '../../../contexts/RefsContexts';
import { ReadableDetection, useReadDetections } from '../../../hooks/useReadDetections';
import { blurPreviewAtom } from '../../../recoil/blurPreview.atom';
import { Box, DetectionClassName, Point } from '../../../recoil/framesDetectionsCoordinates.atom';

const isPointInsideEllipse = (coordinates: Box, pixelX: number, pixelY: number) => {
    const x0 = coordinates.x;
    const y0 = coordinates.y;
    const x1 = coordinates.x + coordinates.width;
    const y1 = coordinates.y + coordinates.height;

    const mx = 0.5 * (x0 + x1);
    const my = 0.5 * (y0 + y1);

    const ox = 0.5 * Math.abs(-x0 + x1);
    const oy = 0.5 * Math.abs(-y0 + y1);

    const result = Math.pow(pixelX - mx, 2) / Math.pow(ox, 2) + Math.pow(pixelY - my, 2) / Math.pow(oy, 2);
    return result < 1;
};

type RGBA = {
    r: number;
    g: number;
    b: number;
    a: number;
};

const getVectorSize = (dest: Point, srcCoord: Point) => Math.sqrt(Math.pow(srcCoord.x - dest.x, 2) + Math.pow(srcCoord.y - dest.y, 2));

export const blurImage = (image: ImageData, detections: ReadableDetection[], kernel: number): ImageData => {
    const pixels = image.data;
    const tmpPixels = new Uint8ClampedArray(pixels.length);
    for (let i = 0; i < pixels.length; i++) {
        tmpPixels[i] = pixels[i];
    }

    const kernelSize = 2 * kernel + 1; // Taille du kernel carré
    let kernelArea = kernelSize * kernelSize;

    const updatePixels = (currPixel: Point, srcCoord: Point, rgbA: RGBA, detection: Box) => {
        const vectorSize = getVectorSize({ x: currPixel.x, y: currPixel.y }, { x: srcCoord.x + detection.width / 2, y: srcCoord.y + detection.height / 2 });
        let kernel2 = kernel * (1 - vectorSize / Math.max(detection.height, detection.width));
        kernel2 = Math.round(kernel2);
        if (Math.round(kernel2) % 2 !== 0) {
            kernel2 = Math.round(kernel2) + 1;
        }
        kernelArea = 0;
        for (let dj = -kernel; dj <= kernel; dj++) {
            for (let dk = -kernel; dk <= kernel; dk++) {
                const cx = currPixel.x + dk;
                const cy = currPixel.y + dj;

                if (cx >= 0 && cx < image.width && cy >= 0 && cy < image.height) {
                    const index = (cy * image.width + cx) * 4;
                    rgbA.r += pixels[index];
                    rgbA.g += pixels[index + 1];
                    rgbA.b += pixels[index + 2];
                    rgbA.a += pixels[index + 3];

                    kernelArea++;
                }
            }
        }

        tmpPixels[(currPixel.y * image.width + currPixel.x) * 4] = Math.round(rgbA.r / kernelArea);
        tmpPixels[(currPixel.y * image.width + currPixel.x) * 4 + 1] = Math.round(rgbA.g / kernelArea);
        tmpPixels[(currPixel.y * image.width + currPixel.x) * 4 + 2] = Math.round(rgbA.b / kernelArea);
        tmpPixels[(currPixel.y * image.width + currPixel.x) * 4 + 3] = Math.round(rgbA.a / kernelArea);
    };

    for (const detection of detections) {
        const { box, cn } = detection;
        if (box) {
            let x = Math.round(box.x);
            let y = Math.round(box.y);
            let width = box.width;
            let height = box.height;
            for (let j = y; j < y + height; j++) {
                for (let k = x; k < x + width; k++) {
                    let totalR = 0;
                    let totalG = 0;
                    let totalB = 0;
                    let totalA = 0;

                    if (cn === DetectionClassName.Head && isPointInsideEllipse({ x, y, width, height }, k, j))
                        updatePixels({ x: k, y: j }, { x, y }, { r: totalR, g: totalG, b: totalB, a: totalA }, box);
                    if (cn === DetectionClassName.Plate) updatePixels({ x: k, y: j }, { x, y }, { r: totalR, g: totalG, b: totalB, a: totalA }, box);
                }
            }
        }
    }

    image.data.set(tmpPixels);
    return image;
};

export const BlurPreview = () => {
    const { canvasMediaRef } = useRefsContext();
    const ref = useRef<HTMLCanvasElement>(null!);
    const detections = useReadDetections();
    // const [, { getDetectionsWithoutPreview }] = useHandleDetection();
    const blurPreviewState = useRecoilValue(blurPreviewAtom);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!ref.current || !canvasMediaRef.current || !blurPreviewState) return;
        const ctx = canvasMediaRef.current.getContext('2d');
        if (!ctx) return;
        // clear new canvas
        ref.current.getContext('2d')?.clearRect(0, 0, ref.current.width, ref.current.height);
        // get image from origin canvas
        const imageData = ctx.getImageData(0, 0, canvasMediaRef.current.width, canvasMediaRef.current.height);
        // get frame detections
        // const detections = getDetectionsWithoutPreview();
        if (detections.length === 0) return;
        setIsLoading(true);
        // get new image blured
        const newImageData = blurImage(
            imageData,
            detections.filter((d) => d.active),
            10
        );
        // assign new imageData to canvas
        ref.current.getContext('2d')?.putImageData(newImageData, 0, 0);
        setIsLoading(false);
    }, [blurPreviewState]);

    if (!blurPreviewState) return null;

    return (
        <>
            <div className="preview-loading">
                <RoundedLoader size={50} isLoading={isLoading} />
            </div>
            <canvas style={{ height: '100%', width: '100%' }} height={1080} width={1920} className="canvas-blur-preview" ref={ref}></canvas>
        </>
    );
};
