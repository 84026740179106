import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import { ReactComponent as DownloadSvg } from '../../../static/icons/icon_download-black.svg';

export const DownloadButton: React.FC<{ url: string; params?: any; buttonType: 'image' | 'link' | 'image-link'; filename: string, aRef?: React.RefObject<HTMLAnchorElement> }> = ({
    url,
    params,
    filename,
    buttonType = 'image',
    aRef
}) => {
    const [cookie] = useCookies(['Auth_manager']);
    const [iconState, setIconState] = useState({ color: '#000000' });

    const handleIconColor = (mouseAction: any) => {
        if (mouseAction === 'enter') {
            setIconState({ color: '#F50A6B' });
        } else {
            setIconState({ color: '#000000' });
        }
    };

    const prepareRequestParameters = () => {
        let urlCopy = '';
        let baseUrl = `${url}?`;
        if (params) {
            urlCopy += Object.keys(params)
                ?.map((param) => `${param}=${params[param]}`)
                ?.join('&');
            urlCopy += '&';
        }
        let finalUrl = baseUrl + encodeURIComponent(`${urlCopy}authToken=${cookie['Auth_manager']?.token}`);

        return finalUrl;
    };

    return (
        <a href={prepareRequestParameters()} ref={aRef}>
            {
                {
                    'image': <DownloadSvg
                        width={15}
                        height={15}
                        onMouseEnter={() => handleIconColor('enter')}
                        onMouseLeave={() => handleIconColor('leave')}
                        fill={iconState.color}
                        style={{
                            cursor: 'pointer',
                        }}
                    />,
                    'link': <span className="file-name">{filename}</span>,
                    'image-link':
                        <div className='d-flex w-100 justify-content-between align-items-center gap-2'>
                            <span className="file-name">{filename}</span>
                            <DownloadSvg
                                width={15}
                                height={15}
                                fill={"#000000"}
                            />
                        </div>
                }[buttonType]
            }
        </a>

    );
};
