import { useEffect } from 'react';
import { atom, useRecoilState } from 'recoil';
import { useRefsContext } from '../contexts/RefsContexts';
import { blurPreviewAtom } from '../recoil/blurPreview.atom';
import { Box, Point } from '../recoil/framesDetectionsCoordinates.atom';
import { useCurrentFrameDetections } from './useCurrentFrameDetections';

export type DimensionRatio = {
    srcElementHeight: number;
    srcElementWidth: number;
    centerShift_x: number;
    centerShift_y: number;
    ratio: number;
    scale: number;
    frameWidth: number;
    frameHeight: number;
};

type CanvasMedia = {
    dimensionRatio: DimensionRatio | undefined;
    isLoading: boolean;
};

export const canvasMediaAtom2 = atom<CanvasMedia>({
    key: 'canvasMediaAtom2',
    default: {
        dimensionRatio: undefined,
        isLoading: true,
    },
});

type CropImageOptions = {
    fillStyle?: string;
    strokeStyle?: string;
    globalAplpha?: number;
};

export const useCanvasMedia = (): [
    CanvasMedia,
    {
        draw: (data: HTMLVideoElement | HTMLImageElement) => void;
        clear: () => void;
        cropImage: (data: { box: Box; ldm: Point[] }, options?: CropImageOptions) => ImageData | void | null;
        setDimensionRatio: (data: HTMLImageElement) => void;
    }
] => {
    const { videoRef, canvasMediaRef, imageRef, isReady } = useRefsContext();
    const [canvasMediaState, setCanvasMediaState] = useRecoilState(canvasMediaAtom2);
    const { dimensionRatio } = canvasMediaState;
    let canvas = canvasMediaRef.current;
    const ctx = canvas?.getContext('2d', { willReadFrequently: true });
    const [blurPreviewState] = useRecoilState(blurPreviewAtom);
    const currentFrameDetections = useCurrentFrameDetections();

    useEffect(() => {
        if (!canvasMediaRef.current || !imageRef.current) return;
        const canvasMedia = canvasMediaRef.current;

        if (dimensionRatio) {
            clear();
            draw(imageRef.current);
        }

        function handleMediaLoaded(this: HTMLImageElement) {
            let srcElementWidth = this.width;
            let srcElementHeight = this.height;

            canvasMedia.width = 1920;
            canvasMedia.height = 1080;
            const canvasWidth = canvasMedia.width;
            const canvasHeight = canvasMedia.height;
            const scale = 1.0;
            const hRatio = canvasWidth / srcElementWidth;
            const vRatio = canvasHeight / srcElementHeight;
            const ratio = Math.min(hRatio, vRatio);

            const centerShift_x = (canvasWidth - srcElementWidth * ratio) / 2;
            const centerShift_y = (canvasHeight - srcElementHeight * ratio) / 2;
            const frameWidth = srcElementWidth * ratio;
            const frameHeight = srcElementHeight * ratio;

            setCanvasMediaState({
                isLoading: false,
                dimensionRatio: {
                    centerShift_x,
                    centerShift_y,
                    ratio,
                    scale,
                    srcElementHeight,
                    srcElementWidth,
                    frameWidth,
                    frameHeight,
                },
            });
            clear();
            draw(this);
        }

        if (imageRef.current instanceof HTMLImageElement) {
            imageRef.current?.addEventListener('load', handleMediaLoaded);
        }
        return () => {
            if (imageRef.current instanceof HTMLImageElement) {
                imageRef.current?.removeEventListener('load', handleMediaLoaded);
            }
        };
    }, [dimensionRatio, isReady]);

    /**
     * This useEffect is triggered when the ref of the media is mounted.
     * We listen the loaded data then can calculate the ratio to draw the image in the canvas.
     */
    useEffect(() => {
        if (!canvasMediaRef.current || !videoRef.current) return;
        const canvasMedia = canvasMediaRef.current;

        function handleMediaLoaded(this: HTMLVideoElement) {
            let srcElementWidth = this.videoWidth;
            let srcElementHeight = this.videoHeight;

            canvasMedia.width = 1920;
            canvasMedia.height = 1080;
            const canvasWidth = canvasMedia.width;
            const canvasHeight = canvasMedia.height;
            const scale = 1.0;
            const hRatio = canvasWidth / srcElementWidth;
            const vRatio = canvasHeight / srcElementHeight;
            const ratio = Math.min(hRatio, vRatio);

            const centerShift_x = (canvasWidth - srcElementWidth * ratio) / 2;
            const centerShift_y = (canvasHeight - srcElementHeight * ratio) / 2;
            const frameWidth = srcElementWidth * ratio;
            const frameHeight = srcElementHeight * ratio;

            setCanvasMediaState({
                isLoading: false,
                dimensionRatio: {
                    centerShift_x,
                    centerShift_y,
                    ratio,
                    scale,
                    srcElementHeight,
                    srcElementWidth,
                    frameWidth,
                    frameHeight,
                },
            });
            draw(this);
        }
        if (videoRef.current instanceof HTMLVideoElement) {
            videoRef.current?.addEventListener('loadedmetadata', handleMediaLoaded);
        }

        return () => {
            if (videoRef instanceof HTMLVideoElement) {
                videoRef.current?.removeEventListener('loadedmetadata', handleMediaLoaded);
            }
        };
    }, [isReady]);

    const draw = (data: HTMLVideoElement | HTMLImageElement) => {
        if (!dimensionRatio) return null;
        const { srcElementWidth, srcElementHeight, centerShift_x, centerShift_y, ratio } = dimensionRatio;

        if (blurPreviewState) {
            for (let dtc of currentFrameDetections) {
                let squarePath = new Path2D();
                squarePath.rect(dtc.box.x, dtc.box.y, dtc.box.width, dtc.box.height);
                ctx?.clip(squarePath);
            }
        }
        ctx?.drawImage(data, 0, 0, srcElementWidth, srcElementHeight, centerShift_x, centerShift_y, srcElementWidth * ratio, srcElementHeight * ratio);
    };

    const clear = () => {
        if (!canvas) return null;
        ctx?.clearRect(0, 0, canvas.height, canvas.width);
    };

    const cropImage = (data: { box: Box; ldm: Point[] }, options?: CropImageOptions): ImageData | void => {
        const canvasTmp = document.createElement('canvas');
        const canvasTmpCtx = canvasTmp.getContext('2d', { willReadFrequently: true });
        if (!canvasTmpCtx) {
            console.error(`Hook useCanvasMedia: method "cropImage" Anable to create a new canvas ctx.`);
            return;
        }
        if (!ctx || !canvas) {
            console.error(`Hook useCanvasMedia: method "cropImage" No canvas context available.`);
            return;
        }
        const { x, y, height, width } = data.box;
        // first extract image from original canvas
        let borderSize = Math.max(height, width);
        const size = borderSize + 100;
        const centerX = x + width / 2;
        const centerY = y + height / 2;
        const top = centerY - size / 2;
        const left = centerX - size / 2;
        const mediaImageData = ctx.getImageData(left, top, size, size, {});

        // prepare new canvas tmp
        canvasTmp.height = canvas.height;
        canvasTmp.width = canvas.width;
        // get image then draw detection box in new canvas tmp
        canvasTmpCtx.putImageData(mediaImageData, 0, 0);
        // define draw style
        canvasTmpCtx.globalAlpha = 1;
        canvasTmpCtx.lineWidth = 2;
        if (options?.strokeStyle) canvasTmpCtx.strokeStyle = options.strokeStyle;
        if (options?.fillStyle) canvasTmpCtx.fillStyle = options.fillStyle;

        // if detection is a box or a head
        if (data.ldm.length === 0) {
            // calculate new box dimensions for detection
            const newX = Math.floor((size - width) / 2);
            const newY = Math.floor((size - height) / 2);
            const newWidth = Math.floor(size - newX * 2);
            const newHeight = Math.floor(size - newY * 2);
            // draw new detection in canvasTmp
            canvasTmpCtx.strokeRect(newX, newY, newWidth, newHeight);
            canvasTmpCtx.fillRect(newX, newY, newWidth, newHeight);
        }
        // if detection is a series of points
        else {
            canvasTmpCtx.beginPath();
            data.ldm.forEach((point, index) => {
                const adjustedX = point.x - left;
                const adjustedY = point.y - top;
                if (index === 0) {
                    canvasTmpCtx.moveTo(adjustedX, adjustedY);
                } else {
                    canvasTmpCtx.lineTo(adjustedX, adjustedY);
                }
            });
            canvasTmpCtx.closePath();
            canvasTmpCtx.fill();
        }
        return canvasTmpCtx.getImageData(0, 0, size, size);
    };

    const setDimensionRatio = (data: HTMLImageElement) => {
        let srcElementWidth = data.width;
        let srcElementHeight = data.height;
        const canvasMedia = canvasMediaRef.current;
        if (!canvasMedia) return;
        canvasMedia.width = 1920;
        canvasMedia.height = 1080;
        const canvasWidth = canvasMedia.width;
        const canvasHeight = canvasMedia.height;
        const scale = 1.0;
        const hRatio = canvasWidth / srcElementWidth;
        const vRatio = canvasHeight / srcElementHeight;
        const ratio = Math.min(hRatio, vRatio);

        const centerShift_x = (canvasWidth - srcElementWidth * ratio) / 2;
        const centerShift_y = (canvasHeight - srcElementHeight * ratio) / 2;
        const frameWidth = srcElementWidth * ratio;
        const frameHeight = srcElementHeight * ratio;

        setCanvasMediaState({
            isLoading: false,
            dimensionRatio: {
                centerShift_x,
                centerShift_y,
                ratio,
                scale,
                srcElementHeight,
                srcElementWidth,
                frameWidth,
                frameHeight,
            },
        });
    };

    return [canvasMediaState, { draw, clear, cropImage, setDimensionRatio }];
};
