import { atom, useRecoilState } from 'recoil';
import { AnonymizationDetection, DetectionBox } from '../../../../../types';
import { Box, Point } from '../recoil/framesDetectionsCoordinates.atom';
import { DimensionRatio } from './useCanvasMedia';

export type AutoFrameDetection = {
    src: number;
    box: Box;
    ldm: Point[];
    trackId: number;
};

type AutoDetectionsState = {
    isLoading: boolean;
    frames: Record<number, AutoFrameDetection[]>;
};

export const autoDetectionsAtom = atom<AutoDetectionsState>({
    key: 'autoDetectionsAtom',
    default: {
        isLoading: true,
        frames: {},
    },
});

const _convertBoxFromFrameDimensionToCanvas = (box: DetectionBox, dimensionsRatio: DimensionRatio) => {
    const { centerShift_x, centerShift_y, frameWidth, frameHeight } = dimensionsRatio;
    const newBox: DetectionBox = { r: 0, t: 0, l: 0, b: 0 };

    for (const key in box) {
        let value = box[key as keyof DetectionBox];
        if (value < 0) {
            newBox[key as keyof DetectionBox] = 0;
        } else {
            newBox[key as keyof DetectionBox] = value;
        }
    }

    let width = frameWidth * (newBox.r - newBox.l);
    if (width > frameWidth) width = frameWidth;
    let height = frameHeight * (newBox.b - newBox.t);
    if (height > frameHeight) width = frameHeight;

    return {
        width,
        height,
        x: frameWidth * newBox.l + centerShift_x,
        y: frameHeight * newBox.t + centerShift_y,
    };
};

const _convertLandmarkFromFrameDimensionToCanvas = (ldm: Point[] | undefined, dimensionsRatio: DimensionRatio) => {
    const { srcElementHeight, srcElementWidth, centerShift_x, centerShift_y, ratio } = dimensionsRatio;
    const frameWidth = srcElementWidth * ratio;
    const frameHeight = srcElementHeight * ratio;
    if (!ldm) return [];
    return ldm.map(({ x, y }) => {
        return {
            x: frameWidth * x + centerShift_x,
            y: frameHeight * y + centerShift_y,
        };
    });
};

export const useAutoDetections = (): [
    AutoDetectionsState,
    {
        initAutoDetections: (
            frames: Record<string, AnonymizationDetection.DetectionsFrame>,
            dimensionRatio: DimensionRatio
        ) => void;
    }
] => {
    const [autoDetectionsState, setAutoDetectionsState] = useRecoilState(autoDetectionsAtom);

    // This useEffect instantiates the auto detections atom.
    // these detections comes from the detection R&D algorithm.
    // we parse it to transform the dimension to fit with the canvas.
    const initAutoDetections = (
        frames: Record<string, AnonymizationDetection.DetectionsFrame>,
        dimensionRatio: DimensionRatio
    ) => {
        if (!autoDetectionsState.isLoading) return;
        const autoDetectionsFramesTmp: Record<number, AutoFrameDetection[]> = {};
        for (const frameKey in frames) {
            const frame = frames[frameKey];
            const key = parseInt(frameKey);
            autoDetectionsFramesTmp[key] = frame.dtcs.map((dtc) => ({
                src: dtc.src,
                box: _convertBoxFromFrameDimensionToCanvas(dtc.box, dimensionRatio),
                ldm: dtc.ldm ? _convertLandmarkFromFrameDimensionToCanvas(dtc.ldm, dimensionRatio) : [],
                trackId: dtc.trk,
            }));
        }
        setAutoDetectionsState({
            isLoading: false,
            frames: autoDetectionsFramesTmp,
        });
    };

    return [autoDetectionsState, { initAutoDetections }];
};
