import { useContext, useEffect, useState } from 'react';
import { clientContext } from '../context/clientContext';
import { socketContext } from '../context/socketContext';
import { useAxios } from '../services/hook/requestsService';
import Cookies from 'js-cookie';
import { useHistory, useLocation } from 'react-router';
import { consumptionContext } from '../context/consumptionContext';
import { areApiActive, isCardExpired } from '../services/tools';
import { taskManagementContext } from '../context/TaskManagementContext';
import { clientServicesContext } from '../context/clientServicesContext';
import { TASKS_UNAVAILABLE_TYPES, serviceUsableState } from '../recoil/services.atom';
import { useSetRecoilState } from 'recoil';

export const useHeader = () => {
    const userStore = useContext(clientContext);
    const location = useLocation();
    const socketStore = useContext(socketContext);
    const taskManagementStore = useContext(taskManagementContext);
    const clientServiceState = useContext(clientServicesContext);
    const setServiceUsableState = useSetRecoilState(serviceUsableState);
    const { postData } = useAxios();
    const { push } = useHistory();
    const { getConsumptionDataWithoutState } = useContext(consumptionContext);
    const [consumption, setConsumption] = useState<any>(null);
    const [loading, setIsLoading] = useState(false);

    useEffect(() => {
        //api calls & service status
        (async () => {
            const clientServices = await clientServiceState.getData();
            if (!clientServices) {
                setServiceUsableState({
                    status: false,
                    reason: TASKS_UNAVAILABLE_TYPES.SERVICES_ERROR,
                });
            } else if (!areApiActive(clientServices)) {
                setServiceUsableState({
                    status: false,
                    reason: TASKS_UNAVAILABLE_TYPES.INACTIVE_SERVICES,
                });
            }
        })();
    }, []);

    useEffect(() => {
        if (
            userStore.dataClient?.creditCardExpirationDate &&
            isCardExpired(userStore.dataClient.creditCardExpirationDate)
        ) {
            setServiceUsableState({
                status: false,
                reason: TASKS_UNAVAILABLE_TYPES.CARD_EXPIRED,
            });
        }
        if (userStore.dataClient?.isTrial && !loading) {
            setIsLoading(true);
            (async () => {
                const { data } = await getConsumptionDataWithoutState({
                    params: { isTrial: userStore.dataClient?.isTrial },
                });
                setConsumption(data);
                setIsLoading(false);
            })();
        }
    }, [userStore.dataClient, taskManagementStore.refetch, location]);

    // get user initials for button
    const getClientName = () => {
        return userStore && userStore.dataClient && userStore?.dataClient?.name?.length > 0
            ? `${userStore?.dataClient?.name[0] || ''}${userStore?.dataClient?.name[1] || ''}`
            : '';
    };

    //logout user
    const handleLogout = async () => {
        try {
            setServiceUsableState({
                status: true,
            });
            await postData(null, 'logout');
            Cookies.remove('Auth_manager');
            socketStore.disconnectSocket();
            push('/login');
        } catch {
            Cookies.remove('Auth_manager');
            socketStore.disconnectSocket();
            push('/login');
        }
    };

    //get trial state
    const getTrialState = () => {
        if (consumption?.totalCounts) {
            // 100 MB in Bytes
            let totalCount = 104_857_600;
            for (const { size } of consumption?.totalCounts) {
                totalCount -= parseInt(size);
            }
            return totalCount;
        } else {
            return 0;
        }
    };

    return {
        getClientName,
        handleLogout,
        getTrialState,
        isTrial: userStore?.dataClient?.isTrial,
    };
};
