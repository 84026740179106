import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router';
import { getCardData } from '../../../components/plans/data/data';
import { IPlanCard, PlanCard } from '../../../components/plans/planCard/planCard';
import { ReactComponent as ArrowConfirmation } from '../../../static/icons/plans/arrow_confirmation.svg';
import Modal, { IModal } from '../../../components/modal/Modal';
import CustomButton from '../../../components/button/button';
import { clientContext } from '../../../context/clientContext';
import useWindowDimensions from '../../../hook/useWindowDimensions';

// Icon
import { ReactComponent as IconBackSvg } from '../../../static/icons/icon-back-mobile.svg';

export const PaymentStep2Payable: React.FC = () => {
    const { t } = useTranslation();
    const params: any = useParams();
    const [modalState, setModalState] = useState({} as IModal);
    const { dataClient, updateClientSupport } = useContext(clientContext);
    const [loading, setIsLoading] = useState(false);
    const { push } = useHistory();
    const { width } = useWindowDimensions();

    const submitModification = async () => {
        try {
            setIsLoading(true);
            await updateClientSupport({
                type: dataClient?.type,
                address: dataClient?.address,
                lastName: dataClient?.lastName,
                firstName: dataClient?.firstName,
                postalCode: dataClient?.postalCode,
                city: dataClient?.city,
                mail: dataClient?.mail,
                country: dataClient?.country,
                optIn: dataClient?.optIn,
                conditions: true,
                support: params.plan.toUpperCase(),
                companyName: dataClient?.companyName,
            });
            setIsLoading(false);
            push(`/payment/${params.plan}/step-3`);
        } catch (e) {
            setIsLoading(false);
            setModalState({
                ...modalState,
                show: true,
                showCancel: true,
                title: t('error.error-title'),
                message: t('error.internalError'),
                onConfirm: () => setModalState({ show: false }),
            });
        }
    };

    const openModalOnClick = () => {
        setModalState({
            show: true,
            showCancel: true,
            message: t('payment.step-2-payable.modal-message', { planType: params.plan }),
            onCancel: () => setModalState({ ...modalState, show: false }),
            onConfirm: () => submitModification(),
        });
    };

    if (dataClient && dataClient?.support) {
        const cardDataNew = getCardData(t, params.plan) as IPlanCard;
        const cardDataPrev = getCardData(t, dataClient?.support?.toLowerCase()) as IPlanCard;

        return (
            <div className="d-flex flex-column align-items-center justify-content-center plans-component-container step-2 pt-5 p-lg-5">
                <section className="d-flex flex-column align-items-center justify-content-center text-center mb-3 mt-5 mt-lg-0 mb-lg-5">
                    <div
                        className="d-flex d-lg-none d-xl-none step-back me-auto"
                        aria-hidden={width > 992}
                        onClick={() => push('/account/plan')}
                    >
                        <IconBackSvg fill="#6b7897" />
                        Update plan
                    </div>
                    <h2>Subscription</h2>
                    <p className="text-center mx-3 mx-lg-0">
                        {t('payment.step-2-payable.laius-subscription', {
                            planPrev: dataClient?.support?.toLowerCase(),
                            planNew: params.plan,
                        })}
                        <br />
                        {t('payment.step-2-payable.laius-subscription-2')}
                        <br />
                    </p>
                </section>
                <section className="d-flex align-items-center justify-content-center flex-column px-3 px-lg-0 w-100">
                    <div className="plans-card mb-5 w-100">
                        <div className="plans-card-container">
                            <PlanCard
                                title={cardDataPrev.title}
                                price={cardDataPrev.price}
                                laius={cardDataPrev.laius}
                                options={cardDataPrev.options}
                                name={cardDataPrev.name}
                                customClass="disabled mr-3"
                                plan="payment"
                                laius2={t('payment.step-2.laius-2')}
                            />
                        </div>
                        <ArrowConfirmation className="arrow-confirmation" />
                        <div className="plans-card-container">
                            <PlanCard
                                title={cardDataNew.title}
                                price={cardDataNew.price}
                                laius={cardDataNew.laius}
                                options={cardDataNew.options}
                                name={cardDataNew.name}
                                customClass="disabled active"
                                plan="payment"
                                laius2={t('payment.step-2.laius-2')}
                            />
                        </div>
                    </div>
                    <div className="d-flex w-100 pb-4 pb-lg-0 justify-content-center">
                        <CustomButton
                            onClick={() => push('/account/plan')}
                            buttonType="button"
                            buttonText={t('payment.step-2.button-back')}
                            classNameType="mainWhite"
                            customClass="mr-1"
                        />
                        <CustomButton
                            onClick={openModalOnClick}
                            buttonType="button"
                            buttonText={t('payment.step-2.button-submit')}
                        />
                    </div>
                </section>
                <Modal {...modalState} loading={loading} />
            </div>
        );
    } else {
        return null;
    }
};
