import { Trans } from "react-i18next";
import "../../static/scss/error.scss";

export const ErrorHeaderPluginDisabled = () => {
	return (
		<div className="error">
			<span className="color-error">
				<Trans
					i18nKey="header.error-plugin-deactivated"
					values={{ who: "support.services@wassa.io" }}
					components={[
						<a
							href="mailto:support.services@wassa.io"
							className="underline"
						/>,
					]}
				/>
			</span>
		</div>
	);
};
