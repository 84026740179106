import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { licensesContext } from "../../../context/licensesContext";
import moment from "moment";
import Modal, { IModal } from "../../../components/modal/Modal";
import Toggle from "../../../components/toggle/Toggle";
import { License, PaymentRecurrence } from "../../../types/license";
import { capitalize, randomKeyGen } from "../../../services/tools";
import { RoundedLoader } from "../../../components/loader/RoundedLoader";

export const Services = () => {
	const { t } = useTranslation();
	const { data, updateLicenseAutomaticRenewal, loading, error } =
		useContext(licensesContext);
	const [modalState, setModalState] = useState({} as IModal);

	const openModalPluginAutomaticRenewal = (license: License) => {
		const automaticRenewalUpdated = !license.automaticRenewal;

		const title = automaticRenewalUpdated
			? t("services-management.plugin.modal.title.activate")
			: t("services-management.plugin.modal.title.deactivate");

		const message = automaticRenewalUpdated
			? t("services-management.plugin.modal.message.activate")
			: t("services-management.plugin.modal.message.deactivate", {
					endDate:
						license.paymentRecurrence === PaymentRecurrence.MONTHLY
							? moment
									.utc()
									.endOf("month")
									.endOf("day")
									.format("DD/MM/YYYY")
							: moment
									.utc()
									.endOf("year")
									.endOf("day")
									.format("DD/MM/YYYY"),
			  });

		setModalState({
			show: true,
			showCancel: true,
			title,
			message,
			onConfirm: () => {
				setModalState({ ...modalState, show: false });
				updateLicenseAutomaticRenewal(license.uuid, {
					automaticRenewal: automaticRenewalUpdated,
				});
			},
			onCancel: () => setModalState({ ...modalState, show: false }),
			confirmBtnText: t("services-management.plugin.modal.confirm"),
			cancelBtnText: t("services-management.plugin.modal.cancel"),
		});
	};

	useEffect(() => {
		if (error) {
			setModalState({
				show: true,
				showCancel: false,
				title: t("error.error-title"),
				message: t("error.internalError"),
				onConfirm: () => {
					setModalState({ ...modalState, show: false });
				},
				confirmBtnText: t("services-management.plugin.modal.confirm"),
				cancelBtnText: t("services-management.plugin.modal.cancel"),
			});
		}
	}, [error]);

	return (
		<div className="middle-container my-account-view">
			{data?.map((license) => (
				<React.Fragment key={randomKeyGen()}>
					<div className="w-100 d-flex align-items-center mb-3 mb-lg-5">
						<div className="d-flex align-items-center account-title-container">
							<h2 className="account-title mb-0 me-lg-4">
								Plugin {capitalize(license.type)}
							</h2>
							<RoundedLoader isLoading={loading} />
						</div>
					</div>
					<div className="row flex-row">
						<div className="col-12 col-lg-4 mb-3 mb-lg-0">
							<div className="d-flex align-items-center justify-content-md-start">
								<span className="mr-1 text-bold">
									{t("services-management.plugin.renewal")}
								</span>
								<Toggle
									onClick={() =>
										openModalPluginAutomaticRenewal(license)
									}
									isChecked={license.automaticRenewal}
									disabled={loading}
								/>
							</div>
						</div>
						<div className="col-12 col-lg-4 mb-3 mb-lg-0">
							<div className="d-flex align-items-center justify-content-md-start">
								<span className="mr-1 text-bold">
									{t(
										"services-management.plugin.commitment-end-date"
									)}
								</span>
								<span className="account-value">
									{license.dateEnd
										? moment
												.utc(license.dateEnd)
												.format("DD/MM/YYYY")
										: t(
												"services-management.plugin.no-ending-date"
										  )}
									{license.blocked &&
										` (${t(
											"services-management.plugin.service-deactivated"
										)})`}
								</span>
							</div>
						</div>
						<div className="col-12 col-lg-4 mb-3 mb-lg-0">
							<div className="d-flex align-items-center justify-content-md-start">
								<span className="mr-1 text-bold">
									{t(
										"services-management.plugin.trial-ending-date"
									)}
								</span>
								<span className="account-value">
									{moment
										.utc(license.dateStart)
										.add(7, "days")
										.format("DD/MM/YYYY")}
								</span>
							</div>
						</div>
					</div>
				</React.Fragment>
			))}
			<Modal {...modalState} />
		</div>
	);
};
