import { Client } from "./client";

export enum PluginLicenseType {
	ADOBE = "ADOBE",
	OTHER = "OTHER",
}

export enum PaymentRecurrence {
	MONTHLY = "MONTHLY",
	YEARLY = "YEARLY",
}

export type License = {
	uuid: string;
	created_at: Date;
	updated_at: Date;
	type: PluginLicenseType;
	paymentRecurrence: PaymentRecurrence;
	automaticRenewal: boolean;
	blocked: boolean;
	dateStart: Date;
	dateEnd: Date | null;
	client: Client;
	modifications: LicenseModification[];
};

export enum ModificationAction {
	CREATION = "CREATION",
	ACTIVATION = "ACTIVATION",
	DESACTIVATION = "DESACTIVATION",
	PLAN = "PLAN",
	RECCURENCE = "RECCURENCE",
	OTHER = "OTHER",
}

export enum ModificationOrigin {
	CLIENT = "CLIENT",
	ADMIN = "ADMIN",
	SYSTEM = "SYSTEM",
	OTHER = "OTHER",
}

export type LicenseModification = {
	uuid: string;
	created_at: Date;
	updated_at: Date;
	origin: ModificationOrigin;
	action: ModificationAction;
	parameters: Record<string, unknown>;
	license: License;
};
