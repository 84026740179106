import { AxiosRequestConfig } from 'axios';
import React, { createContext, useState } from 'react';
import { useAxios } from '../services/hook/requestsService';

export type Service = {
    freePlanThreshold: number;
    moCost: number;
    name: string;
    serviceCostFactor: number;
    uuid: string;
    searchKey: string;
};

type IResponseService = {
    page: number;
    totalPage: number;
    elementsPerPage: number;
    elements: Service[];
};

export type ServicesState = {
    data: IResponseService | null;
    error: any;
    loading: boolean;
    getData: (config?: AxiosRequestConfig) => Promise<IResponseService>;
};

export const initialState: ServicesState = {
    error: false,
    loading: true,
    data: null,
    getData: async (config?: AxiosRequestConfig) => ({} as IResponseService),
};

const servicesContext = createContext<ServicesState>(initialState);

const ServicesProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [state, setState] = useState(initialState);
    const axiosActions = useAxios();

    const getData = async (config?: AxiosRequestConfig) => {
        try {
            const { data } = await axiosActions.getData(null, 'services', config);
            setState({
                ...state,
                data,
                error: false,
            });
            return data;
        } catch (e) {
            setState({
                ...state,
                data: null,
                error: true,
            });
        }
    };

    return (
        <servicesContext.Provider
            value={{
                ...state,
                loading: axiosActions.loading,
                getData,
            }}
        >
            {children}
        </servicesContext.Provider>
    );
};

export { servicesContext, ServicesProvider };
