import { useRecoilState } from 'recoil';
import { isMediaVideo } from '../hooks/useMedia';
import { _getArrayFromBox, _getBoxFromPointArray } from './helper';
import { useMedia } from './useMedia';
import { atom } from 'recoil';
import { Box, DetectionClassName, Point } from '../recoil/framesDetectionsCoordinates.atom';
import { useCanvasMedia } from './useCanvasMedia';
import { FrameRange } from './useTrackPreview';
import { rectStyle } from '../components/mediaVisualizer/canvasDetections/types';

export type NewTrackPreview = {
    box: Box;
    ldm: Point[];
    frameStart: number;
    frameEnd: number;
    cn: DetectionClassName;
    active: boolean;
    initialRange: FrameRange[];
    thumbPreview: ImageData;
};

export const newTrackCreationPreviewAtom = atom<NewTrackPreview | undefined>({
    key: 'newTrackCreationPreviewAtom',
    default: undefined,
});

export type RangeData = {
    frameStart: number;
    frameEnd: number;
    box: Box;
    ldm: Point[];
};

export const useNewTrackCreationPreview = (): [
    NewTrackPreview | undefined,
    {
        addNewTrackCreationPreview: (data: { cn: DetectionClassName; coordinates: Box | Point[] }) => void;
        removeNewTrackCreationPreview: () => void;
        updateNewTrackCreationPreview: (data: Partial<NewTrackPreview>) => void;
        getCurrentFrameData: () => RangeData | undefined;
        updateRangeTrack: (action: 'min' | 'max', value: number) => void;
    }
] => {
    const [, { cropImage }] = useCanvasMedia();
    const [mediaState] = useMedia();
    const [newTrackCreationPreviewState, setNewTrackCreationPreviewState] = useRecoilState(newTrackCreationPreviewAtom);

    const addNewTrackCreationPreview = (data: { cn: DetectionClassName; coordinates: Box | Point[] }) => {
        const box = Array.isArray(data.coordinates) ? _getBoxFromPointArray(data.coordinates) : data.coordinates;
        let ldm = Array.isArray(data.coordinates) ? data.coordinates : [];
        if (data.cn === DetectionClassName.Plate && !Array.isArray(data.coordinates)) {
            ldm = _getArrayFromBox(data.coordinates);
        }
        let lastFrame = 0;
        if (isMediaVideo(mediaState)) {
            lastFrame = mediaState.nbFrames - 1;
        }
        const cropOptions = {
            strokeStyle: rectStyle['creation'].stroke,
            fillStyle: rectStyle['creation'].fill,
        };
        const image = cropImage({ box, ldm }, cropOptions);
        if (!image) return;
        setNewTrackCreationPreviewState({
            active: true,
            box: box,
            frameStart: mediaState.currentFrame,
            frameEnd: lastFrame,
            ldm: ldm,
            cn: data.cn,
            thumbPreview: image,
            initialRange: [
                {
                    frameStart: mediaState.currentFrame,
                    frameEnd: lastFrame,
                    ldm: ldm,
                    box: box,
                },
            ],
        });
    };

    const removeNewTrackCreationPreview = () => {
        setNewTrackCreationPreviewState(undefined);
    };

    const updateRangeTrack = (action: 'min' | 'max', value: number) => {
        setNewTrackCreationPreviewState((prev) => {
            if (!prev) return undefined;
            const index = prev?.initialRange.findIndex((d) => value >= d.frameStart && value <= d.frameEnd);
            let tmp = [...prev.initialRange];
            if (index >= 0) {
                if (action === 'min') {
                    tmp[index] = {
                        ...tmp[index],
                        frameStart: value,
                    };
                    tmp.splice(0, index);
                }
                if (action === 'max') {
                    tmp[index] = {
                        ...tmp[index],
                        frameEnd: value,
                    };
                    tmp.splice(index + 1);
                }
            } else {
                if (action === 'max') {
                    tmp[tmp.length - 1] = {
                        ...tmp[tmp.length - 1],
                        frameEnd: value,
                    };
                } else {
                    tmp[0] = {
                        ...tmp[0],
                        frameStart: value,
                    };
                }
            }

            return { ...prev, initialRange: tmp };
        });
    };

    const updateNewTrackCreationPreview = (data: Partial<NewTrackPreview>) => {
        setNewTrackCreationPreviewState((prev) => {
            if (prev) return { ...prev, ...data };
            else return undefined;
        });
    };

    const getCurrentFrameData = () => {
        if (
            !newTrackCreationPreviewState ||
            mediaState.currentFrame < newTrackCreationPreviewState.frameStart ||
            mediaState.currentFrame > newTrackCreationPreviewState.frameEnd
        )
            return;
        const currentFrameData = newTrackCreationPreviewState?.initialRange.find(
            (d) => mediaState.currentFrame >= d.frameStart && mediaState.currentFrame <= d.frameEnd
        );
        const currentFrameDataIndex = newTrackCreationPreviewState?.initialRange.findIndex(
            (d) => mediaState.currentFrame >= d.frameStart && mediaState.currentFrame <= d.frameEnd
        );
        if (currentFrameData) {
            return currentFrameData;
        } else return undefined;
    };

    return [
        newTrackCreationPreviewState,
        {
            addNewTrackCreationPreview,
            removeNewTrackCreationPreview,
            updateNewTrackCreationPreview,
            getCurrentFrameData,
            updateRangeTrack,
        },
    ];
};
