import { getData } from '../services/axios.services';
import { GetManyHistoryCallsRequest, GetManyHistoryCallsResponse } from '../types/historyCall';

export const getManyHistoryCall = async (params: GetManyHistoryCallsRequest) => {
    return (
        await getData<GetManyHistoryCallsResponse>({
            url: `/history-call`,
            params,
        })
    ).data;
};
