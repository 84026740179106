import { Box, Point } from '../recoil/framesDetectionsCoordinates.atom';

export const _getBoxFromPointArray = (points: Point[]): Box => {
    let minX = Infinity;
    let minY = Infinity;
    let maxX = -Infinity;
    let maxY = -Infinity;

    for (let point of points) {
        minX = Math.min(minX, point.x);
        minY = Math.min(minY, point.y);
        maxX = Math.max(maxX, point.x);
        maxY = Math.max(maxY, point.y);
    }
    let width = maxX - minX;
    let height = maxY - minY;
    return {
        x: minX,
        y: minY,
        width,
        height,
    };
};

export const _getArrayFromBox = (box: Box): Point[] => {
    // Assurer que width et height sont positifs
    const absWidth = Math.abs(box.width);
    const absHeight = Math.abs(box.height);

    // Déterminer le coin supérieur gauche réel en cas de valeurs negatives
    const startX = box.width < 0 ? box.x - absWidth : box.x;
    const startY = box.height < 0 ? box.y - absHeight : box.y;
    return [
        { x: startX, y: startY }, // Coin supérieur gauche
        { x: startX + absWidth, y: startY }, // Coin supérieur droit
        { x: startX + absWidth, y: startY + absHeight }, // Coin inférieur droit
        { x: startX, y: startY + absHeight }, // Coin inférieur gauche
    ];
};
