import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import CustomButton from '../../../components/button/button';
import Input from '../../../components/input/Input';
import { withCookies } from 'react-cookie';
import RoundedLoader from '../../../components/loader/RoundedLoaderAnimated';
import ErrorComponent from '../../../components/error/errorComponent';
import { useQuery } from '../../../services/hook/useQuery';
import { LoginInput, LoginResponse } from '../../../api/types';
import { useAuth } from '../../../hook/api/useAuth';
import { addRefreshInterceptor, setAuthCookie } from '../../../api/authApi';
import { useAxios } from '../../../services/hook/requestsService';

const initialValues = {
    email: '',
    password: '',
};

const Login: React.FC<any> = () => {
    const { t } = useTranslation();
    const { push } = useHistory();
    const query = useQuery();
    const redirection = query.get('redirect');
    const {
        logUser: { isLoading: loading, isError, mutate, error },
    } = useAuth();
    const axiosActions = useAxios();
    const [secondaryLoading, setSecondaryLoading] = useState(false);

    const isFormInValid = (values: any): boolean => {
        return values.email.length <= 3 || values.password.length <= 3;
    };

    const onSuccess = async (data: LoginResponse) => {
        try {
            setSecondaryLoading(true);

            setAuthCookie(data);
            addRefreshInterceptor();

            if (redirection) {
                push(redirection);
                return;
            }

            const { data: client } = await axiosActions.getData(null, 'client');
            setSecondaryLoading(false); // End secondary loading after the call

            // check if client has licenses to determine if he is a plugin user
            if (client.licenses && client.licenses.length > 0) {
                push('/plugin/ressources');
            } else {
                push('/task-manager');
            }
        } catch (e) {
            setSecondaryLoading(false); // End secondary loading after the call
            console.error(e);
            push('/task-manager');
        }
    };

    const submitLogin = (params: LoginInput) => {
        let paramsTmp = { ...params, email: params.email.toLocaleLowerCase() };
        mutate(paramsTmp, { onSuccess });
    };

    // Determine overall loading state
    const isLoading = loading || secondaryLoading;

    return (
        <Formik initialValues={initialValues} onSubmit={submitLogin}>
            {({ values, handleChange }) => {
                return (
                    <Form className="d-flex flex-column justify-content-between">
                        <div className="mb-4">
                            <Input
                                isInvalid={isError}
                                id="login"
                                name="email"
                                icon="login"
                                value={values.email}
                                placeholder={t('login.emailPlaceholder')}
                                isRequired
                                onChange={handleChange as any}
                                inputType="text"
                                inputClassName="rounded"
                                labelTranslation={t('login.emailLabel')}
                            />
                            <Input
                                isInvalid={isError}
                                id="password"
                                icon="password"
                                name="password"
                                value={values.password}
                                placeholder={t('login.passwordPlaceholder')}
                                isRequired
                                onChange={handleChange as any}
                                inputType="password"
                                inputClassName="rounded"
                                labelTranslation={t('login.passwordLabel')}
                                maskable={true}
                            />
                        </div>
                        {isLoading ? (
                            <div className="mb-4 d-flex justify-content-center">
                                <RoundedLoader isLoading={isLoading} />
                            </div>
                        ) : (
                            <div className="mb-4 d-flex flex-column align-items-center justify-content-center">
                                <CustomButton
                                    customClass="mb-3"
                                    disabled={isFormInValid(values)}
                                    buttonText={t('login.signIn')}
                                    classNameType="main"
                                />
                                <Link id="forgotten-password" to="/forgotten-password">
                                    {t('login.forgotten-password')}
                                </Link>
                            </div>
                        )}
                        {isError && error ? (
                            <ErrorComponent error={error} customError={t('login.invalidCredentials') as string} />
                        ) : null}
                    </Form>
                );
            }}
        </Formik>
    );
};

export default withCookies(Login) as any;
