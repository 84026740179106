import React, { createContext, useContext, useEffect, useState } from "react";
import { consumptionContext, ConsumptionState } from "./consumptionContext";
import { Service, servicesContext, ServicesState } from "./servicesContext";
import {
  clientServicesContext,
  ClientServicesState,
} from "./clientServicesContext";
import { useTranslation } from "react-i18next";
import {
  formatApiCallsAndConsumption,
  formatSelectOptions,
  formatActiveServices,
} from "../pages/dashboard/helper/formatData";
import moment from "moment";
import { clientContext } from "./clientContext";

type DashboardData = {
  isActive: boolean;
  totalConsumption: string;
  totalConsumptionThisMonth: string;
  totalConsumptionThisDay: string;
  totalCall: number;
  totalCallThisMonth: number;
  totalCallThisDay: number;
};

type Dashboard = {
  consumptionState: ConsumptionState;
  servicesState: ServicesState;
  clientServiceState: ClientServicesState;
  isLoading: boolean;
  servicesListSelect: { label: string; value: string }[] | [];
  apiCallsAndConsumption: DashboardData;
  dashboardTableData: any;
  refetchData: (value: boolean) => void;
  handleSelect: (e: any) => void;
  selectOptions: any;
  selected: any;
  refetch: boolean;
};

const dashboardContext = createContext({} as Dashboard);

const DashboardProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [dashboardState, setDashboardState] = useState({} as Dashboard);
  const [refetch, setRefetch] = useState(false);
  const clientState = useContext(clientContext);
  const clientServiceState = useContext(clientServicesContext);
  const servicesState = useContext(servicesContext);
  const consumptionState = useContext(consumptionContext);
  const [selectOptions, setSelectOptions] = useState<any[] | null>(null);
  const [selected, setSelect] = useState<any>(null);
  const { t } = useTranslation();

  const refetchData = (value: boolean) => {
    setRefetch(value);
  };

  const handleSelect = (e: any) => {
    setSelect(e);
    refetchData(true);
  };

  useEffect(() => {
    if (refetch && clientState.dataClient) {
      (async () => {
        setDashboardState({
          ...dashboardState,
          isLoading: true,
        });
        //api calls & service
        const clientServices = await clientServiceState.getData();
        const thisDayConsumption = await consumptionState.getData({
          params: {
            service: selected?.value !== "all" ? selected?.value : undefined,
            period: "day",
            dateStart: moment().format("YYYY-MM-DDTHH:mm:ss"),
            isTrial: clientState.dataClient?.isTrial,
          },
        });
        const thisMonthConsumption = await consumptionState.getData({
          params: {
            service: selected?.value !== "all" ? selected?.value : undefined,
            period: "month",
            dateStart: moment().startOf("month").format("YYYY-MM-DDTHH:mm:ss"),
            isTrial: clientState.dataClient?.isTrial,
          },
        });

        let i = 1;
        let totalPage = 1;
        let servicesElements: Service[] = [];

        while (i <= totalPage) {
          const currentServices = await servicesState.getData({
            params: { page: i },
          });
          servicesElements = [...servicesElements, ...currentServices.elements];
          i++;
          totalPage = currentServices.totalPage;
        }

        //format data for select
        if (!selected && !selectOptions) {
          const servicesListSelect = formatSelectOptions(servicesElements, t);
          setSelect(servicesListSelect.selected);
          setSelectOptions(servicesListSelect.options);
        }
        //set data into global store
        setDashboardState({
          ...dashboardState,
          isLoading: false,
          selected,
          dashboardTableData: formatActiveServices(
            clientServices,
            servicesElements
          ),
          apiCallsAndConsumption: formatApiCallsAndConsumption(
            thisDayConsumption,
            thisMonthConsumption,
            clientServices,
            selected
          ),
        });
        setRefetch(false);
      })();
    }
  }, [refetch, clientState.dataClient]);

  return (
    <dashboardContext.Provider
      value={{
        ...dashboardState,
        consumptionState,
        servicesState,
        clientServiceState,
        selectOptions,
        selected,
        handleSelect,
        refetchData,
      }}
    >
      {children}
    </dashboardContext.Provider>
  );
};

export { DashboardProvider, dashboardContext };
