import { useEffect, useMemo } from 'react';
import { HeaderActions } from './components/headers/HeaderActions';
import { HeaderLogo } from './components/headers/HeaderLogo';
import { MediaControls } from './components/mediaControls/MediaControls';
import { MediaVisualizer } from './components/mediaVisualizer/MediaVisualizer';
import { Sidebar } from './components/sidebar/Sidebar';
import { useInitStudio } from './hooks/useInitStudio';
import './styles/layout.scss';

export const Studio = () => {
    useInitStudio();

    return (
        <div className="studio-layout">
            <div className="studio-layout__header">
                <div className="studio-layout__header__logo">
                    <HeaderLogo />
                </div>
                <div className="studio-layout__header__actions">
                    <HeaderActions />
                </div>
            </div>
            <div className="studio-layout__container">
                <div className="studio-layout__container__sidebar">
                    <Sidebar />
                </div>
                <div className="studio-layout__container__editor">
                    <MediaVisualizer />
                    <div className="studio-layout__container__editor__media-controls">
                        <MediaControls />
                    </div>
                </div>
            </div>
        </div>
    );
};
