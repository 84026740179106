import Cookies from 'js-cookie';
import { instance } from './axios';
import { LoginInput, LoginResponse, RefreshTokenInput } from './types';

export const getAuthCookie = (): LoginResponse | undefined => Cookies.getJSON('Auth_manager') ?? undefined;
export const getRefreshTokenCookie = (): RefreshTokenInput | undefined => Cookies.getJSON('Auth_refresh') ?? undefined;

export const setAuthCookie = (data: LoginResponse): void => {
    Cookies.set(
        'Auth_manager',
        JSON.stringify({
            token: data.token,
        }),
        { expires: 30, path: '/' }
    );
    Cookies.set(
        'Auth_refresh',
        JSON.stringify({
            refreshToken: data.refreshToken,
        }),
        { expires: 30, path: '/' }
    );
};

export const logUserFn = async (params: LoginInput) => {
    const response = await instance.post<LoginResponse>('/login', params);
    return response.data;
};

export const refreshAccessToken = async (params: RefreshTokenInput) => {
    const response = await instance.post<LoginResponse>('/refresh_token', params);
    return response.data;
};

instance.interceptors.request.use(
    (config) => {
        const authCookie = getAuthCookie();
        if (authCookie) {
            config.headers['Authorization'] = 'Bearer ' + authCookie.token;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

const UNPROTECTED_ROUTES = ['/login', 'password_reset/token', 'password_reset', 'logout'];

export const addRefreshInterceptor = (callback?: (data: any) => void) => {
    instance.interceptors.response.use(
        async (response) => {
            return response;
        },
        async (error) => {
            const originalConfig = error.config;
            if (
                error.response &&
                error.response.status === 401 &&
                !UNPROTECTED_ROUTES.includes(originalConfig.url) &&
                !originalConfig._retry
            ) {
                originalConfig._retry = true;
                // If the response is 401, it means the token is not valid anymore.
                // Retry the original request after token refresh.
                try {
                    const refreshTokenCookie = getRefreshTokenCookie();

                    if (!refreshTokenCookie || !refreshTokenCookie.refreshToken) {
                        throw error;
                    }
                    const response = await refreshAccessToken(refreshTokenCookie as RefreshTokenInput);

                    setAuthCookie(response);
                    return instance(originalConfig);
                } catch (e) {
                    throw e;
                }
            } else {
                // Handle other errors
                return Promise.reject(error);
            }
        }
    );
};
