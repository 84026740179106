import React from 'react';
import './payment-header.scss';
import { ReactComponent as WisSvg } from '../../static/images/logo-WIS.svg';
import { BreadCrumb } from './components/breadcrumb';
import { Link } from 'react-router-dom';
import useWindowDimensions from '../../hook/useWindowDimensions';
import Header from '../../components/header/Header';

export const PaymentHeader: React.FC = () => {
    const { width } = useWindowDimensions();

    return (
        <>
            {width > 992 ? (
                <div id="cancel-button" className="payment-header">
                    <div className="wis-logo-container">
                        <Link to={'/account'}>
                            <WisSvg height={60} />
                        </Link>
                    </div>

                    <BreadCrumb />

                    <div className="right" aria-hidden={true}>
                        {/* Ne sert qu'à centrer, ne pas toucher */}
                    </div>
                </div>
            ) : (
                <Header />
            )}
        </>
    );
};
