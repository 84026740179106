import { atom, useRecoilState } from 'recoil';

const videoBufferAtom = atom<number>({
    key: 'videoBufferAtom',
    default: 0,
});

export const useVideoBuffer = (): [number, { updateVideoBufferState: (value: number) => void }] => {
    const [videoBufferState, setVideoBufferState] = useRecoilState(videoBufferAtom);

    const updateVideoBufferState = (value: number) => {
        setVideoBufferState(value);
    };

    return [videoBufferState, { updateVideoBufferState }];
};
