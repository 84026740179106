import { useTranslation } from 'react-i18next';
import { useAxios } from '../../services/hook/requestsService';
import '../../static/scss/error.scss';
import CustomButton from '../button/button';
import useWindowDimensions from '../../hook/useWindowDimensions';

export const ErrorHeaderCardExpired = () => {
    const { t } = useTranslation();
    const { putData } = useAxios();
    const { width } = useWindowDimensions();

    const submitUpdateCard = async () => {
        const response = await putData(null, `client/payment-method`, null, { headers: { 'Content-Length': 0 } });
        window.location = response.data.url;
    };

    return (
        <>
            {
                width < 992 ? (
                    <>
                        <div className="trial-text">
                            {t('header.error-card-deactivated')}
                        </div>

                        <div className="trial-action">
                            <CustomButton buttonType="button" buttonText={t('account-management.update-cb-mobile')} onClick={submitUpdateCard} />
                        </div>
                    </>
                ) : (
                    <div className="error">
                        <span className="color-error mr-2">{t('header.error-card-deactivated')}</span>
                        <CustomButton buttonType="button" buttonText={t('account-management.update-cb')} onClick={submitUpdateCard} />
                    </div>
                )
            }
        </>
    );
};