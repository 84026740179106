import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useRecoilRefresher_UNSTABLE, useRecoilValue, useResetRecoilState } from 'recoil';
import { cardsAtom } from '../components/sidebar/cards/hook/cards.atom';
import { useRefsContext } from '../contexts/RefsContexts';
import { autoDetectionsAtom, useAutoDetections } from './useAutoDetections';
import { useCanvasMedia } from './useCanvasMedia';
import { frameDetectionsSelector, useCurrentFrameDetections } from './useCurrentFrameDetections';
import { isStudioDataSuccess, useFetchStudioData } from './useFetchStudioData';
import { framesTimestampsAtom, useFramesTimestamps } from './useFramesTimestamp';
import { loadingStateAtom } from './useLoadingState';
import { mediaAtom, useMedia } from './useMedia';
import { readableDetectionsAtom } from './useReadDetections';
import { thumbsGridAtom } from './useThumbsGrid';
import { trackPreviewAtom } from './useTrackPreview';
import { tracksAtom, useTracks } from './useTracks';

export const useInitStudio = () => {
    const { jobId } = useParams() as { jobId: string };
    // get data from api
    const fetchedStudioDataState = useFetchStudioData();

    const [, { initMedia }] = useMedia();
    const [, { initTracks }] = useTracks();
    const [, { initFramesTimestamps }] = useFramesTimestamps();
    const [{ dimensionRatio }] = useCanvasMedia();
    const [, { initAutoDetections }] = useAutoDetections();

    const resetTracks = useResetRecoilState(tracksAtom);
    const resetPreview = useResetRecoilState(trackPreviewAtom);
    const resetAutoDetections = useResetRecoilState(autoDetectionsAtom);
    const resetFramesTimestamps = useResetRecoilState(framesTimestampsAtom);
    const resetLoading = useResetRecoilState(loadingStateAtom);
    const resetMedia = useResetRecoilState(mediaAtom);
    const resetThumbsGrid = useResetRecoilState(thumbsGridAtom);
    const resetCard = useResetRecoilState(cardsAtom);
    const resetReadable = useResetRecoilState(readableDetectionsAtom);
    // First step
    useEffect(() => {
        if (isStudioDataSuccess(fetchedStudioDataState)) {
            // init media atom
            initMedia(fetchedStudioDataState);
            // init tracks atom
            initTracks(fetchedStudioDataState.framesInfo);
            // init timestamps atom
            initFramesTimestamps(fetchedStudioDataState?.framesInfo?.timestamps?.frames);
        }
    }, [fetchedStudioDataState, jobId]);

    // Second step
    useEffect(() => {
        if (isStudioDataSuccess(fetchedStudioDataState) && dimensionRatio) {
            // init auto detections object
            initAutoDetections(fetchedStudioDataState.framesInfo.framesSorted.frames, dimensionRatio);
        }
    }, [dimensionRatio, jobId]);

    useEffect(() => {
        return () => {
            resetTracks();
            resetPreview();
            resetAutoDetections();
            resetFramesTimestamps();
            resetLoading();
            resetMedia();
            resetThumbsGrid();
            resetCard();
            resetReadable();
        };
    }, [jobId]);
};
