import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import CustomButton from "../../../components/button/button";
import { Client } from "../../../context/clientContext";

export const AccountInformation: React.FC<{ clientValues: Client | null }> = ({
	clientValues,
}) => {
	const { t } = useTranslation();
	const { push } = useHistory();

	const handleNavigation = (key: string) => {
		push(key);
	};

	const getPlan = () => {
		if (clientValues?.isTrial) {
			return t("account-management.plan-trial");
		}
		switch (clientValues?.support) {
			case "BASIC": {
				return t("account-management.plan-basic");
			}
			case "STANDARD": {
				return t("account-management.plan-standard");
			}
			case "PREMIUM": {
				return t("account-management.plan-premium");
			}
			default: {
				return "-";
			}
		}
	};

	return (
		<div className="middle-container my-account-view">
			<div className="w-100 d-flex align-items-center justify-content-between mb-3 mb-lg-5">
				<div className="d-flex align-items-center">
					<h2 className="account-title mb-0 me-lg-4">
						{t("account-management.account-information")}
					</h2>
					<div className="d-none d-lg-block d-xl-block plan-container">
						{getPlan()}
					</div>
				</div>
				<CustomButton
					classNameType="mainWhite"
					onClick={() => handleNavigation("/account/plan")}
					buttonText={t("account-management.update-plan")}
					buttonType="button"
				/>
			</div>
			<div className="row flex-row mb-0 mb-lg-4">
				<div className="col-12 col-lg-4 mb-3 mb-lg-0">
					<div className="account-label-creation d-flex align-items-center justify-content-md-start">
						<span className="mr-1 text-bold">
							{t("account-management.creation-date")}
						</span>
						<span className="account-value">
							{moment(clientValues?.date?.created).format(
								"DD/MM/YYYY"
							) || "-"}
						</span>
					</div>
				</div>
				<div className="col-12 col-lg-4">
					<div className="account-label-creation d-flex align-items-center justify-content-md-start">
						<span className="mr-1 text-bold">Plan type </span>
						<span className="account-value">{getPlan()}</span>
					</div>
				</div>
			</div>
		</div>
	);
};
