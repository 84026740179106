import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import RoundedLoader from "../components/loader/RoundedLoaderAnimated";
import CustomSelect from "../components/select/CustomSelect";
import { dashboardContext } from "../context/DashboardContext";
import "../static/scss/dashboard.scss";
import moment from "moment";
import { areApiActive } from "../services/tools";
import TaskButton from "../components/mobile/task/TaskButton";
import useWindowDimensions from "../hook/useWindowDimensions";

// Icon
import { ReactComponent as IconFilter } from "../static/icons/icon-filter.svg";

const DashboardLayout: React.FC<{ children: React.ReactNode }> = ({
	children,
}) => {
	const { t } = useTranslation();
	const dashboardStore = useContext(dashboardContext);
	const [isLoaded, setIsLoaded] = useState(false);
	const [visibleFilter, setVisibleFilter] = useState<boolean>(false);
	const { width } = useWindowDimensions();

	useEffect(() => {
		if (!isLoaded) {
			dashboardStore.refetchData(true);
			setIsLoaded(true);
		}
	}, [dashboardStore, isLoaded, setIsLoaded]);

	const apiActive = useMemo(() => {
		if (dashboardStore.clientServiceState?.data) {
			return areApiActive(dashboardStore.clientServiceState.data);
		}
		return false;
	}, [dashboardStore?.clientServiceState]);

	const handleMobileFilter = () => {
		setVisibleFilter(!visibleFilter);
	};

	return (
		<div className="">
			{/* TODO: En attente de confirmation avant de le supprimer */}
			{/* <div className='mb-4'>
        <Collapse title={t("dashboard.services-general-state")} isOpen={!isLoaded}>
          <DashboardTable />
        </Collapse>
      </div> */}
			<div className="middle-container">
				<div className="d-flex justify-content-between align-items-center flex-wrap mb-3">
					<div className="d-flex align-items-center w-100-mobile">
						<h2 className="middle-container-title mr-1">
							{t("dashboard.api-status")}
						</h2>
						<div
							className={`dashboard-label-${
								apiActive ? "active" : "inactive"
							} d-flex align-items-center activation-container ${
								apiActive ? "active" : "inactive"
							}`}
						>
							<label>
								{apiActive
									? t("services-management.service.activated")
									: t(
											"services-management.service.deactivated"
									  )}
							</label>
							{apiActive ? (
								<span className="ms-lg-3">
									{apiActive
										? moment(
												dashboardStore
													?.clientServiceState
													?.data?.[0].serviceStart
										  ).format("YYYY-MM-DD")
										: ""}
								</span>
							) : null}
						</div>

						<button
							type="button"
							className={`button btn-filter-mobile${
								visibleFilter ? " active" : ""
							} ms-auto`}
							disabled={dashboardStore.isLoading}
							onClick={handleMobileFilter}
						>
							{dashboardStore.isLoading ? (
								<RoundedLoader
									isLoading={dashboardStore.isLoading}
								/>
							) : (
								<IconFilter fill="#6B7897" />
							)}
						</button>
					</div>
					<div
						className="d-flex align-items-center w-50-mobile ms-auto"
						data-open={visibleFilter}
					>
						{width > 991 && (
							<div className="me-lg-4">
								<RoundedLoader
									isLoading={dashboardStore.isLoading}
								/>
							</div>
						)}

						<div className="select-container">
							<CustomSelect
								options={dashboardStore.selectOptions}
								onChange={dashboardStore.handleSelect}
								value={dashboardStore.selected}
							/>
						</div>
					</div>
				</div>
				{children}
			</div>
			<TaskButton />
		</div>
	);
};

export default DashboardLayout;
