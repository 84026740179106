import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";
import CustomCalendar from "../components/calendar/calendar";
import FilterServicesManagement from "../components/filter/filterServicesManagement";
import { MobileCalendar } from "../components/mobile/calendar/mobileCalendar";
import { CustomCalendarProvider } from "../context/calendarContext";
import { servicesManagementContext } from "../context/ServicesManagementContext";
import useWindowDimensions from "../hook/useWindowDimensions";
import { listApiServices } from "../pages/servicesManagement/helper/formatData";
import "../static/scss/services-management.scss";

const ServicesManagementLayout: React.FC<{ children: React.ReactNode }> = ({
	children,
}) => {
	const { t } = useTranslation();
	const [selectValue, setSelectValue] = useState<any>("");
	const servicesManagementStore = useContext(servicesManagementContext);
	const location = useLocation();
	const { push } = useHistory();
	const { width } = useWindowDimensions();

	const [isLoaded, setIsLoaded] = useState(false);

	const handleChangeService = (e: any) => {
		setIsLoaded(false);
		// handle api calls & consumption for specific service
		servicesManagementStore.handleCurrentService(listApiServices[e.value]);

		if (e.value === "ALL") {
			push("/account/services");
		} else {
			push(`/account/services/${e.value}`);
		}
	};

	useEffect(() => {
		if (!isLoaded) {
			servicesManagementStore.refetchData(true);

			setIsLoaded(true);
		}
	}, [servicesManagementStore, isLoaded, setIsLoaded]);

	useEffect(() => {
		if (location.pathname === "/account/services") {
			setSelectValue({
				label: t(`common.services-service-config.all`),
				value: "ALL",
			});
		} else {
			const pathParam = location.pathname.split("/")[3];
			setSelectValue(
				servicesManagementStore.servicesListSelect?.find(
					({ value }) => value === pathParam
				) || ""
			);
		}
	}, [location, setSelectValue, t, servicesManagementStore]);

	return (
		<CustomCalendarProvider>
			<div className="services-management-container">
				<div className="mb-3">
					<FilterServicesManagement
						title={t("services-management.list-title")}
						loading={servicesManagementStore.isLoading}
						selectProps={{
							options:
								servicesManagementStore?.servicesListSelect,
							onChange: handleChangeService,
							value: selectValue,
						}}
					/>
				</div>
				{children}
			</div>
			{width > 992 ? <CustomCalendar /> : <MobileCalendar />}
		</CustomCalendarProvider>
	);
};

export default ServicesManagementLayout;
