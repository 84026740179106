import { useEffect } from 'react';
import { useRefsContext } from '../../../contexts/RefsContexts';
import { useCanvasMedia } from '../../../hooks/useCanvasMedia';
import { useLoadingState } from '../../../hooks/useLoadingState';
import { useThumbsGrid } from '../../../hooks/useThumbsGrid';
import { useMedia } from '../../../hooks/useMedia';

export const ImagePreview = () => {
    const { canvasMediaRef, imageRef, isReady, updateRefReadyState } = useRefsContext();
    const [{ src }] = useMedia();

    useEffect(() => {
        if (imageRef.current && !isReady.imageRef) updateRefReadyState('imageRef', true);
        if (canvasMediaRef.current && !isReady.canvasMediaRef) updateRefReadyState('canvasMediaRef', true);
    }, [imageRef.current, canvasMediaRef.current]);

    return (
        <>
            <canvas style={{ height: '100%', width: '100%' }} height={1080} width={1920} className="canvas-media" ref={canvasMediaRef}></canvas>
            <img ref={imageRef} hidden={true} crossOrigin="anonymous" src={src}></img>
        </>
    );
};
