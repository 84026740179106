import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as LogoSvg } from '../static/images/logo-WIS.svg';
import { useLocation } from 'react-router-dom';
import '../static/scss/auth.scss';
import { authLayoutContext } from '../context/authLayoutContext';

/**
 * display the authentication layout for every pages with right image panel + logo + title
 * concerned pages are login, register-password, forgotten-password.
 * !Le nom de l'url est en lien avec la traduction
 */
const AuthLayout: React.FC<{ children: React.ReactNode }> = ({ children, ...rest }) => {
    const { t } = useTranslation();

    //get /pageName
    const { pathname } = useLocation();

    //get pageName
    const location = pathname.split('/')[1];

    const authStore = useContext(authLayoutContext);

    return (
        <div className="row h-100" style={{ marginRight: 0 }}>
            <div className="col-12 col-lg-6">
                <div className="h-100 d-flex flex-column justify-content-center align-items-center">
                    <div className="d-flex flex-column flex-start auth-container">
                        <div>
                            <div className="mb-5 d-flex justify-content-center">
                                <LogoSvg />
                            </div>
                            <div className="mb-2 sub-container">
                                <h1 className="mb-3">{t(`${location}.title`)}</h1>
                                {authStore.displayLaius && (
                                    <p id="login-laius">
                                        {t(`${location}.laius`)}
                                        {location === 'login' && (
                                            <a
                                                className="create-account"
                                                href={`${process.env.REACT_APP_WIS_LANDING_FRONT_ENDPOINT}/pricing`}
                                                title="Create account"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                {t('login.create-account')}
                                            </a>
                                        )}
                                    </p>
                                )}
                            </div>
                        </div>
                        <div>{children}</div>
                    </div>
                </div>
            </div>
            <div className="col-6 d-none d-lg-block" id="image-login"></div>
        </div>
    );
};

export default AuthLayout;
