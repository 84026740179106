import { ReactComponent as LogoStudioSvg } from '../../../../../../static/icons/studio/icon_logo-studio.svg';
import { ReactComponent as BackArrow } from '../../../../../../static/icons/icon_fleche-color.svg';
import { useHistory } from 'react-router-dom';
import '../../styles/headers.scss';
import Modal, { IModal } from '../../../../../../components/modal/Modal';
import { useState } from 'react';
import { useApiCalls } from './HeaderActions';

export const HeaderLogo = () => {
    const { push } = useHistory();
    const [modalState, setModalState] = useState({} as IModal);

    const handleCloseModal = () => setModalState({ show: false });

    const handleClickBack = () => {
        setModalState({
            show: true,
            message: `You are about to exit the studio interface. All your unsaved changes will be lost. Please confirm quitting.`,
            showCancel: true,
            onCancel: () => setModalState({ show: false }),
            cancelBtnText: 'Cancel',
            confirmBtnText: 'Exit Studio',
            onConfirm: () => {
                handleCloseModal();
                push('/task-manager');
            },
        });
    };

    return (
        <div className="header-logo">
            <button onClick={handleClickBack} />
            <LogoStudioSvg />
            <Modal {...modalState} />
        </div>
    );
};
