import { Stage } from 'konva/lib/Stage';
import React, { useEffect } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { DimensionRatio, useCanvasMedia } from '../../../../../hooks/useCanvasMedia';
import { isMediaVideo, mediaAtom } from '../../../../../hooks/useMedia';
import { useNewTrackCreationPreview } from '../../../../../hooks/useNewTrackCreationPreview';
import { useTracks } from '../../../../../hooks/useTracks';
import { canvasDrawModeAtom } from '../../../../../recoil/canvasDrawMode.atom';
import { Box, DetectionClassName, Point } from '../../../../../recoil/framesDetectionsCoordinates.atom';
import { trackEditorAtom } from '../../../../../recoil/trackEditor.atom';
import { CreationCard } from '../../../../sidebar/cards/components/CreationCard';
import { useCards } from '../../../../sidebar/cards/hook/useCards';
import { useStageDraw, isDrawBox, isDrawPoints } from '../../hooks/useStageDraw';
import { HeadDetection } from '../shapes/HeadDetection';
import { OtherDetection } from '../shapes/OtherDetection';
import { PlateDetection } from '../shapes/PlateDetection';

type ShapesDrawerProps = {
    isDrawing: boolean;
    setIsDrawing: (data: boolean) => void;
};

export const ShapesDrawer = React.forwardRef<Stage, ShapesDrawerProps>((props, ref) => {
    const [stageDrawState, { removeShape }] = useStageDraw(ref);
    const setCanvasDrawModeState = useSetRecoilState(canvasDrawModeAtom);
    const [cards, { addCard, closeCard }] = useCards();
    const [
        newTrackCreationPreviewState,
        { addNewTrackCreationPreview, removeNewTrackCreationPreview, updateNewTrackCreationPreview, updateRangeTrack },
    ] = useNewTrackCreationPreview();

    const setTrackEditorState = useSetRecoilState(trackEditorAtom);
    const mediaState = useRecoilValue(mediaAtom);
    const [, { addTrack }] = useTracks();
    const [{ dimensionRatio }] = useCanvasMedia();

    useEffect(() => {
        if (!ref || typeof ref === 'function') return;
        props.setIsDrawing(stageDrawState.isDrawing);
        if (stageDrawState.isDrawing && stageDrawState.shape !== DetectionClassName.Other) {
            ref.current?.on('mouseup', () =>
                hanldeEndCreateShape({ cn: stageDrawState.shape, data: (stageDrawState as any).box })
            );
        }
        return () => {
            ref.current?.off('mouseup');
        };
    }, [stageDrawState]);

    const _prepareDimensions = (shapeDimensions: Box | Point[], dimensionRatio: DimensionRatio) => {
        if (Array.isArray(shapeDimensions)) {
            let data = [...shapeDimensions];
            let isInverted = false;
            if (data[0].x > data[1].x) {
                data.splice(data.length - 1);
                const firstElement = data[0];
                data.splice(0, 1);
                data.reverse();
                data.unshift(firstElement);
                isInverted = true;
            }
            data = data.map((point) => {
                let x = point.x;
                let y = point.y;
                if (point.x < dimensionRatio.centerShift_x) x = dimensionRatio.centerShift_x;
                if (point.y < dimensionRatio.centerShift_y) y = dimensionRatio.centerShift_y;
                return {
                    x,
                    y,
                };
            });
            if (!isInverted) data.pop();
            return data;
        } else {
            let data = { ...shapeDimensions };

            if (data.height < 0) {
                data.y = data.y + data.height;
                data.height = Math.abs(data.height);
            }
            if (data.width < 0) {
                data.x = data.x + data.width;
                data.width = Math.abs(data.width);
            }
            if (data.height < 10) {
                data.height = 100;
            }
            if (data.width < 10) {
                data.width = 60;
            }
            // in case we draw outer left of the canvas
            if (data.x < dimensionRatio.centerShift_x) {
                data.width -= dimensionRatio.centerShift_x - data.x;
                data.x = dimensionRatio.centerShift_x;
            }
            // in case we draw outer right of the canvas
            if (data.x + data.width > dimensionRatio.centerShift_x + dimensionRatio.frameWidth) {
                const canvasLeft = dimensionRatio.centerShift_x + dimensionRatio.frameWidth;
                data.width = canvasLeft - data.x;
                // data.x = dimensionRatio.centerShift_x + dimensionRatio.frameWidth - data.width;
            } else if (data.y < dimensionRatio.centerShift_y) data.y = dimensionRatio.centerShift_y;
            else if (data.y + data.height > dimensionRatio.centerShift_y + dimensionRatio.frameHeight)
                data.y = dimensionRatio.centerShift_y + dimensionRatio.frameHeight - data.height;
            return data;
        }
    };

    const hanldeEndCreateShape = (v: { cn: DetectionClassName; data: Box | Point[] }) => {
        if (!dimensionRatio) return;
        let data = { ...v };

        // remove shape from the hook
        removeShape();
        // remove draw mode (mode to draw on canvas)
        setCanvasDrawModeState(undefined);
        // if any card is open we close all and register data
        const cardOpenId = cards.find((d) => d.open === true);
        // si oui
        if (cardOpenId) {
            // on ferme la carte qui etait ouverte
            closeCard(cardOpenId.id);
            // si on était en état de création d'une detection
            if (newTrackCreationPreviewState) {
                // on ajoute la détection
                addTrack();
                // on supprime l'etait de preview de creation (qu'on voyait dans la carte)
                removeNewTrackCreationPreview();
            }
            // on retire le track editor de la timeline
            setTrackEditorState(undefined);
        }
        // on ajoute une nouvelle carte de creation a ouvrir
        const id = `${Math.random()}`;
        addCard({
            id: id,
            closeOnNewEntry: true,
            element: <CreationCard id={id} />,
            open: true,
        });

        // reformat data to fit with canvas
        data.data = _prepareDimensions(data.data, dimensionRatio);

        // on ajoute les données qui seront lues par la carte de creation
        addNewTrackCreationPreview({ cn: data.cn, coordinates: data.data });
        // on ajoute les données qui seront lues par le track editor dans la timeline
        setTrackEditorState({
            min: mediaState.currentFrame,
            max: isMediaVideo(mediaState) ? mediaState.nbFrames - 1 : 1,
            onChangeMax: (value) => {
                updateNewTrackCreationPreview({ frameEnd: value });
            },
            onChangeMin: (value) => {
                updateNewTrackCreationPreview({ frameStart: value });
            },
            onReleaseMax: (value) => {
                updateRangeTrack('max', value);
            },
            onReleaseMin: (value) => {
                updateRangeTrack('min', value);
            },
        });
    };

    return (
        <>
            {isDrawBox(stageDrawState) && stageDrawState.shape === DetectionClassName.Head && (
                <HeadDetection
                    src={0.9}
                    active={true}
                    box={stageDrawState.box}
                    cn={stageDrawState.shape}
                    mode={'creation'}
                    disabled={false}
                    onEndCreation={hanldeEndCreateShape}
                    transformerVisible={true}
                />
            )}
            {isDrawBox(stageDrawState) && stageDrawState.shape === DetectionClassName.Plate && (
                <PlateDetection
                    src={0.9}
                    active={true}
                    box={stageDrawState.box}
                    cn={stageDrawState.shape}
                    mode={'creation'}
                    disabled={false}
                    ldm={[]}
                    onEndCreation={hanldeEndCreateShape}
                    transformerVisible={true}
                />
            )}
            {isDrawPoints(stageDrawState) && (
                <OtherDetection
                    cursorPosition={stageDrawState.cursorPosition}
                    src={0.9}
                    ldm={stageDrawState.points}
                    cn={DetectionClassName.Other}
                    active={true}
                    mode={'creation'}
                    disabled={false}
                    onEndCreation={hanldeEndCreateShape}
                    transformerVisible={true}
                />
            )}
        </>
    );
});
