import { TFunction } from "i18next";
import { Service } from "../../../context/servicesContext";
import { convertSize, isObjectEmpty } from "../../../services/tools";

//get list of services to display in dashboard select
export const formatSelectOptions = (
  services: Service[] | undefined,
  t: TFunction
) => {
  const apiServices = services?.filter(
    (s: any) => !s.searchKey.includes("PREMISE") && !s.searchKey.includes("SDK")
  );
  let options =
    apiServices
      ?.map((s: any) => ({
        label: t(`common.services-searchKey.${s.searchKey}`).replace(
          "/ API",
          ""
        ),
        value: s.searchKey,
      }))
      .sort((a: any, b: any) => a.label.toString().localeCompare(b.label)) ||
    [];
  options?.unshift({ label: t(`common.services-searchKey.all`), value: "all" });
  return {
    options,
    selected: options[0],
  };
};

/**
 * returns data for consumptions and api calls in cards
 * @param thisDayData
 * @param thisMonthData
 * @param clientServices
 * @param selected
 */
export const formatApiCallsAndConsumption = (
  thisDayData: any,
  thisMonthData: any,
  clientServices: any,
  selected: any
) => {
  const totalConsumption = _getConsumption(thisDayData?.totalCounts || []);
  const thisDayConsumption = _getConsumption(
    thisDayData?.totalCountsPerPeriod || []
  );
  const thisMonthConsumption = _getConsumption(
    thisMonthData?.totalCountsPerPeriod || []
  );

  const isActive = clientServices?.find(
    (cs: any) => cs.service.searchKey === selected
  );
  return {
    isActive: isActive && selected !== "all" ? true : false,
    totalConsumption: convertSize(totalConsumption.totalConsumption),
    totalConsumptionThisMonth: convertSize(
      thisMonthConsumption.totalConsumption
    ),
    totalConsumptionThisDay: convertSize(thisDayConsumption.totalConsumption),
    totalCall: totalConsumption.totalApiCall,
    totalCallThisMonth: thisMonthConsumption.totalApiCall,
    totalCallThisDay: thisDayConsumption.totalApiCall,
  };
};

/**
 * returns activated services with this format:
 * @param clientServices
 * @param allServices
 * @returns
 * [
 *  {
 *    serviceAnagram: string,
 *    sdk: boolean,
 *    api: boolean,
 *    onPremise: boolean,
 *  }
 * ]
 */
export const formatActiveServices = (clientServices: any, allServices: any) => {
  let allServicesPerName: any[] = [];
  allServicesPerName = allServices.reduce((acc: any, current: any) => {
    const searchKeyAnagram = current.searchKey.split("_");

    const serviceKey =
      searchKeyAnagram[1] === "BLIT" ? "ANM" : searchKeyAnagram[1]; // wrong naming in base so we have to force rename client side

    if (!acc[serviceKey]) {
      acc[serviceKey] = {};
    }

    let obj = isObjectEmpty(acc[serviceKey])
      ? {
          onPremise: false,
          sdk: false,
          api: false,
        }
      : { ...acc[serviceKey] };

    const csWithSameSearchKey =
      clientServices?.filter(
        (cs: any) => cs.service.searchKey === current.searchKey
      ) || [];

    if (csWithSameSearchKey?.length > 0) {
      const srvExtension = searchKeyAnagram[2];
      if (
        srvExtension === "SDK" &&
        csWithSameSearchKey[0].service.searchKey.includes("SDK")
      ) {
        obj.sdk = true;
      }
      if (
        srvExtension === "PREMISE" &&
        csWithSameSearchKey[0].service.searchKey.includes("PREMISE")
      ) {
        obj.onPremise = true;
      }
      if (
        srvExtension &&
        srvExtension !== "SDK" &&
        srvExtension !== "PREMISE" &&
        csWithSameSearchKey[0].service.searchKey === "SRV_VHC_PDS_DETECT" &&
        !csWithSameSearchKey[0].serviceEnd
      ) {
        obj.api = true;
      }
      if (
        !srvExtension &&
        csWithSameSearchKey[0].service.searchKey === current.searchKey &&
        !csWithSameSearchKey[0].serviceEnd
      ) {
        obj.api = true;
      }
    }

    acc[serviceKey] = {
      serviceAnagram: serviceKey,
      ...obj,
    };
    return acc;
  }, {});
  return Object.keys(allServicesPerName).map((srv: any) => ({
    ...allServicesPerName[srv],
  }));
};

/********************************
 *      PRIVATE FUNCTIONS
 *******************************/
const _getConsumption = (totalCounts: any) => {
  let totalApiCall = 0;
  let totalConsumption = 0;

  totalCounts.forEach((elem: any) => {
    if (elem.type !== null) {
      totalApiCall += parseInt(elem.totalCount);
      totalConsumption += parseInt(elem.size);
    }
  });

  return { totalApiCall, totalConsumption };
};
