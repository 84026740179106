import React, { useEffect } from "react";
import { Redirect, RouteComponentProps } from "react-router-dom";
import Cookies from "js-cookie";
import { addRefreshInterceptor } from "../api/authApi";
import { socketContext } from "../context/socketContext";
import { useAxios } from "../services/hook/requestsService";

const RootRedirect: React.FC<RouteComponentProps> = ({ history }) => {
  const auth = Cookies.getJSON("Auth_manager");
  const socketStore = React.useContext(socketContext);
  const [redirectPath, setRedirectPath] = React.useState<string | null>(null);
  const axiosActions = useAxios();

  useEffect(() => {
    const handleRedirection = async () => {
      if (auth && auth.token) {
        addRefreshInterceptor();

        if (!socketStore.isConnected) {
          socketStore.initSocket(auth.token);
        }

        try {
          const { data: client } = await axiosActions.getData(null, "client");

          if (client.licenses && client.licenses.length > 0) {
            setRedirectPath("/plugin/ressources");
          } else {
            setRedirectPath("/task-manager");
          }
        } catch (e) {
          console.error("Error fetching client data:", e);
          setRedirectPath("/task-manager");
        }
      } else {
        setRedirectPath("/login");
      }
    };

    handleRedirection();
  }, [auth, history, socketStore]);

  if (redirectPath) {
    return <Redirect to={redirectPath} />;
  }

  return null;
};

export default RootRedirect;
