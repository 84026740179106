import React, { createContext, useState } from 'react';

type AuthLayoutContext = {
    displayLaius: boolean;
    handleDisplayLaius: (display: boolean) => void;
};

const initialState = {
    displayLaius: true,
    handleDisplayLaius: () => 1,
};

const authLayoutContext = createContext({} as AuthLayoutContext);

const AuthLayoutProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [state, setState] = useState(initialState);
    const handleDisplayLaius = (display: boolean) => {
        setState({
            ...state,
            displayLaius: display,
        });
    };

    return <authLayoutContext.Provider value={{ ...state, handleDisplayLaius }}>{children}</authLayoutContext.Provider>;
};

export { AuthLayoutProvider, authLayoutContext };
