import { Form, Formik, FormikErrors, FormikTouched } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import CustomButton from "../../../components/button/button";
import Input from "../../../components/input/Input";
import { Client } from "../../../context/clientContext";
import "../../../static/scss/account.scss";
import {
	companyValidationSchema,
	individualValidationSchema,
	trialValidationSchema,
} from "../../../services/validations/accountValidation";
import { isObjectEmpty } from "../../../services/tools";

/**
 * We ensure this interface satisfies all 3 possible Client schemas (trial / individual / company)
 * then we treat each case with its specific ValidationSchema and cast values before calling submit callback
 */

export interface UpdateClient {
	contactLastName?: string | null;
	contactFirstName?: string | null;
	address?: string | null;
	city?: string | null;
	postalCode?: string | null;
	country?: string | null;
	phone?: string | null;
	companyName?: string | null;
	vatNumber?: string | null;
	companyId?: string | null;
	formType?: "trial" | "company" | "individual";
}

const TrialForm: React.FC<{
	initialValues: UpdateClient;
	onSubmit: (values: UpdateClient) => void;
	handleCancel: () => void;
	isErrorInput: (
		errors: FormikErrors<UpdateClient>,
		touched: FormikTouched<UpdateClient>,
		key: string
	) => boolean;
}> = ({ initialValues, onSubmit, handleCancel, isErrorInput }) => {
	const { t } = useTranslation();

	const onFormSubmit = (values: UpdateClient) => {
		// Convert empty string to null by casting nullable values
		const castedValues = trialValidationSchema.cast(values);

		// Filter only the changed fields
		const modifiedValues = Object.keys(castedValues).reduce((diff, key) => {
			if (
				castedValues[key] !== initialValues[key as keyof UpdateClient]
			) {
				diff[key as keyof UpdateClient] = castedValues[key];
			}
			return diff;
		}, {} as UpdateClient);

		// Call the onSubmit callback with only the modified fields
		onSubmit(modifiedValues);
	};

	return (
		<div className="mx-lg-5 edit-account-view">
			<Formik
				initialValues={initialValues}
				onSubmit={onFormSubmit}
				enableReinitialize={true}
				validationSchema={trialValidationSchema}
				validateOnMount={true}
			>
				{({
					handleSubmit,
					values,
					handleChange,
					errors,
					touched,
					handleBlur,
				}) => {
					return (
						<Form onSubmit={handleSubmit}>
							<div className="mb-4">
								<Input
									isInvalid={isErrorInput(
										errors,
										touched,
										"contactLastName"
									)}
									errorMessage={errors["contactLastName"]}
									withLabel={true}
									inputType="text"
									id="contactLastName"
									isRequired
									handleBlur={handleBlur}
									name="contactLastName"
									value={values?.contactLastName || ""}
									onChange={handleChange}
									placeholder={t(
										"account-management.last-name"
									)}
									labelTranslation={t(
										"account-management.last-name"
									)}
									customClassInput="pl-1"
									disable
								/>
							</div>
							<div className="mb-4">
								<Input
									isInvalid={isErrorInput(
										errors,
										touched,
										"contactFirstName"
									)}
									errorMessage={errors["contactFirstName"]}
									handleBlur={handleBlur}
									withLabel={true}
									inputType="text"
									isRequired
									id="contactFirstName"
									name="contactFirstName"
									value={values?.contactFirstName || ""}
									onChange={handleChange}
									placeholder={t(
										"account-management.first-name"
									)}
									labelTranslation={t(
										"account-management.first-name"
									)}
									customClassInput="pl-1"
									disable
								/>
							</div>
							<div className="mb-4">
								<Input
									isInvalid={isErrorInput(
										errors,
										touched,
										"address"
									)}
									errorMessage={errors["address"]}
									handleBlur={handleBlur}
									withLabel={true}
									inputType="text"
									isRequired={false}
									id="address"
									name="address"
									value={values?.address || ""}
									onChange={handleChange}
									placeholder={t(
										"account-management.address"
									)}
									labelTranslation={t(
										"account-management.address"
									)}
									customClassInput="pl-1"
								/>
							</div>
							<div className="mb-4">
								<Input
									isInvalid={isErrorInput(
										errors,
										touched,
										"city"
									)}
									errorMessage={errors["city"]}
									handleBlur={handleBlur}
									withLabel={true}
									inputType="text"
									id="city"
									isRequired={false}
									name="city"
									value={values?.city || ""}
									onChange={handleChange}
									placeholder={t("account-management.city")}
									labelTranslation={t(
										"account-management.city"
									)}
									customClassInput="pl-1"
								/>
							</div>
							<div className="mb-4">
								<Input
									isInvalid={isErrorInput(
										errors,
										touched,
										"postalCode"
									)}
									errorMessage={errors["postalCode"]}
									handleBlur={handleBlur}
									withLabel={true}
									inputType="text"
									id="postalCode"
									isRequired={false}
									name="postalCode"
									value={values?.postalCode || ""}
									onChange={handleChange}
									placeholder={t(
										"account-management.postal-code"
									)}
									labelTranslation={t(
										"account-management.postal-code"
									)}
									customClassInput="pl-1"
								/>
							</div>
							<div className="mb-4">
								<Input
									isInvalid={isErrorInput(
										errors,
										touched,
										"country"
									)}
									errorMessage={errors["country"]}
									handleBlur={handleBlur}
									withLabel={true}
									inputType="text"
									id="country"
									isRequired={false}
									name="country"
									value={values?.country || ""}
									onChange={handleChange}
									placeholder={t(
										"account-management.country"
									)}
									labelTranslation={t(
										"account-management.country"
									)}
									customClassInput="pl-1"
								/>
							</div>
							<div className="mb-4">
								<Input
									isInvalid={isErrorInput(
										errors,
										touched,
										"phone"
									)}
									errorMessage={errors["phone"]}
									handleBlur={handleBlur}
									withLabel={true}
									inputType="text"
									id="phone"
									name="phone"
									value={values?.phone || ""}
									onChange={handleChange}
									placeholder={t("account-management.phone")}
									labelTranslation={t(
										"account-management.phone"
									)}
									customClassInput="pl-1"
									maxLength={20}
								/>
							</div>
							<div>
								<div className="d-flex justify-content-between mx-3">
									<CustomButton
										buttonType="button"
										classNameType="mainWhite"
										buttonText={t("common.text.cancel")}
										onClick={handleCancel}
									/>
									<CustomButton
										disabled={!isObjectEmpty(errors)}
										buttonType="submit"
										classNameType="main"
										buttonText={t(
											"account-management.edit-button-confirm"
										)}
									/>
								</div>
							</div>
						</Form>
					);
				}}
			</Formik>
		</div>
	);
};

const IndividualForm: React.FC<{
	initialValues: UpdateClient;
	onSubmit: (values: UpdateClient) => void;
	handleCancel: () => void;
	isErrorInput: (
		errors: FormikErrors<UpdateClient>,
		touched: FormikTouched<UpdateClient>,
		key: string
	) => boolean;
}> = ({ initialValues, onSubmit, handleCancel, isErrorInput }) => {
	const { t } = useTranslation();

	const onFormSubmit = (values: UpdateClient) => {
		// Convert empty string to null by casting nullable values
		const castedValues = trialValidationSchema.cast(values);

		// Filter only the changed fields
		const modifiedValues = Object.keys(castedValues).reduce((diff, key) => {
			if (
				castedValues[key] !== initialValues[key as keyof UpdateClient]
			) {
				diff[key as keyof UpdateClient] = castedValues[key];
			}
			return diff;
		}, {} as UpdateClient);

		// Call the onSubmit callback with only the modified fields
		onSubmit(modifiedValues);
	};

	return (
		<div className="mx-lg-5 edit-account-view">
			<Formik
				initialValues={initialValues}
				onSubmit={onFormSubmit}
				enableReinitialize={true}
				validationSchema={individualValidationSchema}
				validateOnMount={true}
			>
				{({
					handleSubmit,
					values,
					handleChange,
					errors,
					touched,
					handleBlur,
				}) => {
					return (
						<Form onSubmit={handleSubmit}>
							<div className="mb-4">
								<Input
									isInvalid={isErrorInput(
										errors,
										touched,
										"contactLastName"
									)}
									errorMessage={errors["contactLastName"]}
									withLabel={true}
									inputType="text"
									id="contactLastName"
									isRequired
									handleBlur={handleBlur}
									name="contactLastName"
									value={values?.contactLastName || ""}
									onChange={handleChange}
									placeholder={t(
										"account-management.last-name"
									)}
									labelTranslation={t(
										"account-management.last-name"
									)}
									customClassInput="pl-1"
									disable
								/>
							</div>
							<div className="mb-4">
								<Input
									isInvalid={isErrorInput(
										errors,
										touched,
										"contactFirstName"
									)}
									errorMessage={errors["contactFirstName"]}
									handleBlur={handleBlur}
									withLabel={true}
									inputType="text"
									isRequired
									id="contactFirstName"
									name="contactFirstName"
									value={values?.contactFirstName || ""}
									onChange={handleChange}
									placeholder={t(
										"account-management.first-name"
									)}
									labelTranslation={t(
										"account-management.first-name"
									)}
									customClassInput="pl-1"
									disable
								/>
							</div>
							<div className="mb-4">
								<Input
									isInvalid={isErrorInput(
										errors,
										touched,
										"address"
									)}
									errorMessage={errors["address"]}
									handleBlur={handleBlur}
									withLabel={true}
									inputType="text"
									isRequired={true}
									id="address"
									name="address"
									value={values?.address || ""}
									onChange={handleChange}
									placeholder={t(
										"account-management.address"
									)}
									labelTranslation={t(
										"account-management.address"
									)}
									customClassInput="pl-1"
								/>
							</div>
							<div className="mb-4">
								<Input
									isInvalid={isErrorInput(
										errors,
										touched,
										"city"
									)}
									errorMessage={errors["city"]}
									handleBlur={handleBlur}
									withLabel={true}
									inputType="text"
									isRequired={true}
									id="city"
									name="city"
									value={values?.city || ""}
									onChange={handleChange}
									placeholder={t("account-management.city")}
									labelTranslation={t(
										"account-management.city"
									)}
									customClassInput="pl-1"
								/>
							</div>
							<div className="mb-4">
								<Input
									isInvalid={isErrorInput(
										errors,
										touched,
										"postalCode"
									)}
									errorMessage={errors["postalCode"]}
									handleBlur={handleBlur}
									withLabel={true}
									inputType="text"
									isRequired={true}
									id="postalCode"
									name="postalCode"
									value={values?.postalCode || ""}
									onChange={handleChange}
									placeholder={t(
										"account-management.postal-code"
									)}
									labelTranslation={t(
										"account-management.postal-code"
									)}
									customClassInput="pl-1"
								/>
							</div>
							<div className="mb-4">
								<Input
									isInvalid={isErrorInput(
										errors,
										touched,
										"country"
									)}
									errorMessage={errors["country"]}
									handleBlur={handleBlur}
									withLabel={true}
									inputType="text"
									isRequired={true}
									id="country"
									name="country"
									value={values?.country || ""}
									onChange={handleChange}
									placeholder={t(
										"account-management.country"
									)}
									labelTranslation={t(
										"account-management.country"
									)}
									customClassInput="pl-1"
								/>
							</div>
							<div className="mb-4">
								<Input
									isInvalid={isErrorInput(
										errors,
										touched,
										"phone"
									)}
									errorMessage={errors["phone"]}
									handleBlur={handleBlur}
									withLabel={true}
									inputType="text"
									id="phone"
									name="phone"
									value={values?.phone || ""}
									onChange={handleChange}
									placeholder={t("account-management.phone")}
									labelTranslation={t(
										"account-management.phone"
									)}
									customClassInput="pl-1"
									maxLength={20}
								/>
							</div>
							<div>
								<div className="d-flex justify-content-between mx-3">
									<CustomButton
										buttonType="button"
										classNameType="mainWhite"
										buttonText={t("common.text.cancel")}
										onClick={handleCancel}
									/>
									<CustomButton
										disabled={!isObjectEmpty(errors)}
										buttonType="submit"
										classNameType="main"
										buttonText={t(
											"account-management.edit-button-confirm"
										)}
									/>
								</div>
							</div>
						</Form>
					);
				}}
			</Formik>
		</div>
	);
};

const CompanyForm: React.FC<{
	initialValues: UpdateClient;
	onSubmit: (values: UpdateClient) => void;
	handleCancel: () => void;
	isErrorInput: (
		errors: FormikErrors<UpdateClient>,
		touched: FormikTouched<UpdateClient>,
		key: string
	) => boolean;
}> = ({ initialValues, onSubmit, handleCancel, isErrorInput }) => {
	const { t } = useTranslation();

	const onFormSubmit = (values: UpdateClient) => {
		// Convert empty string to null by casting nullable values
		const castedValues = trialValidationSchema.cast(values);

		// Filter only the changed fields
		const modifiedValues = Object.keys(castedValues).reduce((diff, key) => {
			if (
				castedValues[key] !== initialValues[key as keyof UpdateClient]
			) {
				diff[key as keyof UpdateClient] = castedValues[key];
			}
			return diff;
		}, {} as UpdateClient);

		// Call the onSubmit callback with only the modified fields
		onSubmit(modifiedValues);
	};

	return (
		<div className="mx-lg-5 edit-account-view">
			<Formik
				initialValues={initialValues}
				onSubmit={onFormSubmit}
				enableReinitialize={true}
				validationSchema={companyValidationSchema}
				validateOnMount={true}
			>
				{({
					handleSubmit,
					values,
					handleChange,
					errors,
					touched,
					handleBlur,
				}) => {
					return (
						<Form onSubmit={handleSubmit}>
							<div className="mb-4">
								<Input
									isInvalid={isErrorInput(
										errors,
										touched,
										"companyName"
									)}
									errorMessage={errors["companyName"]}
									withLabel={true}
									inputType="text"
									id="companyName"
									isRequired
									handleBlur={handleBlur}
									name="companyName"
									value={values?.companyName || ""}
									onChange={handleChange}
									placeholder={t(
										"account-management.company-name"
									)}
									labelTranslation={t(
										"account-management.company-name"
									)}
									customClassInput="pl-1"
									disable
								/>
							</div>
							<div className="mb-4">
								<Input
									isInvalid={isErrorInput(
										errors,
										touched,
										"contactLastName"
									)}
									errorMessage={errors["contactLastName"]}
									withLabel={true}
									inputType="text"
									id="contactLastName"
									isRequired
									handleBlur={handleBlur}
									name="contactLastName"
									value={values?.contactLastName || ""}
									onChange={handleChange}
									placeholder={t(
										"account-management.last-name"
									)}
									labelTranslation={t(
										"account-management.last-name"
									)}
									customClassInput="pl-1"
								/>
							</div>
							<div className="mb-4">
								<Input
									isInvalid={isErrorInput(
										errors,
										touched,
										"contactFirstName"
									)}
									errorMessage={errors["contactFirstName"]}
									handleBlur={handleBlur}
									withLabel={true}
									inputType="text"
									isRequired
									id="contactFirstName"
									name="contactFirstName"
									value={values?.contactFirstName || ""}
									onChange={handleChange}
									placeholder={t(
										"account-management.first-name"
									)}
									labelTranslation={t(
										"account-management.first-name"
									)}
									customClassInput="pl-1"
								/>
							</div>
							<div className="mb-4">
								<Input
									isInvalid={isErrorInput(
										errors,
										touched,
										"address"
									)}
									errorMessage={errors["address"]}
									handleBlur={handleBlur}
									withLabel={true}
									inputType="text"
									isRequired={true}
									id="address"
									name="address"
									value={values?.address || ""}
									onChange={handleChange}
									placeholder={t(
										"account-management.address"
									)}
									labelTranslation={t(
										"account-management.address"
									)}
									customClassInput="pl-1"
								/>
							</div>
							<div className="mb-4">
								<Input
									isInvalid={isErrorInput(
										errors,
										touched,
										"city"
									)}
									errorMessage={errors["city"]}
									handleBlur={handleBlur}
									withLabel={true}
									inputType="text"
									isRequired={true}
									id="city"
									name="city"
									value={values?.city || ""}
									onChange={handleChange}
									placeholder={t("account-management.city")}
									labelTranslation={t(
										"account-management.city"
									)}
									customClassInput="pl-1"
								/>
							</div>
							<div className="mb-4">
								<Input
									isInvalid={isErrorInput(
										errors,
										touched,
										"postalCode"
									)}
									errorMessage={errors["postalCode"]}
									handleBlur={handleBlur}
									withLabel={true}
									inputType="text"
									isRequired={true}
									id="postalCode"
									name="postalCode"
									value={values?.postalCode || ""}
									onChange={handleChange}
									placeholder={t(
										"account-management.postal-code"
									)}
									labelTranslation={t(
										"account-management.postal-code"
									)}
									customClassInput="pl-1"
								/>
							</div>
							<div className="mb-4">
								<Input
									isInvalid={isErrorInput(
										errors,
										touched,
										"country"
									)}
									errorMessage={errors["country"]}
									handleBlur={handleBlur}
									withLabel={true}
									inputType="text"
									isRequired={true}
									id="country"
									name="country"
									value={values?.country || ""}
									onChange={handleChange}
									placeholder={t(
										"account-management.country"
									)}
									labelTranslation={t(
										"account-management.country"
									)}
									customClassInput="pl-1"
								/>
							</div>
							<div className="mb-4">
								<Input
									isInvalid={isErrorInput(
										errors,
										touched,
										"phone"
									)}
									errorMessage={errors["phone"]}
									handleBlur={handleBlur}
									withLabel={true}
									inputType="text"
									id="phone"
									name="phone"
									value={values?.phone || ""}
									onChange={handleChange}
									placeholder={t("account-management.phone")}
									labelTranslation={t(
										"account-management.phone"
									)}
									customClassInput="pl-1"
									maxLength={20}
								/>
							</div>
							<div className="mb-4">
								<Input
									isInvalid={isErrorInput(
										errors,
										touched,
										"companyId"
									)}
									errorMessage={errors["companyId"]}
									handleBlur={handleBlur}
									withLabel={true}
									inputType="text"
									id="companyId"
									name="companyId"
									value={values?.companyId || ""}
									onChange={handleChange}
									placeholder={t(
										"account-management.company-id-placeholder"
									)}
									labelTranslation={t(
										"account-management.company-id"
									)}
									customClassInput="pl-1"
								/>
							</div>
							<div className="mb-4">
								<Input
									isInvalid={isErrorInput(
										errors,
										touched,
										"vatNumber"
									)}
									errorMessage={errors["vatNumber"]}
									handleBlur={handleBlur}
									withLabel={true}
									inputType="text"
									id="vatNumber"
									name="vatNumber"
									value={values?.vatNumber || ""}
									onChange={handleChange}
									placeholder={t(
										"account-management.company-vat-placeholder"
									)}
									labelTranslation={t(
										"account-management.company-vat"
									)}
									customClassInput="pl-1"
									maxLength={15}
								/>
							</div>
							<div>
								<div className="d-flex justify-content-between mx-3">
									<CustomButton
										buttonType="button"
										classNameType="mainWhite"
										buttonText={t("common.text.cancel")}
										onClick={handleCancel}
									/>
									<CustomButton
										disabled={!isObjectEmpty(errors)}
										buttonType="submit"
										classNameType="main"
										buttonText={t(
											"account-management.edit-button-confirm"
										)}
									/>
								</div>
							</div>
						</Form>
					);
				}}
			</Formik>
		</div>
	);
};

const EditAccount: React.FC<{
	client: Client;
	onSubmit: (values: UpdateClient) => void;
	handleCancel: () => void;
}> = ({ client, onSubmit, handleCancel }) => {
	const formType = client.isTrial
		? "trial"
		: client.type === "COMPANY"
		? "company"
		: "individual";

	const isErrorInput = (
		errors: FormikErrors<UpdateClient>,
		touched: FormikTouched<UpdateClient>,
		key: string
	) => {
		return errors.hasOwnProperty(key) && touched.hasOwnProperty(key);
	};

	const initialValues: UpdateClient = {
		companyName: client.companyName,
		contactLastName: client.lastName,
		contactFirstName: client.firstName,
		address: client.address,
		city: client.city,
		postalCode: client.postalCode,
		country: client.country,
		phone: client.phone,
		vatNumber: client.vatNumber,
		companyId: client.companyId,
		formType,
	};

	return (
		<>
			{
				{
					trial: (
						<TrialForm
							initialValues={initialValues}
							onSubmit={onSubmit}
							handleCancel={handleCancel}
							isErrorInput={isErrorInput}
						/>
					),
					individual: (
						<IndividualForm
							initialValues={initialValues}
							onSubmit={onSubmit}
							handleCancel={handleCancel}
							isErrorInput={isErrorInput}
						/>
					),
					company: (
						<CompanyForm
							initialValues={initialValues}
							onSubmit={onSubmit}
							handleCancel={handleCancel}
							isErrorInput={isErrorInput}
						/>
					),
				}[formType]
			}
		</>
	);
};

export default EditAccount;
