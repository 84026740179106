import moment from 'moment';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { socketContext } from '../../../context/socketContext';
import { convertSize, randomKeyGen } from '../../../services/tools';
import { DownloadButton } from '../../account/utils/downloadButton';
import '../../../static/scss/tooltip.scss';
import '../../../static/scss/edit-dropdown.scss';
import { TFunction } from 'i18next';
import {
    FileType,
    FormattedHistoryCall,
    HistoryCallServiceMode,
    ServiceSearchKey,
    TaskStatusEnum,
} from '../../../types/historyCall';
import { ReactComponent as IconEdit } from '../../../static/icons/icon_edit.svg';
import { useHistory } from 'react-router-dom';
import { ReactComponent as DownloadSvg } from '../../../static/icons/icon_download-black.svg';
import Tooltip from '../../../components/tooltip/Tooltip';
import { useRecoilValue } from 'recoil';
import { TASKS_UNAVAILABLE_TYPES, serviceUsableState } from '../../../recoil/services.atom';
import { useTranslation } from 'react-i18next';
import { veryDirtyAuthorizedVideoFormats } from '../pages/anonymization/components/Preview';

export enum BadgeStatus {
    NONE = 'none',
    PROCESSING = 'processing',
    SUCCESS = 'success',
    FAILED = 'failed',
    EDITABLE = 'editable',
}

const TaskStatus: React.FC<{ taskId: string; taskStatus: string; mode?: HistoryCallServiceMode }> = ({
    taskId,
    taskStatus,
    mode,
}) => {
    const socketStore = useContext(socketContext);

    const getBadgeStatus = () => {
        switch (taskStatus) {
            case 'Succeeded': {
                return BadgeStatus.SUCCESS;
            }
            case 'Deleted': {
                return BadgeStatus.NONE;
            }
            case 'None': {
                return BadgeStatus.NONE;
            }
            case 'Sent': {
                return BadgeStatus.PROCESSING;
            }
            case 'Started': {
                return BadgeStatus.PROCESSING;
            }
            case 'Uploading': {
                return BadgeStatus.PROCESSING;
            }
            case 'Failed': {
                return BadgeStatus.FAILED;
            }
            case 'Upload failed': {
                return BadgeStatus.FAILED;
            }
            default:
                return BadgeStatus.NONE;
        }
    };

    const getBadgeStatusText = () => {
        if (socketStore.task[taskId] && socketStore.task[taskId] === 'Upload failed') {
            return 'Failed';
        }
        if (mode && mode === HistoryCallServiceMode.DETECTION && taskStatus === TaskStatusEnum.SUCCEEDED) {
            return 'Editable';
        }
        if (socketStore.task[taskId] && taskId !== undefined && socketStore.task[taskId] === 'Started') {
            return 'Processing';
        } else {
            if (taskStatus === 'Started' || taskStatus === 'Uploading') {
                return 'Processing';
            }
            if (taskStatus === 'Upload failed') return 'Failed';
            return taskStatus;
        }
    };

    return (
        <div className="d-flex align-items-center">
            <div className={`task-status-badge ${getBadgeStatus()} me-lg-2`}></div>
            <span>{getBadgeStatusText()}</span>
        </div>
    );
};

const EditAction: React.FC<{
    mode: HistoryCallServiceMode;
    taskStatus: TaskStatusEnum | 'Deleted';
    jobId: string;
    fileName: string;
}> = ({ mode, taskStatus, jobId, fileName }) => {
    const { push } = useHistory();
    const { t } = useTranslation();
    const [iconState, setIconState] = useState({ color: '#000000' });
    const canUseTask = useRecoilValue(serviceUsableState);

    const fileExtension = fileName.toLowerCase().split('.').at(-1);
    const isDirtyFormat = !!(fileExtension && veryDirtyAuthorizedVideoFormats.includes(fileExtension));
    const tooltipContent = useMemo(() => {
        if (isDirtyFormat) {
            return t('error.fileFormatTooMuchDirtyForStudio-message');
        }

        switch (canUseTask?.reason) {
            case TASKS_UNAVAILABLE_TYPES.INACTIVE_SERVICES:
                return t('error.servicesDeactivated-message');
            case TASKS_UNAVAILABLE_TYPES.CARD_EXPIRED:
                return t('error.cardExpired-message');
            default:
                return '';
        }
    }, [canUseTask, fileName]);

    const isEditable = mode === HistoryCallServiceMode.RENDER ? false : isDirtyFormat ? false : true;

    const handleIconColor = (mouseAction: 'enter' | 'leave') => {
        if (mouseAction === 'enter') {
            setIconState({ color: '#F50A6B' });
        } else {
            setIconState({ color: '#000000' });
        }
    };
    if (taskStatus !== TaskStatusEnum.SUCCEEDED) return null;
    return (
        <Tooltip content={tooltipContent} disabled={canUseTask.status && !isDirtyFormat} maxWidth={400}>
            <div className="table-action">
                {isEditable ? (
                    <IconEdit
                        onClick={() => push(`task-manager/anonymization/edit/${jobId}`)}
                        width={20}
                        height={20}
                        onMouseEnter={() => handleIconColor('enter')}
                        onMouseLeave={() => handleIconColor('leave')}
                        fill={iconState.color}
                        style={{
                            cursor: 'pointer',
                        }}
                    />
                ) : (
                    <IconEdit width={20} height={20} fill={'#e1e3ea'} />
                )}
            </div>
        </Tooltip>
    );
};

const DownloadAction: React.FC<{ element: FormattedHistoryCall }> = ({ element }) => {
    const [isOpen, setIsOpen] = useState(false);
    const divRef = useRef<HTMLDivElement>(null);
    const [iconState, setIconState] = useState({ color: '#000000' });
    const { t } = useTranslation();
    const anchorRef = useRef<HTMLAnchorElement>(null!);
    const canUseTask = useRecoilValue(serviceUsableState);
    const tooltipContent = useMemo(() => {
        switch (canUseTask?.reason) {
            case TASKS_UNAVAILABLE_TYPES.INACTIVE_SERVICES:
                return t('error.servicesDeactivated-message');
            case TASKS_UNAVAILABLE_TYPES.CARD_EXPIRED:
                return t('error.cardExpired-message');
            default:
                return '';
        }
    }, [canUseTask]);

    const handleIconColor = (mouseAction: 'enter' | 'leave') => {
        if (mouseAction === 'enter') {
            setIconState({ color: '#F50A6B' });
        } else {
            setIconState({ color: '#000000' });
        }
    };
    useEffect(() => {
        const handleClickOutside = (e: MouseEvent) => {
            if (divRef.current && !divRef.current.contains(e.target as Node)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleClick = (e: React.MouseEvent) => {
        e.stopPropagation();
        setIsOpen((prev) => !prev);
    };

    // No download button if task is not succeeded OR not anonymization / watermark OR has been processed in "detection" mode
    if (!isTaskSucceeded(element.status) || !['SRV_WATERMARK', 'SRV_ANM'].includes(element.service_search_key))
        return null;
    // if (!isTaskSucceeded(element.status) || !['SRV_WATERMARK', 'SRV_ANM'].includes(element.service_search_key) || element.mode === HistoryCallServiceMode.DETECTION) return null;
    const formattedFileName = formatFileName(element);
    const buttonFileName = () => {
        if (element.file_type === FileType.Bulk) return 'Blurred files';
        return element.service_search_key === ServiceSearchKey.ANONYMIZATION ? 'Blurred file' : 'Watermarked file';
    };

    return (
        <Tooltip content={tooltipContent} disabled={canUseTask.status}>
            <div
                onClick={handleClick}
                role="button"
                className="d-flex align-items-center flex-column h-100 gap-1 table-action"
            >
                <DownloadSvg
                    width={15}
                    height={15}
                    onMouseEnter={() => handleIconColor('enter')}
                    onMouseLeave={() => handleIconColor('leave')}
                    fill={iconState.color}
                    style={{
                        cursor: 'pointer',
                    }}
                />

                <div id={randomKeyGen()} ref={divRef} className={`${isOpen ? 'dropdow-menu' : 'd-none'}`}>
                    <div className="d-flex flex-column">
                        <div className="dropdown-menu__item" onClick={() => anchorRef.current.click()}>
                            {[ServiceSearchKey.ANONYMIZATION, ServiceSearchKey.WATERMARK].includes(
                                element.service_search_key
                            ) && (
                                <DownloadButton
                                    aRef={anchorRef}
                                    params={{
                                        searchKey: element.service_search_key,
                                        job_id: element.job_id,
                                        fileType: element.file_type,
                                        fileName: formattedFileName,
                                    }}
                                    url={`${process.env.REACT_APP_API_URL}/task-management/${element.fileurl}`}
                                    filename={buttonFileName()}
                                    buttonType="image-link"
                                />
                            )}
                        </div>
                        {element.parameters.output_detections_url && (
                            <div className="dropdown-menu__item">
                                <DownloadButton
                                    params={{
                                        searchKey: element.service_search_key,
                                        job_id: element.job_id,
                                        fileType: element.file_type,
                                        is_json: true,
                                        fileName: `${element.filename.slice(
                                            0,
                                            element.filename.lastIndexOf('.')
                                        )}.json`,
                                    }}
                                    url={`${process.env.REACT_APP_API_URL}/task-management/${element.fileurl}`}
                                    filename={`Coordinates position file`}
                                    buttonType="image-link"
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </Tooltip>
    );
};

const FileName: React.FC<{ element: FormattedHistoryCall }> = ({ element }) => {
    const formattedFileName = formatFileName(element);
    const { push } = useHistory();
    const { t } = useTranslation();
    const canUseTask = useRecoilValue(serviceUsableState);
    const tooltipContent = useMemo(() => {
        switch (canUseTask?.reason) {
            case TASKS_UNAVAILABLE_TYPES.INACTIVE_SERVICES:
                return t('error.servicesDeactivated-message');
            case TASKS_UNAVAILABLE_TYPES.CARD_EXPIRED:
                return t('error.cardExpired-message');
            default:
                return formattedFileName;
        }
    }, [canUseTask]);

    if (element.status !== TaskStatusEnum.SUCCEEDED)
        return (
            <div className="text-truncate">
                <span className="file-name">{formattedFileName}</span>
            </div>
        );
    return (
        <Tooltip content={tooltipContent} disabled={!canUseTask.status} maxWidth={1000}>
            <div className="text-truncate table-action">
                {
                    {
                        detection: (
                            <span
                                className="file-name text-truncate"
                                onClick={() => push(`task-manager/anonymization/edit/${element.job_id}`)}
                            >
                                {formattedFileName}
                            </span>
                        ),
                        render: (
                            <DownloadButton
                                params={{
                                    searchKey: element.service_search_key,
                                    job_id: element.job_id,
                                    fileType: element.file_type,
                                    fileName: element.filename,
                                }}
                                url={`${process.env.REACT_APP_API_URL}/task-management/${element.fileurl}`}
                                filename={formattedFileName}
                                buttonType="link"
                            />
                        ),
                        auto: (
                            <DownloadButton
                                params={{
                                    searchKey: element.service_search_key,
                                    job_id: element.job_id,
                                    fileType: element.file_type,
                                    fileName: element.fileurl,
                                }}
                                url={`${process.env.REACT_APP_API_URL}/task-management/${element.fileurl}`}
                                filename={formattedFileName}
                                buttonType="link"
                            />
                        ),
                    }[element.mode]
                }
            </div>
        </Tooltip>
    );
};

const formatFileName = (element: FormattedHistoryCall) => {
    if (element.status === 'Succeeded' && element.filename) {
        return `${element.filename.slice(0, element.filename.lastIndexOf('.'))}_processed_${moment(element.date)
            .utc()
            .format('YYYYMMDDhhmmss')}z${element.filename.slice(
            element.filename.lastIndexOf('.'),
            element.filename.length
        )}`;
    } else {
        return element.filename;
    }
};

const isTaskSucceeded = (status: string) => {
    return status === 'Succeeded';
};

export const formatTable = (data: FormattedHistoryCall[] | [], t: TFunction) => {
    const head = [
        {
            title: 'Date',
            type: 'button-sortable',
            name: 'date',
            align: 'left',
            style: {
                width: '15%',
            },
        },
        {
            title: 'Service name',
            type: 'button-sortable',
            align: 'center',
            name: 'service_name',
            style: {
                width: '15%',
            },
        },
        {
            title: 'File type',
            type: 'button-sortable',
            name: 'type',
            align: 'left',
            data: true,
            style: {
                width: '10%',
            },
        },
        {
            title: 'File name',
            type: 'text',
            name: 'filename',
            align: 'left',
            data: true,
            style: {
                width: '30%',
            },
        },
        {
            title: 'Size',
            type: 'text',
            align: 'left',
            name: 'size',
            data: true,
            style: {
                width: '8%',
            },
        },
        {
            title: 'Status',
            type: 'text',
            align: 'left',
            name: 'task_status',
            style: {
                width: '10%',
            },
        },
        {
            title: 'Edit',
            type: 'text',
            align: 'center',
            name: 'edit',
            data: true,
        },
        {
            title: 'Download',
            type: 'text',
            name: 'download',
            data: true,
        },
    ];

    const body =
        data.length > 0
            ? data.map((element: FormattedHistoryCall) => {
                  const job_id = element.job_id;
                  const linkTo = { pathname: `/task-manager/${job_id}/detail`, state: { element } };
                  // mise à jour du status à "Supprimé si le fichier n'est plus disponible (après une semaine)
                  if (moment(element?.date).add(7, 'days') < moment() && element?.status === 'Succeeded') {
                      element.status = 'Deleted';
                  }

                  // data = true => hide on mobile
                  return {
                      date: {
                          value: moment(element.date).format('DD-MM-YYYY_HH:mm:ss'),
                          type: 'link',
                          align: 'left',
                          name: 'date',
                          linkTo,
                      },
                      serviceName: {
                          value: t(`common.services-searchKey.${element.service_search_key}`),
                          type: 'link',
                          service: t(`common.services-searchKey.${element.service_search_key}`),
                          align: 'left',
                          linkTo,
                      },
                      fileType: {
                          value: element.file_type,
                          type: 'text',
                          name: 'fileType',
                          align: 'left',
                          data: true,
                      },
                      filename: {
                          value: <FileName element={element} />,
                          type: 'text',
                          align: 'left',
                      },
                      size: {
                          value: convertSize(Number(element.size)),
                          type: 'text',
                          align: 'left',
                          name: 'size',
                          data: true,
                      },
                      taskStatus: {
                          value: <TaskStatus taskId={job_id} taskStatus={element.status} mode={element.mode} />,
                          type: 'link',
                          name: 'taskStatus',
                          align: 'left',
                          linkTo,
                          taskLink: true,
                      },
                      edit: {
                          value:
                              element.service_search_key === ServiceSearchKey.ANONYMIZATION &&
                              element.file_type !== FileType.Bulk ? (
                                  <EditAction
                                      fileName={element.filename}
                                      mode={element.mode}
                                      taskStatus={element.status}
                                      jobId={element.job_id}
                                  />
                              ) : null,
                          type: 'base',
                          align: 'center',
                          data: true,
                      },
                      download: {
                          value: <DownloadAction element={element} />,
                          type: 'base',
                          align: 'center',
                          data: true,
                      },
                  };
              })
            : [];

    return {
        head,
        body,
    };
};
