import { useEffect, useRef, useState } from 'react';
import { useRefsContext } from '../../../contexts/RefsContexts';
import { MediaVideo } from '../../../hooks/useMedia';
import { ProgressBar } from './ProgressBar';
import { Ruler } from './Ruler';
import { TrackEditor } from './TrackEditor';

const useGetRulerDimensions = () => {
    const [dimensions, setDimensions] = useState({ height: 0, width: 0 });

    useEffect(() => {
        const element = document.getElementById('ruler');
        if (!element) return;
        const onResize = () => {
            setDimensions({ height: element.clientHeight, width: element.clientWidth });
        };
        onResize();
        window.addEventListener('resize', onResize);
        return () => {
            window.removeEventListener('resize', onResize);
        };
    }, []);
    return dimensions;
};

export const Timeline: React.FC<{ media: MediaVideo }> = ({ media }) => {
    const progressBarRef = useRef<HTMLInputElement>(null!);

    return (
        <div className="media-controls__timeline">
            <Ruler />
            <div className="media-controls__timeline__sliders">
                <ProgressBar ref={progressBarRef} media={media} />
                <TrackEditor />
            </div>
        </div>
    );
};
