import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { blurPreviewAtom } from '../../../recoil/blurPreview.atom';
import { canvasDrawModeAtom } from '../../../recoil/canvasDrawMode.atom';
import { DetectionClassName } from '../../../recoil/framesDetectionsCoordinates.atom';
import '../../../styles/toolbox.scss';
import { useToolboxDisabled } from './hooks/useToolboxDisabled';

type TooltipProps = {
    open: boolean;
    text: string;
    data?: 'head' | 'plate' | 'other';
};

const Tooltip: React.FC<TooltipProps> = (props) => {
    const { text, open } = props;
    // if (!open) return null;
    return (
        <div data-icon={props.data} className="button__tooltip">
            <span>{text}</span>
        </div>
    );
};

export const Toolbox = () => {
    const [canvasDrawModeState, setCanvasDrawModeState] = useRecoilState(canvasDrawModeAtom);
    const [blurPreviewState, setBlurPreviewState] = useRecoilState(blurPreviewAtom);
    const [openTooltipState, setOpenTooltipState] = useState<(DetectionClassName | 'blur-preview') | undefined>(
        undefined
    );
    const disabled = useToolboxDisabled();

    const handleClickTool = (e: React.MouseEvent<HTMLButtonElement>, cn: DetectionClassName) => {
        e.stopPropagation();
        document.body.style.cursor = 'crosshair';
        if (canvasDrawModeState && canvasDrawModeState === cn) {
            setCanvasDrawModeState(undefined);
        } else {
            setCanvasDrawModeState(cn);
        }
    };

    const handleHoverButton = (d: DetectionClassName | 'blur-preview') => {
        setOpenTooltipState(d);
    };

    const handleLeaveButton = () => {
        setOpenTooltipState(undefined);
    };

    return (
        <div className="toolbox">
            <div className="toolbox__add-detections-container">
                <button
                    disabled={disabled}
                    onClick={(e) => handleClickTool(e, DetectionClassName.Head)}
                    className={canvasDrawModeState === 'head' ? 'active' : ''}
                    data-icon="head"
                    style={{
                        cursor: canvasDrawModeState
                            ? canvasDrawModeState !== DetectionClassName.Head
                                ? 'pointer'
                                : 'crosshair'
                            : 'pointer',
                    }}
                    onMouseEnter={() => handleHoverButton(DetectionClassName.Head)}
                    onMouseLeave={handleLeaveButton}
                >
                    {openTooltipState === DetectionClassName.Head && (
                        <Tooltip
                            data="head"
                            open={true}
                            text={'Create a face detection. The blur shape will be oval'}
                        />
                    )}
                </button>
                <button
                    disabled={disabled}
                    onClick={(e) => handleClickTool(e, DetectionClassName.Plate)}
                    className={canvasDrawModeState === 'plate' ? 'active' : ''}
                    data-icon="plate"
                    style={{
                        cursor: canvasDrawModeState
                            ? canvasDrawModeState !== DetectionClassName.Plate
                                ? 'pointer'
                                : 'crosshair'
                            : 'pointer',
                    }}
                    onMouseEnter={() => handleHoverButton(DetectionClassName.Plate)}
                    onMouseLeave={handleLeaveButton}
                >
                    {openTooltipState === DetectionClassName.Plate && (
                        <Tooltip
                            data="plate"
                            open={true}
                            text={'Create a plate detection. The blur shape will be rectangular'}
                        />
                    )}
                </button>
                <button
                    disabled={disabled}
                    onClick={(e) => handleClickTool(e, DetectionClassName.Other)}
                    className={canvasDrawModeState === 'other' ? 'active' : ''}
                    data-icon="other"
                    style={{
                        cursor: canvasDrawModeState
                            ? canvasDrawModeState !== DetectionClassName.Other
                                ? 'pointer'
                                : 'crosshair'
                            : 'pointer',
                    }}
                    onMouseEnter={() => handleHoverButton(DetectionClassName.Other)}
                    onMouseLeave={handleLeaveButton}
                >
                    {openTooltipState === DetectionClassName.Other && (
                        <Tooltip
                            data="other"
                            open={true}
                            text={'Create a custom detection. The blur shape will cover the drawn area'}
                        />
                    )}
                </button>
            </div>
            {/* <button
                disabled={disabled}
                onMouseEnter={() => handleHoverButton('blur-preview')}
                onMouseLeave={handleLeaveButton}
                onClick={() => setBlurPreviewState((prev) => !prev)}
                className={blurPreviewState ? 'active' : ''}
                data-icon="blur"
            >
                <div className={`deactive-blur ${blurPreviewState ? 'active' : ''}`}></div>
                {openTooltipState === 'blur-preview' && (
                    <Tooltip
                        open={true}
                        text={'Select blur preview. For browser performance reasons, the blur file preview will be less accurate than the final version'}
                    />
                )}
            </button> */}
        </div>
    );
};
