import React, { ButtonHTMLAttributes, CSSProperties, DetailedHTMLProps } from 'react';
import './button.scss';

interface IButton {
    buttonType?: 'submit' | 'button';
    buttonText?: string | React.ReactNode;
    onClick?: (e: any) => void;
    classNameType?:
        | 'main'
        | 'mainBordered'
        | 'disconnect'
        | 'mainWhite'
        | 'arrowButton'
        | 'tabButton'
        | 'account'
        | 'mainInverted'
        | 'squarred'
        | 'close'
        | 'menu'
        | 'none'
        | 'desktopTask';
    customClass?: string;
    disabled?: boolean;
    withLoader?: boolean;
    style?: CSSProperties;
    role?: string;
    ariaSelected?: boolean; // WAI-ARIA => https://developer.mozilla.org/en-US/docs/Learn/Accessibility/WAI-ARIA_basics
}

const CustomButton: React.FC<
    DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> & IButton
> = ({
    buttonType = 'submit',
    classNameType = 'main',
    buttonText = '',
    onClick,
    disabled = false,
    customClass = '',
    withLoader = false,
    style,
    role,
    ariaSelected,
    onMouseEnter,
    onMouseLeave,
    onPointerEnter,
    onPointerLeave,
    id,
}) => {
    const buttonTypeClass = {
        main: 'button btn-main',
        mainBordered: 'button btn-main bordered',
        mainInverted: 'button btn-main-inverted',
        mainWhite: 'button btn-white-main',
        disconnect: 'button btn-disconnect',
        arrowButton: 'button collapse-btn',
        tabButton: 'button tab-button',
        account: 'button btn-account',
        squarred: 'button btn-squarred',
        close: 'button btn-close',
        menu: 'button btn-menu',
        desktopTask: 'button btn-desktop-task',
        none: '',
    };

    return (
        <button
            id={id}
            disabled={disabled}
            className={`${buttonTypeClass[classNameType]} ${customClass} ${withLoader ? 'with-loader' : ''}`}
            onClick={onClick}
            type={buttonType}
            style={style}
            role={role}
            aria-selected={ariaSelected}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onPointerEnter={onPointerEnter}
            onPointerLeave={onPointerLeave}
        >
            {buttonText}
        </button>
    );
};

export default CustomButton;
