import { atom } from 'recoil';

export type Card = {
    open: boolean;
    closeOnNewEntry: boolean;
    element: React.ReactNode;
    id: string;
    onClose?: (data?: any) => void;
};

export const cardsAtom = atom<Card[]>({
    key: 'cardsAtom',
    default: [],
});
