import React, { createContext, useState } from 'react';
import { useAxios } from '../services/hook/requestsService';
import { Service } from './servicesContext';

export type ClientService = {
    OPLicenceType: string;
    OPsetup: string;
    end: string | null;
    start: string;
    licenceFilePath: string | null;
    machineId: string | null;
    sdkType: string;
    services: string[];
    service: Service;
    support: string;
    serviceEnd: Date;
    serviceStart: Date;
};

export type ClientServicesState = {
    data: ClientService[] | null;
    error: any;
    loading: boolean;
    getData: () => Promise<ClientService[]>;
};

export const initialState: ClientServicesState = {
    error: false,
    loading: true,
    data: null,
    getData: async () => [] as ClientService[],
};

const clientServicesContext = createContext<ClientServicesState>(initialState);

const ClientServicesProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [state, setState] = useState(initialState);
    const axiosActions = useAxios();

    const getData = async () => {
        try {
            const { data } = await axiosActions.getData(null, 'client-services');
            setState({
                ...state,
                data,
                error: false,
            });

            return data;
        } catch (e) {
            setState({
                ...state,
                data: null,
                error: true,
            });
        }
    };

    return (
        <clientServicesContext.Provider
            value={{
                ...state,
                loading: axiosActions.loading,
                getData,
            }}
        >
            {children}
        </clientServicesContext.Provider>
    );
};

export { clientServicesContext, ClientServicesProvider };
