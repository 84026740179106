import { _getArrayFromBox, _getBoxFromPointArray } from '../../../../../hooks/helper';
import { useCurrentFramePreviewDetection } from '../../../../../hooks/useCurrentFramePreview';
import { useMedia } from '../../../../../hooks/useMedia';
import { FrameRange, TrackPreview, useTrackPreview } from '../../../../../hooks/useTrackPreview';
import { Box, DetectionClassName, isBox, Point } from '../../../../../recoil/framesDetectionsCoordinates.atom';
import { HeadDetection } from '../shapes/HeadDetection';
import { OtherDetection } from '../shapes/OtherDetection';
import { PlateDetection } from '../shapes/PlateDetection';
import { useShapeDisabled } from '../../hooks/useShapeDisabled';
import { useCanvasMedia } from '../../../../../hooks/useCanvasMedia';
import { rectStyle } from '../../types';

type GenericFrameRange<T> = FrameRange & T;

// Celui qui touche a ça je lui fume sa ******
export function insertFrameInRange<T>(
    rangeArray: GenericFrameRange<T>[],
    toInsert: { box: Box; ldm: Point[] } & any, // par contre si quelqu'un est chaud pour bien faire le generice lezgo
    currentFrame: number
): GenericFrameRange<T>[] {
    const newRangeArray = [...rangeArray];
    const { box, ldm } = toInsert;
    const rangeOverlappedIndex = rangeArray.findIndex(
        (range) => currentFrame >= range.frameStart && currentFrame <= range.frameEnd
    );
    if (rangeOverlappedIndex < 0) {
        newRangeArray.push({ ...toInsert, box, ldm, frameStart: currentFrame, frameEnd: currentFrame });
    } else {
        const rangeOverlapped = rangeArray[rangeOverlappedIndex];
        // if the range is only ONE FRAME (frameStart === frameEnd) we replace it at the index
        if (rangeOverlapped.frameStart === rangeOverlapped.frameEnd) {
            newRangeArray[rangeOverlappedIndex] = {
                ...rangeOverlapped,
                box: box,
                ldm: ldm,
            };
        } else {
            // on modifie la partie du tableau avant la nouvelle insertion SI la frameStart etait inferieur a la currentFrame
            if (rangeOverlapped.frameStart < currentFrame) {
                newRangeArray[rangeOverlappedIndex] = {
                    ...rangeOverlapped,
                    frameEnd: currentFrame - 1,
                };
            }
            // on ajoute la partie du tableau APRES la nouvelle insertion SI la frameEnd etait superieur a la currentFrame
            if (rangeOverlapped.frameEnd > currentFrame) {
                newRangeArray.push({
                    ...rangeOverlapped,
                    frameStart: currentFrame + 1,
                });
            }
            // on ajoute le nouvel element
            newRangeArray.push({
                ...toInsert,
                box: box,
                ldm: ldm,
                frameStart: currentFrame,
                frameEnd: currentFrame,
            });
        }
    }

    return newRangeArray.sort((a, b) => a.frameStart - a.frameEnd);
}

export const TrackPreviewDetection: React.FC<{ isDrawing: boolean }> = (props) => {
    const [trackPreview, { updateTrackPreview }] = useTrackPreview();
    const currentFramePreviewDetection = useCurrentFramePreviewDetection();
    const [{ currentFrame }] = useMedia();
    const shapeDisabled = useShapeDisabled();
    const [, { cropImage }] = useCanvasMedia();

    if (!trackPreview || !currentFramePreviewDetection) return null;
    const dtc = currentFramePreviewDetection;
    const track = trackPreview;

    const handleClick = () => {
        if (props.isDrawing) return;
        const cropOptions = {
            strokeStyle: rectStyle[!trackPreview.active ? 'active' : 'inactive'].stroke,
            fillStyle: rectStyle[!trackPreview.active ? 'active' : 'inactive'].fill,
        };
        const image = cropImage({ box: trackPreview.box, ldm: trackPreview.ldm }, cropOptions);
        if (!image) return;

        updateTrackPreview({ active: !trackPreview.active, thumbPreview: image });
    };

    const handleGestureEnd = (cn: DetectionClassName, data: Box | Point[]) => {
        if (!isBox(data)) {
            let ldm = data;
            let box = _getBoxFromPointArray(data);
            const range = insertFrameInRange(trackPreview.initialRange, { box, ldm }, currentFrame);
            updateTrackPreview({ initialRange: range });
        } else {
            let ldm: Point[] = [];
            if (cn === DetectionClassName.Plate) ldm = _getArrayFromBox(data);
            const range = insertFrameInRange(trackPreview.initialRange, { box: data, ldm }, currentFrame);
            updateTrackPreview({ initialRange: range });
        }
    };

    const renderHeadDetection = () => {
        return (
            <HeadDetection
                disabled={shapeDisabled}
                cn={DetectionClassName.Head}
                box={dtc.box}
                mode={track.active ? 'active' : 'inactive'}
                onDragEnd={(box) => handleGestureEnd(DetectionClassName.Head, box)}
                onTransformEnd={(box) => handleGestureEnd(DetectionClassName.Head, box)}
                handleClick={handleClick}
                transformerVisible={true}
            />
        );
    };

    const renderPlateDetection = () => {
        return (
            <PlateDetection
                mode={track.active ? 'active' : 'inactive'}
                disabled={shapeDisabled}
                cn={DetectionClassName.Plate}
                box={dtc.box}
                ldm={dtc.ldm}
                onDragEnd={(d) => handleGestureEnd(DetectionClassName.Plate, d)}
                onTransformEnd={(d) => handleGestureEnd(DetectionClassName.Plate, d)}
                handleClick={handleClick}
                transformerVisible={true}
            />
        );
    };

    const renderOtherDetection = () => {
        return (
            <OtherDetection
                ldm={dtc.ldm}
                cn={DetectionClassName.Other}
                mode={track.active ? 'active' : 'inactive'}
                disabled={shapeDisabled}
                handleClick={handleClick}
                onDragEnd={(d) => handleGestureEnd(DetectionClassName.Other, d)}
                transformerVisible={true}
            />
        );
    };

    const renderShape = () => {
        switch (track.cn) {
            case 'head':
                return renderHeadDetection();
            case 'plate':
                return renderPlateDetection();
            case 'other':
                return renderOtherDetection();
        }
    };
    return <>{renderShape()}</>;
};
