import React, { useContext, useMemo, useState } from "react";
import CustomButton from "../button/button";
import "../../static/scss/header.scss";
import { useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ReactComponent as ArrowBackSvg } from "../../static/icons/icon_fleche-color.svg";
import Modal, { IModal } from "../modal/Modal";
import { useHeader } from "../../hook/useHeader";
import { ErrorHeaderCardExpired } from "../error/ErrorHeaderCardExpired";
import useWindowDimensions from "../../hook/useWindowDimensions";

// Logo mobile
import { ReactComponent as LogoMobile } from "../../static/images/logo-mobile.svg";
import {
	TASKS_UNAVAILABLE_TYPES,
	serviceUsableState,
} from "../../recoil/services.atom";
import { useRecoilValue } from "recoil";
import { ErrorHeaderServiceDisabled } from "../error/ErrorHeaderServiceDisabled";
import MenuMobilePlugin from "../mobile/menu/MenuMobilePlugin";
import { ErrorHeaderPluginTrial } from "../error/ErrorHeaderPluginTrial";
import { licensesContext } from "../../context/licensesContext";
import moment from "moment";
import { ErrorHeaderPluginDisabled } from "../error/ErrorHeaderPluginDisabled";

const HeaderPlugin: React.FC = () => {
	const { push } = useHistory();
	const location = useLocation();
	const { t } = useTranslation();
	const [iconState, setIconState] = useState({ color: "#333" });
	const [modalState, setModalState] = useState({} as IModal);
	const [showMenu, setShowMenu] = useState<boolean>(false);
	const { getClientName, handleLogout } = useHeader();
	const { width } = useWindowDimensions();
	const serviceUsable = useRecoilValue(serviceUsableState);
	const { data: licenses } = useContext(licensesContext);
	const today = moment();

	const trialEndingDate = useMemo(() => {
		if (
			!licenses ||
			!licenses.length ||
			moment.utc(licenses[0].dateStart).add(7, "days") <= today
		) {
			return null;
		}

		const startingDate = moment.utc(licenses[0].dateStart);
		const endOfTrial = startingDate.add(7, "days");
		const diffDays = endOfTrial.diff(today, "days") + 1;

		switch (diffDays) {
			case 0:
				return t("date.today");
			case 1:
				return t("date.tomorrow");
			default:
				return t("date.in", { count: diffDays });
		}
	}, [licenses, today]);

	const pluginUsable = useMemo(
		() => !!!licenses?.find((license) => license.blocked),
		[licenses]
	);

	const openModalLogout = () => {
		setModalState({
			show: true,
			showCancel: true,
			title: t("logout.title"),
			message: t("logout.message"),
			onConfirm: () => handleLogout(),
			onCancel: () => setModalState({ ...modalState, show: false }),
			confirmBtnText: t("logout.quit"),
			cancelBtnText: t("logout.cancel"),
		});
	};

	const handleIconState = (mouseAction: string) => {
		if (mouseAction === "enter") {
			setIconState({ color: "#F50A6B" });
		} else {
			setIconState({ color: "#333" });
		}
	};

	const displayHeaderTitle = () => {
		const pagesWithBackButton = [
			{
				paths: ["plugin/account/edit"],
				goTo: "/plugin/account",
				isSubsection: false,
			},
		];

		const isPageWithGoBack = pagesWithBackButton.find((page) =>
			page.paths.find((path) => location.pathname.includes(path))
		);
		let title = location.pathname;
		let subsection = "";

		if (isPageWithGoBack && isPageWithGoBack.isSubsection) {
			title = `/${isPageWithGoBack.paths[0].split("/")[1]}`;
			subsection = location.pathname;
		}
		return (
			<>
				{isPageWithGoBack && (
					<div className="mr-2 d-none d-lg-block">
						<ArrowBackSvg
							height={15}
							width={15}
							onMouseEnter={() => handleIconState("enter")}
							onMouseLeave={() => handleIconState("leave")}
							style={{
								cursor: "pointer",
							}}
							fill={iconState.color}
							onClick={() => {
								push(isPageWithGoBack.goTo);
								setIconState({ color: "#333" });
							}}
						/>
					</div>
				)}
				<h1 className="mb-0 mr-1 main-title">{t(`header.${title}`)}</h1>
				{isPageWithGoBack && isPageWithGoBack.isSubsection && (
					<span className="page-subsection d-none d-lg-block">
						{t(`header.${subsection}`)}
					</span>
				)}

				{isPageWithGoBack && width < 992 && (
					<button
						type="button"
						className="button btn-menu btn-header-task active"
						onClick={() => {
							push(isPageWithGoBack.goTo);
							setIconState({ color: "#333" });
						}}
					></button>
				)}
			</>
		);
	};

	const handleMobileMenu = () => {
		setShowMenu(true);
	};

	const mobileLinksCloseList: string[] = ["/plugin/account/edit"];

	return (
		<header
			className={`header  px-lg-5 align-items-center justify-content-between${
				width < 992 && mobileLinksCloseList.includes(location.pathname)
					? " header-task"
					: ""
			}`}
		>
			<div className="d-flex align-items-center">
				<div className="mr-1 d-flex d-lg-none d-xl-none">
					<LogoMobile />
				</div>
				{displayHeaderTitle()}
			</div>
			<div className="d-flex align-items-center">
				{!serviceUsable.status &&
					serviceUsable.reason ===
						TASKS_UNAVAILABLE_TYPES.CARD_EXPIRED && (
						<div className="d-none d-lg-flex d-xl-flex me-4">
							<ErrorHeaderCardExpired />
						</div>
					)}

				{serviceUsable.status && !pluginUsable && (
					<div className="d-none d-lg-flex d-xl-flex me-4">
						<ErrorHeaderPluginDisabled />
					</div>
				)}

				{/* We display service usable error before endingDate */}
				{serviceUsable.status && pluginUsable && trialEndingDate && (
					<div className="d-none d-lg-flex d-xl-flex me-4">
						<ErrorHeaderPluginTrial endingDate={trialEndingDate} />
					</div>
				)}

				{width < 992 &&
				mobileLinksCloseList.includes(location.pathname) ? null : (
					<div className={`d-flex align-items-center`}>
						<CustomButton
							buttonType="button"
							buttonText={getClientName()}
							customClass="mr-1 mr-lg-2"
							classNameType="account"
							onClick={() => push("/plugin/account")}
						/>
						<CustomButton
							buttonType="button"
							buttonText=""
							classNameType="disconnect"
							customClass="d-none d-lg-block"
							onClick={openModalLogout}
						/>
						<CustomButton
							buttonType="button"
							buttonText=""
							classNameType="menu"
							customClass="d-block d-lg-none d-xl-none"
							onClick={handleMobileMenu}
						/>
					</div>
				)}
			</div>
			<Modal {...modalState} />
			<MenuMobilePlugin active={showMenu} setActive={setShowMenu} />
		</header>
	);
};

export default HeaderPlugin;
