import { ReactEventHandler, useState } from 'react';
import { useRefsContext } from '../../../contexts/RefsContexts';
import { ReactComponent as SoundFullSvg } from '../../../../../../../static/icons/studio/icon_sound-full.svg';
import { ReactComponent as SoundMediumSvg } from '../../../../../../../static/icons/studio/icon_sound-medium.svg';
import { ReactComponent as SoundOffSvg } from '../../../../../../../static/icons/studio/icon_sound-off.svg';
import { useRecoilValue } from 'recoil';
import { mediaAtom } from '../../../hooks/useMedia';

export const Audio = () => {
    const { videoRef } = useRefsContext();
    const { filename } = useRecoilValue(mediaAtom);
    const [volumeState, setVolumeState] = useState<number>(100);

    const volumeDisabled = filename.includes('.mkv');

    const onChange: ReactEventHandler<HTMLInputElement> = (e) => {
        if (!videoRef.current || volumeDisabled) return;
        const volumePercent = parseInt(e.currentTarget.value) / 100;
        videoRef.current.volume = volumePercent;
        setVolumeState(parseInt(e.currentTarget.value));
    };

    const onClick: ReactEventHandler<SVGSVGElement> = () => {
        if (!videoRef.current) return;
        if (volumeState === 0) {
            videoRef.current.volume = 0.5;
            setVolumeState(50);
        } else {
            videoRef.current.volume = 0;
            setVolumeState(0);
        }
    };

    const renderVolumeButton = () => {
        if (volumeDisabled) return <SoundOffSvg onClick={onClick} height={15} width={15} />;
        if (volumeState === 0) {
            return <SoundOffSvg onClick={onClick} height={15} width={15} />;
        } else if (volumeState < 60) {
            return <SoundMediumSvg onClick={onClick} height={15} width={15} />;
        } else {
            return <SoundFullSvg onClick={onClick} height={15} width={15} />;
        }
    };

    return (
        <div className="media-controls__controls__audio">
            <div className="media-controls__controls__audio__icon">{renderVolumeButton()}</div>
            {!volumeDisabled && (
                <div className="media-controls__controls__audio__range">
                    <input onChange={onChange} type="range" min={0} max={100} step={1} value={volumeState} />
                </div>
            )}
        </div>
    );
};
