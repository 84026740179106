type MiniModalDeleteConfirmationProps = {
    open: boolean;
    onConfirm: () => void;
    onCancel: () => void;
};

export const MiniModalDeleteConfirmation: React.FC<MiniModalDeleteConfirmationProps> = (props) => {
    const { open, onConfirm, onCancel } = props;
    if (!open) return null;

    return (
        <div className={`modal-delete`}>
            <span className="modal-delete__title">Please confirm your track deletion. This action is irreversible.</span>
            <div className="modal-delete__buttons-container">
                <button className="modal-delete__buttons-container__button cancel" onClick={onCancel}>
                    Cancel
                </button>
                <button className="modal-delete__buttons-container__button confirm" onClick={onConfirm}>
                    Delete track
                </button>
            </div>
        </div>
    );
};
