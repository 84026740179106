import React, { useState } from 'react';
import './input.scss';
import { ReactComponent as ShowSvg } from '../../static/icons/icon-show.svg';

interface IInput {
    isInvalid?: boolean;
    inputType: 'password' | 'text' | 'email';
    inputClassName?: any;
    value?: string;
    labelTranslation?: string;
    placeholder: string;
    isRequired?: boolean;
    id: string;
    name?: string;
    icon?: string;
    maskable?: boolean;
    disable?: boolean;
    errorMessage?: string;
    customClassInput?: string;
    maxLength?: number;
    onChange: (e: any) => any;
    handleBlur?: (e: React.FocusEvent<any>) => void;
    onFocus?: (e: React.FocusEvent<any>) => void;
    withLabel?: boolean;
    order?: number | null;
}

const Input: React.FC<IInput> = ({
    isInvalid,
    inputType,
    inputClassName,
    value,
    labelTranslation,
    placeholder,
    isRequired = false,
    id,
    name,
    maskable = false,
    disable = false,
    errorMessage,
    icon,
    customClassInput,
    withLabel = true,
    maxLength,
    onChange,
    handleBlur,
    onFocus,
    order = null,
}) => {
    const [inputTypeState, setInputTypeState] = useState(inputType);

    const handleTypeWithShow = () => {
        if (maskable) {
            setInputTypeState(inputTypeState === 'text' ? 'password' : 'text');
        }
    };

    const getDivClassName = () => {
        let className = '';
        if (isInvalid) {
            className += ' is-invalid';
        } else if ((value?.length as number) > 0 && !disable) {
            className += ' active';
        } else {
            className = '';
        }
        return className;
    };

    const getInputClassName = () => {
        let className = '';
        if (isInvalid) {
            className += ' is-invalid';
        } else if ((value?.length as number) > 0 && !disable) {
            className += ' input-filled';
        } else {
            className = '';
        }
        return className;
    };

    return (
        <div className={`form-group${order ? ' form-order-' + order : ''}`}>
            {withLabel && (
                <label className={`mb-2 ${isInvalid && 'is-invalid'}`} htmlFor={id}>
                    {labelTranslation}
                    {isRequired && <sup className={`${isInvalid && 'is-invalid'}`}>*</sup>}
                </label>
            )}
            <div className={`${icon ? `icon-${icon}` : ''} ${getDivClassName()}`}>
                <input
                    id={id}
                    name={name}
                    className={`form-control input-text  ${inputClassName} ${getInputClassName()} ${
                        customClassInput || ''
                    }`}
                    type={inputTypeState}
                    onChange={onChange}
                    placeholder={placeholder}
                    value={value}
                    disabled={disable}
                    onBlur={handleBlur}
                    onFocus={onFocus}
                    maxLength={maxLength}
                />
                {maskable && (
                    <ShowSvg
                        fill={inputTypeState === 'password' ? '#DFE2E6' : '#6B7897'}
                        onClick={handleTypeWithShow}
                        className="show-password"
                    />
                )}
                <div className="error-container" style={{ position: 'relative' }}>
                    {isInvalid && <i className="is-invalid">{errorMessage}</i>}
                </div>
            </div>
        </div>
    );
};

export default Input;
