import moment from "moment";
import React from "react";
import { useTranslation } from "react-i18next";
import { Client } from "../../../../context/clientContext";

export const AccountInformation: React.FC<{ clientValues: Client | null }> = ({
	clientValues,
}) => {
	const { t } = useTranslation();

	return (
		<div className="middle-container my-account-view">
			<div className="w-100 d-flex align-items-center mb-3 mb-lg-5">
				<div className="d-flex align-items-center">
					<h2 className="account-title mb-0 me-lg-4">
						{t("account-management.account-information")}
					</h2>
				</div>
			</div>
			<div className="row flex-row mb-0 mb-lg-4">
				<div className="col-12 col-lg-4 mb-3 mb-lg-0">
					<div className="d-flex align-items-center justify-content-md-start">
						<span className="mr-1 text-bold">
							{t("account-management.subscription-date")}
						</span>
						<span className="account-value">
							{moment(clientValues?.date?.created).format(
								"DD/MM/YYYY"
							) || "-"}
						</span>
					</div>
				</div>
			</div>
		</div>
	);
};
