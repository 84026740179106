import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CustomButton from "../components/button/button";
import { useHistory, useLocation } from "react-router";
import Modal, { IModal } from "../components/modal/Modal";

const PluginAccountLayout: React.FC<{ children: React.ReactNode }> = ({
	children,
}) => {
	const { t } = useTranslation();
	const { push } = useHistory();
	const { pathname } = useLocation();
	const [modalState, setModalState] = useState({ show: false } as IModal);

	const handlePageAccount = () => {
		push("/plugin/account");
	};

	const handlePageServices = () => {
		push("/plugin/account/services");
	};

	const handlePageInvoice = () => {
		push("/plugin/account/invoice");
	};

	useEffect(() => {
		if (pathname.includes("update-payment-method-success")) {
			setModalState({
				show: true,
				showCancel: false,
				message: t("account-management.modal-payment-method"),
				onConfirm: () => {
					handlePageAccount();
					setModalState({ show: false });
				},
			});
		} else {
			setModalState({ show: false });
		}
	}, []);

	return (
		<>
			<div className="page-header d-flex justify-content-between mb-3 mb-lg-5">
				<div className="d-flex justify-content-start" role="tablist">
					<CustomButton
						customClass={`${
							!pathname.includes("invoice") &&
							!pathname.includes("services")
								? "active"
								: ""
						} me-lg-4`}
						classNameType="tabButton"
						buttonText={t("account-management.tab-my-account")}
						buttonType="button"
						onClick={handlePageAccount}
						role={"tab"}
						ariaSelected={
							!pathname.includes("invoice") &&
							!pathname.includes("services")
						}
					/>
					<CustomButton
						customClass={`${
							pathname.includes("services") ? "active" : ""
						} me-lg-4`}
						classNameType="tabButton"
						buttonText={t("account-management.tab-my-services")}
						buttonType="button"
						onClick={handlePageServices}
						role={"tab"}
						ariaSelected={pathname.includes("services")}
					/>
					<CustomButton
						customClass={`${
							pathname.includes("invoice") ? "active" : ""
						}`}
						classNameType="tabButton"
						buttonText={t("account-management.tab-my-invoices")}
						buttonType="button"
						onClick={handlePageInvoice}
						role={"tab"}
						ariaSelected={pathname.includes("invoice")}
					/>
				</div>
			</div>
			{children}
			<Modal {...modalState} />
		</>
	);
};

export default PluginAccountLayout;
