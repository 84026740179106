// import { ReactComponent as ArrowTableSvg } from '../../../../../static/icons/fleche.svg';
import { ReactComponent as ArrowTableSvg } from '../../../../../../../../../static/icons/fleche.svg';

type ArrowInputProps = {
    value: number;
    onChange: (action: '+' | '-', value: number) => void;
    limit: number;
};

export const ArrowInput: React.FC<ArrowInputProps> = (props) => {
    const { value, onChange, limit } = props;
    const handleChangeValue = (action: '+' | '-') => {
        onChange(action, action === '+' ? value + 1 : value - 1);
    };

    return (
        <div className="arrow-input">
            <span>{value}</span>
            <div className="arrow-input__arrow-container">
                <button onClick={() => handleChangeValue('+')}>
                    <ArrowTableSvg className="rotate" />
                </button>
                <button onClick={() => handleChangeValue('-')}>
                    <ArrowTableSvg />
                </button>
            </div>
        </div>
    );
};
