export interface ConfigEntry {
	link: string;
	name: string;
	text?: string;
}

export const ressourcesConfig: ConfigEntry[] = [
	{
		link: `${process.env.REACT_APP_SELF_URL}/blurit-adobe.exe`,
		name: "windows",
	},
	{
		link: `${process.env.REACT_APP_SELF_URL}/blurit-adobe.pkg`,
		name: "mac",
	},
];

export const documentationConfig: ConfigEntry[] = [
	{
		link: `${process.env.REACT_APP_SELF_URL}/installation-guide.pdf`,
		text: `${process.env.REACT_APP_SELF_URL}/installation-guide.pdf`,
		name: "documentation",
	},
	{
		link: "mailto:support.services@wassa.io",
		text: "support.services@wassa.io",
		name: "support",
	},
];
