import React, { createContext, useContext, useEffect, useState } from 'react';
import { formatApiCallAndConsumptionTable, formatServicesLayouts, formatServicesListSelect } from '../pages/servicesManagement/helper/formatData';
import { consumptionContext, ConsumptionState } from './consumptionContext';
import { servicesContext, ServicesState } from './servicesContext';
import { clientServicesContext, ClientServicesState } from './clientServicesContext';
import { useTranslation } from 'react-i18next';
import { clientContext } from './clientContext';

type ServiceManagement = {
    consumptionState: ConsumptionState;
    servicesState: ServicesState;
    clientServiceState: ClientServicesState;
    isLoading: boolean;
    dataConsumption?: any;
    generalInformation?: any;
    allClientServicesData?: any;
    calendarData?: any;
    servicesListSelect: { label: string; value: string }[];
    refetchData: (value: boolean) => void;
    handleCurrentService: (value: string) => void;
    refetch: boolean;
    currentService: string | undefined;
};

const servicesManagementContext = createContext({} as ServiceManagement);

const ServiceManagementProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [serviceManagementState, setServiceManagementState] = useState({} as ServiceManagement);
    const [refetch, setRefetch] = useState(false);
    const [currentService, setCurrentService] = useState<string | undefined>(undefined);
    const consumptionState = useContext(consumptionContext);
    const servicesState = useContext(servicesContext);
    const clientServiceState = useContext(clientServicesContext);
    const clientState = useContext(clientContext);

    const { t } = useTranslation();

    const refetchData = (value: boolean) => {
        setRefetch(value);
    };

    const handleCurrentService = (value: string) => {
        setCurrentService(value);
    };

    useEffect(() => {
        clientState.refetchData();
    }, []);

    useEffect(() => {
        if (refetch && clientState) {
            (async () => {
                setServiceManagementState({
                    ...serviceManagementState,
                    isLoading: true,
                });
                const consumption = await consumptionState.getData({ params: { isTrial: clientState.dataClient?.isTrial, service: currentService } });
                const services = await servicesState.getData();
                const clientServices = await clientServiceState.getData();

                setServiceManagementState({
                    ...serviceManagementState,
                    isLoading: false,
                    servicesListSelect: formatServicesListSelect(t),
                    dataConsumption: formatApiCallAndConsumptionTable(consumption),
                    allClientServicesData: formatServicesLayouts(clientServices, services.elements),
                });
                setRefetch(false);
            })();
        }
    }, [refetch, clientState.refetch]);

    return (
        <servicesManagementContext.Provider
            value={{
                ...serviceManagementState,
                consumptionState,
                servicesState,
                clientServiceState,
                refetchData,
                handleCurrentService,
                currentService,
            }}
        >
            {children}
        </servicesManagementContext.Provider>
    );
};

export { ServiceManagementProvider, servicesManagementContext };
