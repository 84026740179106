import { useEffect, useRef } from 'react';

export const Thumb: React.FC<{ image: ImageData; canvasSize: number; onClick?: () => void }> = ({ image, canvasSize, onClick }) => {
    const thumbRef = useRef<HTMLCanvasElement>(null);
    useEffect(() => {
        const canvas = thumbRef.current;
        const ctx = canvas?.getContext('2d');
        if (!canvas || !ctx || !image) return;
        (async () => {
            const ratio = Math.min(100 / image.width, 100 / image.height);
            const centerShift_x = (canvas.width - image.width * ratio) / 2;
            const centerShift_y = (canvas.height - image.height * ratio) / 2;
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            const bitmap = await createImageBitmap(image);
            ctx.drawImage(bitmap, 0, 0, image.width, image.height, centerShift_x, centerShift_y, image.width * ratio, image.height * ratio);
        })();
    }, [image]);

    return <canvas ref={thumbRef} onClick={onClick} height={canvasSize} width={canvasSize} />;
};
