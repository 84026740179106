import { useLoadingState } from '../../../hooks/useLoadingState';
import { useNewTrackCreationPreview } from '../../../hooks/useNewTrackCreationPreview';
import { useTrackPreview } from '../../../hooks/useTrackPreview';
import { MediaVideo, useMedia } from '../../../hooks/useMedia';

export const useButtonsDisabled = (): boolean => {
    const [loadingState] = useLoadingState();
    const [media] = useMedia<MediaVideo>();
    const [trackPreview] = useTrackPreview();
    const [trackCreation] = useNewTrackCreationPreview();
    return !!(loadingState === 'init-studio' || media?.isPlaying || trackPreview || trackCreation);
};
