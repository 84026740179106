import { Thumb } from '../../Thumb';
import { timestampToReadable } from '../../../mediaControls/controls/MediaData';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useNewTrackCreationPreview } from '../../../../hooks/useNewTrackCreationPreview';
import { useCards } from '../hook/useCards';
import { isMediaVideo, mediaAtom } from '../../../../hooks/useMedia';
import { trackEditorAtom } from '../../../../recoil/trackEditor.atom';
import { ArrowInput } from './components/ArrowInput';
import { useFramesTimestamps } from '../../../../hooks/useFramesTimestamp';
import { useTracks } from '../../../../hooks/useTracks';
import { useState } from 'react';
import { MiniModalDeleteConfirmation } from './components/MiniModalDeleteConfirmation';

export const CreationCard: React.FC<{ id: string }> = ({ id }) => {
    const [{ framesTimestamps }] = useFramesTimestamps();
    const [newTrackCreationPreviewState, { removeNewTrackCreationPreview, updateNewTrackCreationPreview }] = useNewTrackCreationPreview();
    const [, { addTrack }] = useTracks();
    const mediaState = useRecoilValue(mediaAtom);
    const [, { closeCard }] = useCards();
    const [, setTrackEditorState] = useRecoilState(trackEditorAtom);
    const [openModal, setOpenModal] = useState(false);

    if (!newTrackCreationPreviewState) return null;

    const frameTsStart = framesTimestamps[newTrackCreationPreviewState.frameStart];
    const frameTsEnd = framesTimestamps[newTrackCreationPreviewState.frameEnd];

    const handleCancelCreation = () => {
        closeCard(id);
        setTrackEditorState(undefined);
        removeNewTrackCreationPreview();
    };

    const handleCancelDeleteTrack = () => {
        setOpenModal(false);
    };

    const handleOpenModal = () => {
        setOpenModal(true);
    };

    const renderReadableTime = (d: number) => {
        const { h: currentH, m: currentM, s: currentS } = timestampToReadable(d);
        return (
            <>
                {currentH}
                {':'}
                {currentM}
                {':'}
                {currentS}
            </>
        );
    };

    const handleClickClose = () => {
        closeCard(id);
        addTrack();
        setTrackEditorState(undefined);
        removeNewTrackCreationPreview();
    };

    const handleChangeArrowInput = (data: 'frameStart' | 'frameEnd') => (action: '+' | '-', value: number) => {
        if (value < 0 || (isMediaVideo(mediaState) && value >= mediaState.nbFrames)) return;
        updateNewTrackCreationPreview({
            [data]: value,
        });
        if (data === 'frameStart') {
            setTrackEditorState((prev) => (prev ? { ...prev, min: value } : undefined));
        } else {
            setTrackEditorState((prev) => (prev ? { ...prev, max: value } : undefined));
        }
    };

    const isVideo = isMediaVideo(mediaState);
    return (
        <>
            <div className="card-animated__head">
                <div className="card-animated__head__title">
                    <h4>New{isVideo ? ' track' : ' detection'}</h4>
                </div>
                <button className="close-btn" onClick={() => handleClickClose()} />
            </div>
            <div className="card-animated__body">
                <div className="card-animated__body__detection-section">
                    <div className="card-animated__body__detection-section__thumb">
                        {newTrackCreationPreviewState.thumbPreview && <Thumb canvasSize={100} image={newTrackCreationPreviewState.thumbPreview} />}
                    </div>
                    <div className="card-animated__body__detection-section__actions">
                        <div onClick={handleOpenModal} className="card-animated__body__detection-section__actions__delete-track">
                            <span>Delete {isVideo ? 'track' : 'detection'} </span>
                            <button></button>
                        </div>
                    </div>
                </div>
                {isVideo && (
                    <div className="card-animated__body__data-section">
                        <div className="card-animated__body__data-section__writable">
                            <div className="card-animated__body__data-section__writable__label">Frame start:</div>
                            <ArrowInput
                                onChange={handleChangeArrowInput('frameStart')}
                                value={newTrackCreationPreviewState.frameStart}
                                limit={newTrackCreationPreviewState.frameEnd}
                            />
                            <div className="card-animated__body__data-section__writable__timestamp"> / {renderReadableTime(frameTsStart.timestamp)}</div>
                            <div className="card-animated__body__data-section__writable__label">Frame end:</div>
                            <ArrowInput
                                onChange={handleChangeArrowInput('frameEnd')}
                                value={newTrackCreationPreviewState.frameEnd}
                                limit={newTrackCreationPreviewState.frameStart}
                            />

                            <div className="card-animated__body__data-section__writable__timestamp"> / {renderReadableTime(frameTsEnd.timestamp)}</div>
                        </div>
                    </div>
                )}

                <div className="card-animated__body__data-section">
                    <div className="card-animated__body__data-section__readable">
                        <div className="card-animated__body__data-section__readable__label">Detection type:</div>
                        <div className="card-animated__body__data-section__readable__value cn">{newTrackCreationPreviewState.cn}</div>
                        {isVideo && (
                            <>
                                <div className="card-animated__body__data-section__readable__label">Track length:</div>
                                <div className="card-animated__body__data-section__readable__value">
                                    {newTrackCreationPreviewState.frameEnd - newTrackCreationPreviewState.frameStart + 1}
                                </div>
                                <div className="card-animated__body__data-section__readable__label">Track duration:</div>
                                <div className="card-animated__body__data-section__readable__value">
                                    {renderReadableTime(frameTsEnd.timestamp - frameTsStart.timestamp)}
                                </div>
                            </>
                        )}
                    </div>
                </div>
                <MiniModalDeleteConfirmation open={openModal} onConfirm={handleCancelCreation} onCancel={handleCancelDeleteTrack} />
            </div>
        </>
    );
};
