import { RefObject, useEffect } from 'react';
import { useRecoilState } from 'recoil';
import { canvasDrawModeAtom } from '../../../../recoil/canvasDrawMode.atom';

export const useHandleClickOutsideCanvasContainer = (ref: RefObject<HTMLDivElement>) => {
    const [canvasDrawModeState, setCanvasDrawModeState] = useRecoilState(canvasDrawModeAtom);

    useEffect(() => {
        const container = ref.current;
        if (!container || !canvasDrawModeState) {
            document.body.style.cursor = 'default';
            return;
        }

        function handleClick(this: Document, ev: MouseEvent) {
            if (!container?.contains(ev.target as Node) && canvasDrawModeState) {
                setCanvasDrawModeState(undefined);
                document.body.style.cursor = 'default';
            }
        }
        document.addEventListener('click', handleClick);
        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, [canvasDrawModeState]);
};
