import { useEffect } from 'react';
import { Box, Point } from '../recoil/framesDetectionsCoordinates.atom';
import { useLoadingState } from './useLoadingState';
import { atom, useRecoilState } from 'recoil';
import { useCurrentFrameDetections } from './useCurrentFrameDetections';

export type ReadableDetection = {
    box: Box;
    ldm: Point[];
    cn: 'plate' | 'head' | 'other';
    trackId: number;
    active: boolean;
};

export const readableDetectionsAtom = atom<ReadableDetection[]>({
    key: 'readableDetectionsAtom',
    default: [],
});

export const useReadDetections = (withPreview: boolean = false): ReadableDetection[] => {
    const currentFrameDetections = useCurrentFrameDetections(false);
    const [readableDetectionsState, setReadableDetectionsState] = useRecoilState(readableDetectionsAtom);
    const [isLoading] = useLoadingState();

    useEffect(() => {
        if (isLoading === 'init-studio') return;
        setReadableDetectionsState((prev) => {
            if (isLoading === 'media-loading') return prev;
            return currentFrameDetections;
        });
    }, [isLoading, currentFrameDetections]);

    if (isLoading === 'init-studio') return [];

    return readableDetectionsState;
};
