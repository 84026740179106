import React, { useContext, useEffect, useState } from 'react';
import { clientContext } from '../../../context/clientContext';
import CustomButton from '../../button/button';
import { IPlanCard } from '../planCard/planCard';
import { pricingContext } from '../../../context/pricingContext';
import { useHistory } from 'react-router';
import PlanSwitchMobile from '../../mobile/planSwitch/planSwitch';

// Icon
import { ReactComponent as IconArrowBottom } from '../../../static/icons/icon-arrow-bottom.svg';

type Props = {
    data: IPlanCard[];
};

const useGetElementByIdInDom = (id: string) => {
    const [element, setElement] = useState<DOMRect | null>(null);

    useEffect(() => {
        const handleScroll = () => {
            const elementInDom = document.getElementById(id);
            if (elementInDom) {
                setElement(elementInDom.getBoundingClientRect());
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [id]);

    return element;
};

const StickyHeader: React.FC<Props> = ({ data }) => {
    const headerElement = useGetElementByIdInDom('sticky-header');
    const { dataClient } = useContext(clientContext);
    const { handleSelected, selected } = useContext(pricingContext);
    const { push } = useHistory();

    const currentPlan = () => {
        if (dataClient?.isTrial) {
            return 'TRIAL';
        } else if (dataClient?.support) {
            return dataClient.support;
        }
    };

    const getClasses = (index: number, card: IPlanCard) => {
        const classes = [];
        if (headerElement?.top === 110) {
            classes.push('reduced');
        }
        if (index === 0) {
            classes.push('trial');
        }
        if (currentPlan() === card.title?.toUpperCase()) {
            classes.push('disabled');
        }
        if (selected === index || currentPlan() === card.title?.toUpperCase()) {
            classes.push('active');
        }
        return classes.join(' ');
    };

    return (
        <>
            <section id="sticky-header" className="d-flex plans-card-container w-100 sticky-header">
                {data.map((card, index) => (
                    <div key={index} className={`sticky-header__card ${getClasses(index, card)}`}>
                        <h2 className="sticky-header__card__title">{card.title}</h2>
                        <div className="sticky-header__card__pricing-per-month">
                            <span className="price">{card.price}</span>
                            <span className="euro">€*</span>
                            <span className="text">&nbsp; / month</span>
                        </div>
                        {currentPlan() === card.title?.toUpperCase() ? (
                            <h3 className="sticky-header__card__current-plan mb-0">Your current plan</h3>
                        ) : (
                            <>
                                <CustomButton
                                    buttonType="button"
                                    buttonText="Update plan"
                                    classNameType="mainWhite"
                                    onClick={() => push(`/payment/${card.name}/step-2`)}
                                    disabled={currentPlan() === card.title?.toUpperCase()}
                                />
                            </>
                        )}
                    </div>
                ))}
            </section>

            <div className="compare-title">
                Compare all features
                <IconArrowBottom fill="#F50A6B" />
            </div>

            <PlanSwitchMobile handleSelected={handleSelected} />
        </>
    );
};

export default StickyHeader;
