import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import Modal, { IModal } from "../../../components/modal/Modal";
import { clientContext, Client } from "../../../context/clientContext";
import EditAccount, { UpdateClient } from "./editAccount";

const EditAccountFactory: React.FC = () => {
	const [modalState, setModalState] = useState({} as IModal);
	const { t } = useTranslation();
	const { push } = useHistory();
	const { dataClient, updateClient } = useContext(clientContext);

	const handleupdateClient = async (values: UpdateClient) => {
		try {
			setModalState({ ...modalState, show: false });
			await updateClient(values);

			setModalState({
				show: true,
				showCancel: false,
				message: t("account-management.edit-message-success"),
				onConfirm: () => {
					setModalState({ ...modalState, show: false });
					push("/plugin/account");
				},
			});
		} catch (e) {
			setModalState({
				show: true,
				showCancel: false,
				message: t("error.internalError"),
				onConfirm: () => {
					setModalState({ ...modalState, show: false });
				},
			});
		}
	};

	const handleCancel = () => {
		setModalState({
			show: true,
			showCancel: true,
			message: t("account-management.edit-message-cancel"),
			onConfirm: () => {
				setModalState({ ...modalState, show: false });
				push("/plugin/account");
			},
			onCancel: () => setModalState({ ...modalState, show: false }),
		});
	};

	const onSubmit = (values: UpdateClient) => {
		setModalState({
			show: true,
			showCancel: true,
			message: t("account-management.edit-message-confirm"),
			onCancel: () => setModalState({ ...modalState, show: false }),
			onConfirm: () => handleupdateClient(values),
		});
	};

	return (
		<>
			<EditAccount
				client={dataClient || ({} as Client)}
				onSubmit={onSubmit}
				handleCancel={handleCancel}
			/>
			<Modal {...modalState} />
		</>
	);
};
export default EditAccountFactory;
