import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { TaskComponentProps } from '../taskFactory';
import { ReactComponent as PictureSvg } from '../../../static/icons/tasks/icon_picture.svg';
import CustomButton from '../../../components/button/button';
import CustomSelect from '../../../components/select/CustomSelect';
import { useAxios } from '../../../services/hook/requestsService';
import pngImage from '../../../static/icons/tasks/icons8-zip-96.png';
import { clientContext } from '../../../context/clientContext';
import { useHeader } from '../../../hook/useHeader';
import DragNDrop from '../../../components/dragNDrop/DragNDrop';
import { DragNDropProvider } from '../../../components/dragNDrop/DragNDropContext';
import { useDragNDrop } from '../../../components/dragNDrop/useDragNDrop';
import { randomKeyGen } from '../../../services/tools';
import FormData from 'form-data';
import { NoPreview } from '../component/NoPreview';
import useWindowDimensions from '../../../hook/useWindowDimensions';

const pictureColor = '#BFC7CE';

const waterMarkPositionEnums = ['upper_right', 'upper_left', 'upper', 'lower_right', 'lower_left', 'lower', 'center_right', 'center_left', 'center'] as const;

const watermarkRatio = [
    { label: '10%', value: 0.1 },
    { label: '20%', value: 0.2 },
    { label: '30%', value: 0.3 },
    { label: '40%', value: 0.4 },
    { label: '50%', value: 0.5 },
    { label: '60%', value: 0.6 },
    { label: '70%', value: 0.7 },
    { label: '80%', value: 0.8 },
    { label: '90%', value: 0.9 },
    { label: '100%', value: 1 },
];

const watermarkTransparency = [
    { label: '0%', value: 1 },
    { label: '10%', value: 0.9 },
    { label: '20%', value: 0.8 },
    { label: '30%', value: 0.7 },
    { label: '40%', value: 0.6 },
    { label: '50%', value: 0.5 },
    { label: '60%', value: 0.4 },
    { label: '70%', value: 0.3 },
    { label: '80%', value: 0.2 },
    { label: '90%', value: 0.1 },
    { label: '100%', value: 0 },
];

type Media = { fileUrl: string; file: any } | null;

type OptionsWatermark = {
    watermark_transparency: number;
    watermark_ratio: number;
    watermark_position_preset: typeof waterMarkPositionEnums[number];
};

const PreviewComponent: React.FC<{
    files: { [name: string]: Media };
    optionsWatermark: OptionsWatermark;
}> = ({ files, optionsWatermark }) => {
    const [isMediaLoaded, setMediaLoaded] = useState(false);
    const [watermarkPosition, setWatermarkPosition] = useState<any>(null);
    const [watermarkRatio, setWatermarkRatio] = useState<any>(null);

    const getDataSize = () => {
        const media = document.getElementById('media');
        const container = document.getElementById('container');

        if (media && container) {
            const positions = getPosition(container, media);
            const ratio = getWatermarkRatio(media);
            setWatermarkPosition(positions);
            setWatermarkRatio(ratio);
        }
    };

    useEffect(() => {
        if (isMediaLoaded) {
            getDataSize();
        }
        window.addEventListener('resize', getDataSize);
        return () => {
            window.removeEventListener('resize', getDataSize);
        };
    }, [files, isMediaLoaded, optionsWatermark]);

    const getPosition = (containerData: any, mediaData: any) => {
        switch (optionsWatermark.watermark_position_preset) {
            case 'center_left': {
                return {
                    left: Math.floor((containerData.clientWidth - mediaData.clientWidth) / 2),
                };
            }
            case 'center_right': {
                return {
                    right: Math.round((containerData.clientWidth - mediaData.clientWidth) / 2),
                };
            }
            case 'upper': {
                return {
                    top: Math.round((containerData.clientHeight - mediaData.clientHeight) / 2),
                };
            }
            case 'upper_left': {
                return {
                    top: Math.floor((containerData.clientHeight - mediaData.clientHeight) / 2),
                    left: Math.floor((containerData.clientWidth - mediaData.clientWidth) / 2),
                };
            }
            case 'upper_right': {
                return {
                    top: Math.round((containerData.clientHeight - mediaData.clientHeight) / 2),
                    right: Math.round((containerData.clientWidth - mediaData.clientWidth) / 2),
                };
            }
            case 'lower_left': {
                return {
                    bottom: Math.floor((containerData.clientHeight - mediaData.clientHeight) / 2),
                    left: Math.floor((containerData.clientWidth - mediaData.clientWidth) / 2),
                };
            }
            case 'lower': {
                return {
                    bottom: Math.floor((containerData.clientHeight - mediaData.clientHeight) / 2),
                };
            }
            case 'lower_right': {
                return {
                    bottom: Math.round((containerData.clientHeight - mediaData.clientHeight) / 2),
                    right: Math.round((containerData.clientWidth - mediaData.clientWidth) / 2),
                };
            }
        }
    };

    const getWatermarkRatio = (mediaData: any) => {
        return mediaData.clientWidth * optionsWatermark.watermark_ratio;
    };

    const handleLoadedImage = () => {
        setMediaLoaded(true);
    };

    return (
        <div className="px-3 py-3 preview d-flex flex-column align-items-center justify-content-center">
            {!files['input_media'] ? (
                <PictureSvg height={80} width={80} fill={pictureColor} />
            ) : (
                <div id="container">
                    {['application/zip', 'application/x-zip-compressed', 'application/zip-compressed'].includes(files['input_media'].file.type) ? (
                        <img src={pngImage} height="96px" alt="img" />
                    ) : files['input_media'].file.type.includes('image') ? (
                        <img id="media" className="selected-background" src={files['input_media'].fileUrl} onLoad={handleLoadedImage} alt="img" />
                    ) : files['input_media'].file.type === 'video/avi' ? (
                        <NoPreview />
                    ) : (
                        <video
                            id="media"
                            style={{ maxWidth: '350px', maxHeight: '100%' }}
                            preload="auto"
                            onLoadedData={handleLoadedImage}
                            src={files['input_media'].fileUrl}
                        />
                    )}
                    {!['application/zip', 'application/x-zip-compressed', 'video/avi'].includes(files['input_media'].file.type) && files['input_watermark'] && (
                        <img
                            src={files['input_watermark']?.fileUrl}
                            style={{
                                position: 'absolute',
                                zIndex: 10,
                                width: watermarkRatio,
                                opacity: optionsWatermark.watermark_transparency,
                                height: 'auto',
                                ...watermarkPosition,
                            }}
                            alt="img"
                        ></img>
                    )}
                    <div className="mb-2"></div>
                    {files['input_media'] && ['application/zip', 'application/x-zip-compressed', 'video/avi'].includes(files['input_media'].file.type) && (
                        <p className="color-primary">{files['input_media'].file.name}</p>
                    )}
                </div>
            )}
        </div>
    );
};

const OptionsComponent: React.FC<{
    optionsWatermark: OptionsWatermark;
    onChangeSelect: any;
    isUsable: boolean;
    submitTask: () => void;
    cancelTask: () => void;
    disabledSubmit: boolean;
}> = ({ optionsWatermark, onChangeSelect, isUsable, submitTask, cancelTask, disabledSubmit }) => {
    const { t } = useTranslation();
    const { width } = useWindowDimensions();

    return (
        <div className={`options ms-lg-3 d-flex`} style={{ height: `${width < 992 ? 'auto' : '400px'}` }}>
            <div className="w-100 h-100 d-flex flex-column  justify-content-around align-items-between mt-lg-2">
                <div className="mb-lg-4">
                    <div className="w-100 d-flex justify-content-between align-items-center mb-4 mb-lg-2">
                        <span className={`${isUsable ? 'color-clear-blue-grey' : ''}`}>{t('task-management.tasks.watermark.transparency')}</span>
                        <div className="select-container">
                            <CustomSelect
                                isDisabled={!isUsable}
                                options={watermarkTransparency}
                                value={watermarkTransparency.find((t) => t.value === optionsWatermark.watermark_transparency)}
                                onChange={(e) => onChangeSelect(e, 'watermark_transparency')}
                            />
                        </div>
                    </div>
                    <div className="w-100 d-flex justify-content-between align-items-center mb-4 mb-lg-2">
                        <span className={`${isUsable ? 'color-clear-blue-grey' : ''}`}>{t('task-management.tasks.watermark.position')}</span>
                        <div className="select-container">
                            <CustomSelect
                                isDisabled={!isUsable}
                                options={waterMarkPositionEnums.map((data: any) => ({ value: data, label: data.replace('_', ' ') }))}
                                value={{
                                    value: optionsWatermark.watermark_position_preset,
                                    label: optionsWatermark.watermark_position_preset.replace('_', ' '),
                                }}
                                onChange={(e) => onChangeSelect(e, 'watermark_position_preset')}
                            />
                        </div>
                    </div>
                    <div className="w-100 d-flex justify-content-between align-items-center mb-4 mb-lg-2">
                        <span className={`${isUsable ? 'color-clear-blue-grey' : ''}`}>{t('task-management.tasks.watermark.size')}</span>
                        <div className="select-container">
                            <CustomSelect
                                isDisabled={!isUsable}
                                options={watermarkRatio}
                                value={watermarkRatio.find((t) => t.value === optionsWatermark.watermark_ratio)}
                                onChange={(e) => onChangeSelect(e, 'watermark_ratio')}
                            />
                        </div>
                    </div>
                </div>
                <h3 className="image-processing d-flex justify-content-center mb-4 mb-lg-2">{t('task-management.tasks.image-processing')}</h3>
                <div className="d-flex justify-content-center align-items-center w-100">
                    {
                        <div className="mb-3">
                            <CustomButton classNameType="mainWhite" buttonText="Cancel" customClass="me-4" onClick={cancelTask} />
                            <CustomButton classNameType="main" buttonText="Create" customClass="ml-2" disabled={disabledSubmit} onClick={submitTask} />
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};

type MediaComponentProps = {
    file: Media;
    isErrorSize: boolean;
    onChangeFile: (name: 'input_media' | 'input_watermark', file: File) => void;
};

const InputMediaComponent: React.FC<MediaComponentProps> = ({ file, isErrorSize, onChangeFile }) => {
    const dragNDropStore = useDragNDrop();

    const { t } = useTranslation();
    const onDrop = (file: File) => {
        onChangeFile('input_media', file);
    };
    const getPreviewPictureColor = () => {
        return dragNDropStore.dragging ? '#f50a6b' : '#BFC7CE';
    };
    const onOpenFile = () => {
        dragNDropStore.inputRef?.current?.click();
    };
    const { width } = useWindowDimensions();

    const EmptyFile = () => (
        <div
            style={{ border: `${width < 992 ? '0' : `3px dashed ${getPreviewPictureColor()}`}` }}
            className="picture-container d-flex justify-content-center align-items-center"
        >
            <PictureSvg height={50} width={50} fill={getPreviewPictureColor()} />
        </div>
    );
    const PreviewFile = () => {
        return (
            <div className="picture-container d-flex justify-content-center align-items-center preview">
                {['application/zip', 'application/x-zip-compressed'].includes(file?.file.type) ? (
                    <img src={pngImage} height="96px" alt="img" />
                ) : file?.file.type === 'video/avi' ? (
                    <NoPreview withMessage={false} />
                ) : !file?.file.type.includes('image') ? (
                    <video width="100%" height="100%" className="clip-thumbnail" preload="auto" src={file?.fileUrl} />
                ) : (
                    <img src={file.fileUrl} style={{ height: '100%', width: '100%', objectFit: 'contain' }} alt={'selected-file'} />
                )}
            </div>
        );
    };
    return (
        <DragNDrop id={randomKeyGen()} onDrop={onDrop}>
            <h3 className="color-primary d-block d-lg-none d-xl-none text-center file-container-title">{t('task-management.tasks.watermark.select-file')}</h3>
            <div className={`file-container d-flex ${file ? 'selected' : ''} bordered`}>
                {isErrorSize ? (
                    <div className="error-container d-flex flex-column text-center me-lg-4">
                        <p className="color-primary">{t('task-management.error.fileTooLong-title')}</p>
                        <p className="color-primary">{t('task-management.error.fileTooLong')}</p>
                    </div>
                ) : (
                    <>
                        <div className={`me-lg-4 w-100-mobile ${file ? 'preview' : ''} d-flex align-items-center justify-content-center`}>
                            {!file ? <EmptyFile /> : <PreviewFile />}
                        </div>
                    </>
                )}
                <div className="d-flex flex-column align-items-center justify-content-between">
                    <h3 className="color-primary d-none d-lg-block d-xl-block">{t('task-management.tasks.watermark.select-file')}</h3>
                    <div className="mb-3 text-center file-container-laius">
                        <span className="color-clear-text text-center mb-1">{t('task-management.tasks.watermark.select-file-laius')}</span>
                        <span className="color-clear-text text-center mb-1">{t('task-management.tasks.watermark.max-size')}</span>
                    </div>
                    <CustomButton classNameType={!file ? 'main' : 'mainWhite'} buttonText={!file ? 'Select file' : 'Change file'} onClick={onOpenFile} />
                </div>
            </div>
        </DragNDrop>
    );
};

const WatermarkMediaComponent: React.FC<MediaComponentProps & { disabled: boolean }> = ({ file, onChangeFile, disabled }) => {
    const dragNDropStore = useDragNDrop();
    const { t } = useTranslation();

    const onOpenFile = () => {
        dragNDropStore.inputRef?.current?.click();
    };
    const onDrop = (file: File) => {
        if (disabled) return;
        return onChangeFile('input_watermark', file);
    };
    const getPreviewPictureColor = () => {
        return !disabled && dragNDropStore.dragging ? '#f50a6b' : '#BFC7CE';
    };

    const { width } = useWindowDimensions();

    return (
        <DragNDrop id={randomKeyGen()} onDrop={onDrop} disabled={disabled}>
            <h3 className={`d-block d-lg-none d-xl-none text-center file-container-title ${!disabled ? 'color-primary' : ''}`}>
                {t('task-management.tasks.watermark.select-watermark')}
            </h3>
            <div className={`file-container ms-lg-2 d-flex  ${file ? 'selected' : ''} ${!disabled ? 'bordered' : ''}`}>
                <div className={`me-lg-4 w-100-mobile ${file ? 'preview' : ''}`}>
                    {!file ? (
                        <div
                            style={{ border: `${width < 992 ? `0` : `3px dashed ${getPreviewPictureColor()}`}` }}
                            className="picture-container d-flex justify-content-center align-items-center"
                        >
                            <PictureSvg height={50} width={50} fill={getPreviewPictureColor()} />
                        </div>
                    ) : (
                        <div className="picture-container d-flex justify-content-center align-items-center preview">
                            <img src={file.fileUrl} style={{ height: '100%', width: '100%', objectFit: 'contain' }} alt={'selected-file'} />
                        </div>
                    )}
                </div>
                <div className="d-flex h-100 flex-column align-items-center justify-content-start justify-content-lg-center ms-lg-4 me-lg-4">
                    <h3 className={`mb-4 d-none d-lg-block d-xl-block ${!disabled ? 'color-primary' : ''}`}>
                        {t('task-management.tasks.watermark.select-watermark')}
                    </h3>
                    <span className="color-clear-text text-center mb-3 mb-lg-4 file-container-laius file-container-laius-flex">
                        {t('task-management.tasks.watermark.select-watermark-laius')}
                    </span>
                    <CustomButton
                        classNameType={!file ? 'main' : 'mainWhite'}
                        buttonText={!file ? 'Select file' : 'Change file'}
                        disabled={disabled}
                        onClick={onOpenFile}
                    />
                </div>
            </div>
        </DragNDrop>
    );
};

const WatermarkTask: React.FC<TaskComponentProps> = ({ handleCancel, handleError, handleConfirm, setProgressUpload }) => {
    const client = useContext(clientContext);
    const { t } = useTranslation();
    const headerData = useHeader();

    const [optionsWatermark, setOptionsWatermark] = useState<OptionsWatermark>({
        watermark_transparency: 0.5,
        watermark_ratio: 0.1,
        watermark_position_preset: 'upper_left',
    });
    const [isErrorSize, setIsErrorSize] = useState(false);

    const { postData } = useAxios();
    const [files, setFiles] = useState<{ [name: string]: Media }>({
        input_media: null,
        input_watermark: null,
    });
    const onChangeFile2 = (name: 'input_media' | 'input_watermark', file: File) => {
        if (file) {
            if (name === 'input_media') {
                if (file.size > 21_474_836_480) {
                    setIsErrorSize(true);
                    setFiles({ input_media: null });
                } else {
                    setIsErrorSize(false);
                    setFiles({
                        ...files,
                        [name]: { fileUrl: URL.createObjectURL(file), file },
                        input_watermark: null,
                    });
                }
            } else {
                setFiles({
                    ...files,
                    [name]: { fileUrl: URL.createObjectURL(file), file },
                });
            }
        }
    };

    const onUploadProgress = (progressEvent: any) => {
        var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        setProgressUpload(percentCompleted);
    };

    const handleOptionsWatermark = (selectValue: any, key: 'watermark_transparency' | 'watermark_ratio' | 'watermark_position_preset') => {
        setOptionsWatermark({
            ...optionsWatermark,
            [key]: selectValue.value,
        });
    };

    const handlePostData = async (withMail?: boolean) => {
        try {
            const form = new FormData();
            form.append('input_media', files['input_media']?.file, files['input_media']?.file.filename);
            form.append('input_watermark', files['input_watermark']?.file, files['input_watermark']?.file.filename);
            form.append('watermark_transparency', optionsWatermark.watermark_transparency.toString());
            form.append('watermark_ratio', optionsWatermark.watermark_ratio.toString());
            form.append('watermark_position_preset', optionsWatermark.watermark_position_preset.toString());
            if (withMail) {
                form.append('with_mail', true);
            }
            await postData(null, 'task-management/watermark', form, { onUploadProgress });
        } catch (e) {
            throw e;
        }
    };

    const submitTask = () => {
        const authorizedFormatMedia = [
            'image/jpg',
            'image/jpeg',
            'image/png',
            'image/webp',
            'video/mp4',
            'video/avi',
            'application/zip',
            'application/x-zip-compressed',
            'application/zip-compressed',
            'video/x-msvideo',
            'video/quicktime',
        ];
        const authorizedFormatWatermark = ['image/jpg', 'image/jpeg', 'image/png'];
        if (
            client.dataClient?.isTrial &&
            // trial remaining en octet MOINS la taille du fichier en octet
            headerData.getTrialState() - (files['input_media']?.file?.size + files['input_watermark']?.file?.size) < 0
        ) {
            handleError({ response: { data: { message: 'trialLimitation' } } });
            return;
        }
        if (files['input_media']?.file?.size > 21_474_836_480 || files['input_watermark']?.file?.size > 21_474_836_480) {
            handleError({ response: { data: { message: 'task-management.error.fileTooLong' } } });
        } else if (
            !authorizedFormatMedia.includes(files['input_media']?.file?.type) &&
            !files['input_media']?.file?.name.includes('.mkv') &&
            !files['input_media']?.file?.name.includes('.MKV')
        ) {
            handleError({ response: { data: { message: 'task-management.error.unsupported-format-media' } } });
        } else if (!authorizedFormatWatermark.includes(files['input_watermark']?.file?.type)) {
            handleError({ response: { data: { message: 'task-management.error.unsupported-format-watermark' } } });
        } else {
            handleConfirm('Watermark', files['input_media']?.file.size + files['input_watermark']?.file.size, handlePostData, true);
        }
    };

    const isInputMediaSelected = () => files.input_media;
    const isAllFiles = () => files.input_media && files.input_watermark;
    const { width } = useWindowDimensions();
    return (
        <div className="mx-lg-3 task-page">
            <div className="d-flex mx-lg-5 align-items-center flex-column">
                <div className="w-100 row flex-row px-lg-4 mb-3">
                    <div className="col-6">
                        <DragNDropProvider accepts="image/jpeg, image/jpg, image/png, image/webp, application/zip, application/x-zip-compressed, video/mp4, video/avi, video/quicktime, .mkv, .MKV">
                            <InputMediaComponent file={files['input_media']} isErrorSize={isErrorSize} onChangeFile={onChangeFile2} />
                        </DragNDropProvider>
                    </div>
                    <div className="col-6">
                        <DragNDropProvider accepts="image/jpeg, image/png">
                            <WatermarkMediaComponent
                                file={files['input_watermark']}
                                isErrorSize={isErrorSize}
                                onChangeFile={onChangeFile2}
                                disabled={files['input_media'] ? false : true}
                            />
                        </DragNDropProvider>
                    </div>
                </div>
                <div className="w-100 px-lg-4 d-flex row flex-row d-flex align-items-center options-container mt-3">
                    <div className="col-12 col-lg-6 mb-3 mb-lg-0">
                        <h3 className={`mb-4 mb-lg-2 d-none d-lg-block d-xl-block ${!isInputMediaSelected() ? 'color-clear-text' : 'color-primary'}`}>
                            {t('task-management.tasks.watermark.media-preview')}
                        </h3>
                        <h3
                            className={`mb-4 d-block d-lg-none d-xl-none ${files['input_watermark'] !== null && files['input_media'] !== null} ${!isAllFiles() ? 'color-clear-text' : 'color-primary'
                                }`}
                            aria-hidden={width > 992}
                        >
                            {t('task-management.tasks.watermark.watermark-options')}
                        </h3>
                        <PreviewComponent optionsWatermark={optionsWatermark} files={files} />
                    </div>
                    <div className="col-12 col-lg-6">
                        <h3
                            className={`mb-2 ml-1 d-none d-lg-block d-xl-block ${files['input_watermark'] !== null && files['input_media'] !== null} ${!isAllFiles() ? 'color-clear-text' : 'color-primary'
                                }`}
                        >
                            {t('task-management.tasks.watermark.watermark-options')}
                        </h3>
                        <OptionsComponent
                            disabledSubmit={!files['input_media'] || !files['input_watermark']}
                            submitTask={submitTask}
                            cancelTask={handleCancel}
                            optionsWatermark={optionsWatermark}
                            onChangeSelect={handleOptionsWatermark}
                            isUsable={files['input_watermark'] !== null && files['input_media'] !== null}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WatermarkTask;
