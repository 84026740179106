import RoundedLoader from '../../components/loader/RoundedLoaderAnimated';
import { useQuery } from '../../services/hook/useQuery';

export const Redirection = () => {
    const query = useQuery();
    let code = query.get('code');

    return (
        <div className="h-100 w-100 d-flex justify-center align-center">
            <RoundedLoader size={50} isLoading={true} />
            <meta http-equiv="refresh" content={`1;URL="${window.location.origin}/register-password?code=${code}"`}></meta>
        </div>
    );
};
