import { useEffect } from 'react';
import { atom, selectorFamily, useRecoilState, useRecoilValue } from 'recoil';
import { FileType } from '../../../../../types/historyCall';
import { useRefsContext } from '../contexts/RefsContexts';
import { GetStudioDataSuccess } from '../hooks/useFetchStudioData';
import { useFramesTimestamps, FrameTimestamp } from './useFramesTimestamp';

export type MediaVideo = {
    framerate: number;
    nbFrames: number;
    isPlaying: boolean;
    buffering: number;
} & MediaAtom;

export type MediaImage = MediaAtom;

export type MediaAtom = {
    src: string;
    filename: string;
    currentFrame: number;
    isLoading: boolean;
};

export const isMediaVideo = (data: unknown): data is MediaVideo => {
    return !!(data as MediaVideo).framerate;
};

export const mediaAtom = atom<MediaVideo | MediaImage>({
    key: 'mediaAtom',
    default: {
        src: '',
        filename: '',
        currentFrame: 0,
        isLoading: true,
    },
});

/**
 * Hook to consume the mediaAtom state.
 * The "initialValues" parameter is called in useInitStudio hook instanciate the state with the correct values from api fetch
 * @param initialValues
 * @returns
 */
export const useMedia = <T extends MediaAtom>(): [
    T,
    {
        initMedia: (data: GetStudioDataSuccess) => void;
        updateCurrentFrame: (currentFrame: number) => void;
        updatePlayPause: (isPlaying: boolean) => void;
    }
] => {
    const [mediaState, setMediaAtomState] = useRecoilState(mediaAtom);

    const initMedia = (data: GetStudioDataSuccess) => {
        switch (data.task.file_type) {
            case FileType.Video: {
                setMediaAtomState((prev) => {
                    return {
                        ...prev,
                        src: data.mediaUrl,
                        filename: data.task.filename,
                        framerate: data.task.metadata.frameRate,
                        nbFrames: data.framesInfo.timestamps.frames.length,
                        currentFrame: 0,
                        isLoading: false,
                    };
                });
                break;
            }
            case FileType.Picture: {
                setMediaAtomState((prev) => {
                    return {
                        ...prev,
                        src: data.mediaUrl,
                        filename: data.task.filename,
                        nbFrames: 1,
                        isLoading: false,
                    };
                });
                break;
            }
        }
    };

    const updateCurrentFrame = (currentFrame: number) => {
        setMediaAtomState((prev) => ({ ...prev, currentFrame: currentFrame }));
    };

    const updatePlayPause = (isPlaying: boolean) => {
        setMediaAtomState((prev) => ({ ...prev, isPlaying: isPlaying }));
    };

    return [mediaState as T, { updateCurrentFrame, updatePlayPause, initMedia }];
};
