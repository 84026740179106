import React from "react";
import { ConfigEntry, documentationConfig, ressourcesConfig } from "./config";
import { useTranslation } from "react-i18next";

interface ConfigLineProps extends ConfigEntry {
	section: "documentation" | "downloads";
}

export const ConfigLine: React.FC<ConfigLineProps> = ({
	section,
	link,
	text,
	name,
}) => {
	const { t } = useTranslation();

	return (
		<div className="row flex-row align-items-center mb-3">
			<div className="col-12 col-lg-4">
				<span className="me-5 text-bold">
					{t(`ressources.${section}.links.${name}`)}
				</span>
			</div>
			<div className="col-12 col-lg-6">
				<a
					style={{
						textDecoration: "underline",
						color: "#F50A6B",
					}}
					href={link}
					title={name}
					target="_blank"
					rel="noreferrer"
				>
					{text}
				</a>
			</div>
		</div>
	);
};

export const ConfigButton: React.FC<ConfigLineProps> = ({
	section,
	link,
	name,
}) => {
	const { t } = useTranslation();

	return (
		<a
			style={{
				display: "inline-block",
				textDecoration: "none",
				height: "inherit",
				textAlign: "center",
			}}
			className="button btn-main"
			href={link}
			title={name}
			target="_blank"
			rel="noreferrer"
			role="button"
		>
			{t(`ressources.${section}.links.${name}`)}
		</a>
	);
};

export const Ressources: React.FC = () => {
	const { t } = useTranslation();

	return (
		<>
			<div className="middle-container middle-container-developer mb-3">
				<h2 className="mb-lg-4 mb-3 middle-container-title">
					{t("ressources.downloads.title")}
				</h2>
				<div className="d-flex flex-column mb-3">
					<span
						className="fst-italic color-clear-blue-grey mb-2"
						style={{ whiteSpace: "pre-line" }}
					>
						{t("ressources.downloads.laius")}
					</span>
				</div>
				<div className="d-flex gap-4">
					{ressourcesConfig.map((item, key) => (
						<ConfigButton key={key} section="downloads" {...item} />
					))}
				</div>
			</div>
			<div className="middle-container middle-container-developer">
				<h2 className="mb-lg-4 mb-3 middle-container-title">
					{t("ressources.documentation.title")}
				</h2>
				{documentationConfig.map((item, key) => (
					<ConfigLine key={key} section="documentation" {...item} />
				))}
			</div>
		</>
	);
};
