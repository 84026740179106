import { useEffect, useMemo } from 'react';
import { atom, atomFamily, selectorFamily, useRecoilState, useRecoilValue } from 'recoil';
import { rectStyle } from '../components/mediaVisualizer/canvasDetections/types';
import { useRefsContext } from '../contexts/RefsContexts';
import { Box, Point } from '../recoil/framesDetectionsCoordinates.atom';
import { useMedia } from '../hooks/useMedia';
import { useCanvasMedia } from './useCanvasMedia';
import { CurrentFrameDetectionState, useCurrentFrameDetections } from './useCurrentFrameDetections';
import { useLoadingState } from './useLoadingState';
import { isMediaVideo, MediaVideo } from './useMedia';
import { useNewTrackCreationPreview } from './useNewTrackCreationPreview';
import { useTrackPreview } from './useTrackPreview';

type ThumbGridElement = {
    trackId: number;
    image: ImageData | null;
    box: Box;
    ldm: Point[];
    isHover: boolean;
    active: boolean;
    cn: 'head' | 'plate' | 'other';
};

type ThumbsGridState = {
    isLoading: boolean;
    elements: ThumbGridElement[];
};

export const thumbsGridAtom = atom<ThumbsGridState>({
    key: 'thumbsGridAtom',
    default: {
        isLoading: true,
        elements: [],
    },
});

export const thumbHoverAtom = atom<number | undefined>({
    key: 'thumbHoverAtom',
    default: undefined,
});

export const thumbsGridSelector = selectorFamily({
    key: 'testSelector',
    get:
        (data: CurrentFrameDetectionState[]) =>
        ({ get }) => {
            const thumbHover = get(thumbHoverAtom);
            const thumbs: ThumbGridElement[] = [];
            for (let d of data) {
                thumbs.push({
                    trackId: d.trackId,
                    image: null,
                    box: d.box,
                    ldm: d.ldm,
                    isHover: !!(thumbHover && thumbHover >= 0 && thumbHover === d.trackId),
                    active: d.active,
                    cn: d.cn,
                });
            }
            return thumbs;
        },
});

export const useThumbsGrid = (): [
    ThumbsGridState,
    { setIsHover: (trackId: number, value: boolean) => void; extractThumbs: () => void; updateThumbActivation: (trackId: number, value: boolean) => void }
] => {
    const currentFrameDetections = useCurrentFrameDetections();
    const thumbsData = useRecoilValue(thumbsGridSelector(currentFrameDetections));
    const [, setThumbHoverState] = useRecoilState(thumbHoverAtom);
    const [{ dimensionRatio }, { cropImage }] = useCanvasMedia();
    const [trackPreview] = useTrackPreview();
    const [trackCreation] = useNewTrackCreationPreview();
    const [thumbsGridState, setThumbsGridState] = useRecoilState(thumbsGridAtom);
    const { isReady } = useRefsContext();

    const extractThumbs = () => {
        let elementsTmp = [...thumbsData];
        for (let i = 0; i < elementsTmp.length; i++) {
            const cropOptions = {
                strokeStyle: rectStyle[elementsTmp[i].active ? 'active' : 'inactive'].stroke,
                fillStyle: rectStyle[elementsTmp[i].active ? 'active' : 'inactive'].fill,
            };
            const image = cropImage({ box: elementsTmp[i].box, ldm: elementsTmp[i].ldm }, cropOptions);
            if (!image) return;
            elementsTmp[i] = {
                ...elementsTmp[i],
                image: image,
            };
        }
        setThumbsGridState({ isLoading: false, elements: elementsTmp });
    };

    // dans le cas d'une image on extrait les thumbs quand dimension ratio est pret et que les data aussi
    useEffect(() => {
        if (isReady.imageRef && isReady.canvasMediaRef && dimensionRatio) {
            extractThumbs();
        }
    }, [dimensionRatio, thumbsData]);

    useEffect(() => {
        if (isReady.videoRef && isReady.canvasMediaRef) {
            extractThumbs();
        }
    }, [trackPreview, trackCreation, isReady.videoRef, isReady.canvasMediaRef, isReady.imageRef, dimensionRatio]);

    const setIsHover = (trackId: number, value: boolean) => {
        setThumbHoverState(trackId);
    };

    const updateThumbActivation = (trackId: number, value: boolean) => {
        const thumbsElementsTmp = [...thumbsGridState.elements];
        const elementIndex = thumbsElementsTmp.findIndex((e) => e.trackId === trackId);
        if (elementIndex < 0) return;
        let elementsTmp = [...thumbsData];
        for (let i = 0; i < elementsTmp.length; i++) {
            let cropOptions = {
                strokeStyle: rectStyle[elementsTmp[i].active ? 'active' : 'inactive'].stroke,
                fillStyle: rectStyle[elementsTmp[i].active ? 'active' : 'inactive'].fill,
            };
            if (trackId === elementsTmp[i].trackId) {
                cropOptions = {
                    strokeStyle: rectStyle[value ? 'active' : 'inactive'].stroke,
                    fillStyle: rectStyle[value ? 'active' : 'inactive'].fill,
                };
            }
            const image = cropImage({ box: elementsTmp[i].box, ldm: elementsTmp[i].ldm }, cropOptions);
            if (!image) return;
            elementsTmp[i] = {
                ...elementsTmp[i],
                image: image,
                active: elementsTmp[i].trackId === trackId ? value : elementsTmp[i].active,
            };
        }
        setThumbsGridState({ isLoading: false, elements: elementsTmp });
    };

    return [thumbsGridState, { setIsHover, extractThumbs, updateThumbActivation }];
};
