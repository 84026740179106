import React from 'react';
import '../../static/scss/error.scss';
import { getError } from '../../services/errorsService';
import { useTranslation } from 'react-i18next';

const ErrorComponent: React.FC<{
    error: any;
    customError?: any;
}> = ({ error, customError = null }) => {
    const { t } = useTranslation();

    return <div className="error">{getError(error, t, customError).message}</div>;
};
export default ErrorComponent;
