import { atom } from 'recoil';

export enum DetectionClassName {
    Head = 'head',
    Plate = 'plate',
    Other = 'other',
}

export type Box = {
    x: number;
    y: number;
    width: number;
    height: number;
};

export type Point = {
    x: number;
    y: number;
};

export type FrameTimestampData = {
    ts: number;
    duration: number;
};

export type DetectionsData = {
    trackId: number;
    src: number;
};

export type DetectionPlate = {
    cn: DetectionClassName.Plate;
    box: Box;
    ldm: Point[];
    src: number;
} & DetectionsData;

export type DetectionHead = {
    cn: DetectionClassName.Head;
    box: Box;
} & DetectionsData;

export type DetectionOther = {
    cn: DetectionClassName.Other;
    box: Box;
    ldm: Point[];
} & DetectionsData;

export type FrameDetectionsCoordinate = {
    tsData: FrameTimestampData; // le timestamp de la frame
    detections: (DetectionOther | DetectionPlate | DetectionHead)[];
};

export const isBox = (data: unknown): data is Box => {
    return !!(data as Box).height;
};

export type FramesDetectionsCoordinates = FrameDetectionsCoordinate[];

export const framesDetectionsCoordinatesAtom = atom<FramesDetectionsCoordinates>({
    key: 'detectionsCoordinates',
    default: [],
});
