import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getManyHistoryCall, getMediaMetadata } from '../../api';
import { GetManyHistoryCallsRequest, GetManyHistoryCallsResponse } from '../../types/historyCall';

export const useGetHistoryCall = (
    params: GetManyHistoryCallsRequest,
    enabled: boolean = false
): UseQueryResult<GetManyHistoryCallsResponse> => {
    return useQuery(['getHistoryCall', params], async () => getManyHistoryCall(params), {
        refetchOnMount: true,
        refetchOnWindowFocus: true,
        refetchOnReconnect: true,
        enabled,
    });
};
