import React, { CSSProperties } from 'react';
import '../../static/scss/checkbox.scss';

export interface IRadio {
    isChecked?: boolean,
    onClick?: (e: any) => void,
    value: string,
    labelText?: string
    isRequired?: boolean,
    isDisabled?: boolean,
    customClass?: string,
    radioLabelStyle?: CSSProperties,
    checkMarkStyle?: CSSProperties,
    labelTextStyle?: CSSProperties,
    inputRef?: React.MutableRefObject<HTMLInputElement | null>
}

export const Radio = ({
    isChecked,
    isDisabled,
    onClick,
    value,
    labelText,
    isRequired,
    customClass,
    radioLabelStyle,
    checkMarkStyle,
    labelTextStyle,
    inputRef
}: IRadio) => {
    return (
        <label htmlFor={`radio-${value}`} className={`radio-container ${isDisabled ? 'disabled' : ''} ${customClass}`} style={radioLabelStyle}>
            <input id={`radio-${value}`} ref={inputRef} value={value} checked={isChecked} className="my-radio" type="radio" onChange={onClick} onClick={onClick} disabled={isDisabled} />
            <span className="checkmark" style={checkMarkStyle}></span>
            {labelText &&
                <span className="label-text" style={labelTextStyle}>{labelText}{isRequired && <sup>*</sup>}</span>
            }
        </label>
    );
};
