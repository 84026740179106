import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { atom, useRecoilValue } from 'recoil';
import { ReactComponent as FaceActive } from '../../../../../static/icons/tasks/icon_face_active.svg';
import { ReactComponent as FaceInactive } from '../../../../../static/icons/tasks/icon_face.svg';
import { ReactComponent as PlatesGreen } from '../../../../../static/icons/tasks/icon_plates_active.svg';
import { ReactComponent as PlatesInactive } from '../../../../../static/icons/tasks/icon_plates.svg';
import { ReactComponent as CoordinatesActive } from '../../../../../static/icons/tasks/coordinates_active.svg';
import { ReactComponent as CoordinatesInactive } from '../../../../../static/icons/tasks/coordinates.svg';
import { useFormikContext } from 'formik';
import { FormAskAnonymization, BlurOptionsKey } from '../../../../../types';
import { Checkbox } from '../../../../../components/checkbox/checkbox';
import { PreviewTask, veryDirtyAuthorizedVideoFormats } from './Preview';
import { Radio } from '../../../../../components/radio/radio';
import Tooltip from '../../../../../components/tooltip/Tooltip';
import useWindowDimensions from '../../../../../hook/useWindowDimensions';
import { useDragNDrop } from '../../../../../components/dragNDrop/useDragNDrop';

export type BlurOptionsStep = Record<
    'select-file' | 'blur-settings',
    {
        state: 'inactive' | 'active';
    }
>;

export const defaultOptionsSettingsState: BlurOptionsStep = {
    'select-file': {
        state: 'active',
    },
    'blur-settings': {
        state: 'inactive',
    },
};

export const optionsSettingsState = atom<BlurOptionsStep>({ key: 'optionsSettings', default: defaultOptionsSettingsState });

const OptionRowCases = ['anonymization-mode', 'blur-options'] as const;
type OptionRowCase = (typeof OptionRowCases)[number];

type OptionRowExtractProps<T extends OptionRowCase> = {
    value: BlurOptionsKey;
    type: T extends 'anonymization-mode' ? 'radio' : 'checkbox';
};
type OptionRowProps<T extends OptionRowCase> = {
    icons: {
        active: () => JSX.Element;
        inactive: () => JSX.Element;
    };
    activated: boolean;
    text: string;
    onClickToggle: (e: React.ChangeEvent<HTMLInputElement>) => void;
    isChecked?: boolean;
    tooltip?: string;
    disabled?: boolean;
} & OptionRowExtractProps<T>;

const OptionRow = <T extends OptionRowCase>({ icons, activated, text, value, onClickToggle, type, isChecked, tooltip, disabled }: OptionRowProps<T>) => {
    const { width } = useWindowDimensions();
    const inputRef = useRef<HTMLInputElement | null>(null);
    const { active: LogoActive, inactive: LogoInactive } = icons;
    const Logo = activated && isChecked ? LogoActive : LogoInactive;

    const handleMainDivClick = (e: React.MouseEvent<HTMLDivElement>) => {
        e.preventDefault();
        e.stopPropagation();

        if (inputRef.current) {
            inputRef.current.click(); // Programmatically trigger a click event on the input element
        }
    };

    return (
        <div style={{ cursor: disabled ? 'not-allowed' : 'initial' }}>
            <div
                onClick={handleMainDivClick}
                className={`filter-container__step__blur-settings__option-row d-flex justify-content-between ${
                    activated ? 'filter-container__step__blur-settings__option-row--activated opacity-100' : 'opacity-50'
                } ${isChecked ? 'filter-container__step__blur-settings__option-row--checked' : ''} ${
                    disabled ? 'filter-container__step__blur-settings__option-row--disabled' : ''
                }`}
            >
                <div className="w-100 d-flex ml-2 ml-lg-0 mt-2 mt-lg-0 flex-column flex-lg-row align-items-center justify-content-between">
                    <div className={`filter-container__step__blur-settings__option-row__logo`}>
                        <div className="d-flex align-items-center justify-content-center h-100 w-100">
                            <Logo />
                        </div>
                    </div>
                    <div className="ml-2 w-100 d-flex align-items-center h-100 w-100 filter-container__step__blur-settings__option-row__text duration-200 ease-in-out">
                        <span className="color-clear-blue-grey">{text}</span>
                        {tooltip && width > 992 && (
                            <Tooltip content={tooltip} disabled={!activated}>
                                <sup>
                                    <div className="filter-container__step__blur-settings__option-row__information">
                                        <small>i</small>
                                    </div>
                                </sup>
                            </Tooltip>
                        )}
                    </div>
                </div>
                <div className="w-25 h-100 filter-container__step__blur-settings__option-row__checkbox">
                    {type === 'checkbox' ? (
                        <Checkbox value={value} isDisabled={!activated} onClick={onClickToggle} customClass="my-0" isChecked={isChecked} inputRef={inputRef} />
                    ) : (
                        <Radio value={value} isDisabled={!activated} onClick={onClickToggle} isChecked={isChecked} inputRef={inputRef} />
                    )}
                </div>
            </div>
        </div>
    );
};

const BlurSettings = () => {
    const state = useRecoilValue(optionsSettingsState);
    const stepState = state['blur-settings'].state;
    const isActive = stepState === 'active';
    const formikContext = useFormikContext<FormAskAnonymization>();
    const { t } = useTranslation();
    const { width } = useWindowDimensions();

    const handleClickToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        e.stopPropagation();
        const value = e.target.value as BlurOptionsKey;
        formikContext.setValues((prev) => ({ ...prev, [value]: !prev[value] }));
    };

    return (
        <div className={`filters-container__step ${stepState} d-flex align-items-center justify-content-between`}>
            <div className={`filters-container__step__title ${stepState} d-flex align-items-center`}>
                <div className={`filters-container__step__title__badge ${stepState}`}>2</div>
                <span className={`filters-container__step__title__text ${stepState}`}>{t('task-management.tasks.anonymization.select-options-blur')}</span>
                <span className={`filters-container__step__title__sub ${stepState}`}>
                    {t('task-management.tasks.anonymization.select-options-blur-mode-sub')}
                </span>
            </div>
            <div className="filter-container__step__blur-settings row">
                <div className="col-4">
                    <OptionRow<'blur-options'>
                        icons={{ active: () => <FaceActive height={40} width={40} />, inactive: () => <FaceInactive height={40} width={40} /> }}
                        activated={isActive}
                        text={width < 992 ? t('task-management.tasks.anonymization.face-responsive') : t('task-management.tasks.anonymization.faces')}
                        value={'activation_faces_blur'}
                        onClickToggle={handleClickToggle}
                        type="checkbox"
                        isChecked={formikContext.values.activation_faces_blur}
                    />
                </div>
                <div className="col-4">
                    <OptionRow<'blur-options'>
                        icons={{ active: () => <PlatesGreen height={40} width={40} />, inactive: () => <PlatesInactive height={40} width={40} /> }}
                        activated={isActive}
                        text={width < 992 ? t('task-management.tasks.anonymization.plates-responsive') : t('task-management.tasks.anonymization.plates')}
                        value={'activation_plates_blur'}
                        onClickToggle={handleClickToggle}
                        type="checkbox"
                        isChecked={formikContext.values.activation_plates_blur}
                    />
                </div>
                <div className="col-4">
                    <OptionRow<'blur-options'>
                        icons={{
                            active: () => <CoordinatesActive height={35} width={35} />,
                            inactive: () => <CoordinatesInactive height={35} width={35} />,
                        }}
                        activated={isActive}
                        text={width < 992 ? t('task-management.tasks.anonymization.json-responsive') : t('task-management.tasks.anonymization.json')}
                        value={'output_detections_url'}
                        onClickToggle={handleClickToggle}
                        type="checkbox"
                        isChecked={formikContext.values.output_detections_url}
                        tooltip={t('task-management.tasks.anonymization.json-information')}
                    />
                </div>
            </div>
        </div>
    );
};

const InputOption = () => {
    const { t } = useTranslation();
    const { setValues } = useFormikContext<FormAskAnonymization>();
    const { file, isDirtyVideoAcceptedFormat } = useDragNDrop();

    useEffect(() => {
        if (file && isDirtyVideoAcceptedFormat(file, veryDirtyAuthorizedVideoFormats)) {
        }

        setValues((prev) => ({
            ...prev,
            anonymization_mode: 'auto',
            output_detections_url: false,
        }));
    }, [file]);

    return (
        <div className={`filters-container__step active`}>
            <div className={`filters-container__step__title d-flex align-items-center active`}>
                <div className={`filters-container__step__title__badge active`}>1</div>
                <span className={`filters-container__step__title__text active`}>{t('task-management.tasks.anonymization.select-file')}</span>
            </div>
            <div className="w-100 h-100 row">
                <PreviewTask />
            </div>
        </div>
    );
};

export const BlurOptions = () => {
    return (
        <div className="filters-container">
            <InputOption />
            <BlurSettings />
        </div>
    );
};
