import React from 'react';
import { ReactComponent as ArrowTableSvg } from '../../../static/icons/fleche.svg';
import { ReactComponent as ArrowTableMobileSvg } from '../../../static/icons/icon-chevron-mobile.svg';
import { randomKeyGen } from '../../../services/tools';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useTable from './hook/useTable';
import './table.scss';
import useWindowDimensions from '../../../hook/useWindowDimensions';

interface ICustomTable {
    loading?: boolean;
    customStyleTr?: any;
    customStyleTd?: any;
    customClassname?: string;
    handleSort?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    sortState?: {
        sortOrder: 'ASC' | 'DESC';
        sortBy: string;
    };
}

const CustomTable: React.FC<ICustomTable> = ({ customStyleTr = null, customStyleTd = null, customClassname = '', handleSort, sortState }) => {
    const { t } = useTranslation();
    const { head, body } = useTable();
    const noneText = t('common.text.none');
    // Check pathname
    const location = useLocation();
    const { width } = useWindowDimensions();

    const handleTableClass = () => {
        let tableClass = customClassname;

        switch (location.pathname) {
            case '/task-manager':
                tableClass += ' table-manager';
                break;

            case '/account/invoice':
                tableClass += ' table-invoice';
                break;

            default:
                tableClass = customClassname;
        }

        return tableClass;
    };

    const handleEmptyTableMessage = () => {
        let message = '';

        switch (location.pathname) {
            case '/task-manager': {
                message = 'No task created';
                break;
            }

            case '/account/invoice':
                message = 'No invoices';
                break;

            default:
                message = '';
        }

        return message;
    };

    // Check table.scss line 568
    const hiddenPagination = () => {
        if (body?.length === 0) {
            return ' hidden-pagination';
        } else {
            return '';
        }
    };

    return (
        <>
            <table className={`table ${handleTableClass()}${hiddenPagination()}`}>
                <thead className="head-sticky">
                    <tr>
                        {head?.map((element: any) => {
                            return (
                                <th
                                    align={element?.align || 'left'}
                                    key={randomKeyGen()}
                                    style={element?.style || undefined}
                                    data-mobile={element.data && element.data}
                                >
                                    {element.type === 'button-sortable' && handleSort && (
                                        <>
                                            <button
                                                type="button"
                                                name={element.name}
                                                onClick={handleSort || undefined}
                                                className={`d-flex align-items-center ${
                                                    sortState?.sortBy === element.name && sortState?.sortOrder === 'DESC' ? 'btn-desc' : ''
                                                } ${sortState?.sortBy === element.name ? 'active' : ''} button btn-table-arrow`}
                                            >
                                                {element.title}
                                                {width < 992 ? <ArrowTableMobileSvg fill="#F50A6B" /> : <ArrowTableSvg fill="#702D6A" />}
                                            </button>
                                        </>
                                    )}
                                    {element.type === 'text' && <>{element.title}</>}
                                </th>
                            );
                        })}
                    </tr>
                </thead>
                <tbody>
                    {body?.length === 0 ? (
                        <tr key={randomKeyGen()} style={{ ...customStyleTr }} className="empty-table">
                            <td colSpan={head?.length}>{handleEmptyTableMessage()}</td>
                        </tr>
                    ) : (
                        body?.map((element: any) => {
                            return (
                                <tr key={randomKeyGen()} style={{ ...customStyleTr }}>
                                    {Object.keys(element).map((data) => {
                                        const cell = element[data];
                                        return (
                                            <td
                                                style={customStyleTd}
                                                key={randomKeyGen()}
                                                align={cell?.align || 'left'}
                                                data-mobile={cell.data}
                                                data-status={cell.taskLink}
                                                data-service={cell.service && cell.service.toLowerCase()}
                                            >
                                                {cell.type === 'text' && (
                                                    <div
                                                        // className="text-truncate"
                                                        style={{
                                                            lineHeight: 'normal',
                                                            // overflow: 'hidden',
                                                            // whiteSpace: 'nowrap',
                                                            // textOverflow: 'ellipsis',
                                                        }}
                                                    >
                                                        {cell.value || noneText}
                                                    </div>
                                                )}
                                                {cell.type === 'link' && (
                                                    <Link
                                                        to={{
                                                            pathname: width > 992 ? cell.linkTo.pathname : cell.linkTo.pathname,
                                                            state: cell.linkTo.state ?? {},
                                                        }}
                                                        className="table-link"
                                                    >
                                                        {cell.value || noneText}
                                                    </Link>
                                                )}
                                                {cell.type === 'anchor' && <a href={cell.linkTo}>{cell.value || noneText}</a>}
                                                {cell.type === 'base' && <div>{cell.value}</div>}
                                            </td>
                                        );
                                    })}
                                </tr>
                            );
                        })
                    )}
                </tbody>
            </table>
        </>
    );
};

export default CustomTable;
